// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Recoil from "recoil";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Modal$OrcaPaymentPage from "../Components/Modal.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as LoggerUtils$OrcaPaymentPage from "../Utilities/LoggerUtils.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "../Utilities/RecoilAtoms.bs.js";

function VoucherDisplay(props) {
  var match = React.useState(function () {
        return false;
      });
  var setOpenModal = match[1];
  var match$1 = React.useState(function () {
        return "";
      });
  var setReturnUrl = match$1[1];
  var returnUrl = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setDownloadUrl = match$2[1];
  var match$3 = React.useState(function () {
        return "";
      });
  var setReference = match$3[1];
  var logger = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.loggerAtom);
  var match$4 = React.useState(function () {
        return 0;
      });
  var setDownloadCounter = match$4[1];
  var downloadCounter = match$4[0];
  var match$5 = React.useState(function () {
        return "";
      });
  var setPaymentMethod = match$5[1];
  var paymentMethod = match$5[0];
  var match$6 = React.useState(function () {
        return null;
      });
  var setPaymentIntent = match$6[1];
  var paymentIntent = match$6[0];
  var match$7 = React.useState(function () {
        return true;
      });
  var setLoader = match$7[1];
  var loader = match$7[0];
  var linkRef = React.useRef(null);
  React.useEffect((function () {
          var link = linkRef.current;
          if (!(link == null)) {
            link.click();
          }
          
        }), [loader]);
  React.useEffect((function () {
          Utils$OrcaPaymentPage.handlePostMessage(undefined, [[
                  "iframeMountedCallback",
                  true
                ]]);
          var handle = function (ev) {
            var json = JSON.parse(ev.data);
            var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
            if (!Core__Option.isSome(dict["fullScreenIframeMounted"])) {
              return ;
            }
            var metadata = Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "metadata");
            var metaDataDict = Core__Option.getOr(Core__JSON.Decode.object(metadata), {});
            setReturnUrl(function (param) {
                  return Utils$OrcaPaymentPage.getString(metaDataDict, "returnUrl", "");
                });
            setDownloadUrl(function (param) {
                  return Utils$OrcaPaymentPage.getString(metaDataDict, "voucherUrl", "");
                });
            setReference(function (param) {
                  return Utils$OrcaPaymentPage.getString(metaDataDict, "reference", "");
                });
            setPaymentMethod(function (param) {
                  return Utils$OrcaPaymentPage.getString(metaDataDict, "paymentMethod", "");
                });
            setPaymentIntent(function (param) {
                  return Utils$OrcaPaymentPage.getJsonObjectFromDict(metaDataDict, "payment_intent_data");
                });
            setLoader(function (param) {
                  return false;
                });
          };
          window.addEventListener("message", handle);
          return (function () {
                    window.removeEventListener("message", handle);
                  });
        }), []);
  var closeModal = function () {
    Utils$OrcaPaymentPage.postSubmitResponse(paymentIntent, returnUrl);
    Modal$OrcaPaymentPage.close(setOpenModal);
  };
  return React.createElement(Modal$OrcaPaymentPage.make, {
              children: React.createElement("div", {
                    className: "flex flex-col h-full justify-between items-center"
                  }, React.createElement("div", {
                        className: "flex flex-col max-w-md justify-between items-center"
                      }, React.createElement("div", {
                            className: "flex flex-row w-full"
                          }, React.createElement("p", {
                                className: "Disclaimer font-medium text-sm text-[#151A1F] opacity-70"
                              }, Utils$OrcaPaymentPage.snakeToTitleCase(paymentMethod) + " voucher was successfully generated! If the document hasn't started downloading automatically, click ", React.createElement("a", {
                                    ref: Caml_option.some(linkRef),
                                    className: "text font-medium text-sm text-[#006DF9] underline",
                                    href: match$2[0],
                                    onClick: (function (param) {
                                        setDownloadCounter(function (c) {
                                              return c + 1 | 0;
                                            });
                                        LoggerUtils$OrcaPaymentPage.handleLogging(logger, downloadCounter.toString(), undefined, "DISPLAY_VOUCHER", paymentMethod, undefined, undefined);
                                      })
                                  }, "here"), " to download it.")), React.createElement("div", {
                            className: "flex flex-row mt-4 w-full"
                          }, React.createElement("p", {
                                className: "Disclaimer font-medium text-sm text-[#151A1F] opacity-70"
                              }, "Bar Code Reference: ", React.createElement("span", {
                                    className: "Disclaimer font-bold text-sm text-[#151A1F] opacity-90"
                                  }, match$3[0]))), React.createElement("div", {
                            className: "Disclaimer w-full mt-16 font-medium text-xs text-[#151A1F] opacity-50"
                          }, "Please do not close until you have successfully downloaded the voucher, after which you will be automatically redirected."), React.createElement("div", {
                            className: "button w-full"
                          }, React.createElement("div", undefined, React.createElement("button", {
                                    className: "w-full mt-6 p-2 h-[40px]",
                                    style: {
                                      background: "#006DF9",
                                      color: "#ffffff",
                                      borderRadius: "4px"
                                    },
                                    onClick: (function (param) {
                                        closeModal();
                                      })
                                  }, "Done"))))),
              loader: loader,
              showClose: false,
              setOpenModal: setOpenModal,
              openModal: match[0]
            });
}

var make = VoucherDisplay;

export {
  make ,
}
/* react Not a pure module */
