// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Recoil from "recoil";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as RenderIf$OrcaPaymentPage from "./RenderIf.bs.js";
import * as CardUtils$OrcaPaymentPage from "../CardUtils.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "../Utilities/RecoilAtoms.bs.js";

function InputField(props) {
  var isFocus = props.isFocus;
  var __className = props.className;
  var __placeholder = props.placeholder;
  var paymentType = props.paymentType;
  var __type_ = props.type_;
  var __fieldName = props.fieldName;
  var errorString = props.errorString;
  var __rightIcon = props.rightIcon;
  var onFocus = props.onFocus;
  var onBlur = props.onBlur;
  var onChange = props.onChange;
  var value = props.value;
  var setIsValid = props.setIsValid;
  var __id = props.id;
  var isValid = props.isValid;
  var id = __id !== undefined ? __id : "";
  var rightIcon = __rightIcon !== undefined ? Caml_option.valFromOption(__rightIcon) : null;
  var fieldName = __fieldName !== undefined ? __fieldName : "";
  var type_ = __type_ !== undefined ? __type_ : "text";
  var placeholder = __placeholder !== undefined ? __placeholder : "";
  var className = __className !== undefined ? __className : "";
  var match = React.useState(function () {
        return "input-base";
      });
  var setEleClassName = match[1];
  var match$1 = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.keys);
  var parentURL = match$1.parentURL;
  var iframeId = match$1.iframeId;
  var options = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.elementOptions);
  var setFocus = function (val) {
    if (onFocus !== undefined) {
      return onFocus(val);
    }
    
  };
  var setClass = function (val) {
    switch (paymentType) {
      case "Payment" :
      case "NONE" :
          return ;
      default:
        return setEleClassName(function (param) {
                    return val;
                  });
    }
  };
  var textColor = isValid !== undefined && !isValid ? "#eb1c26" : "";
  var setValidClasses = function () {
    if (isValid !== undefined) {
      if (isValid) {
        return setClass("input-complete");
      } else {
        return setClass("input-invalid");
      }
    } else {
      return setClass("input-base");
    }
  };
  var handleFocus = function (param) {
    if (value.length === 0) {
      setClass("input-empty");
    } else if (value.length > 0) {
      setValidClasses();
    }
    setFocus(true);
    setIsValid(function (param) {
          
        });
    Utils$OrcaPaymentPage.handleOnFocusPostMessage(parentURL, undefined);
  };
  var handleBlur = function (ev) {
    if (value.length === 0) {
      setClass("input-base");
    } else if (value.length > 0) {
      setValidClasses();
    }
    setFocus(false);
    onBlur(ev);
    Utils$OrcaPaymentPage.handleOnBlurPostMessage(parentURL, undefined);
  };
  React.useEffect((function () {
          if (value.length > 0) {
            setValidClasses();
          }
          
        }), [
        value,
        isValid
      ]);
  var direction = type_ === "password" || type_ === "tel" ? "ltr" : "";
  var isValidValue = CardUtils$OrcaPaymentPage.getBoolOptionVal(isValid);
  var match$2 = React.useMemo((function () {
          var isCardDetailsEmpty = value.length === 0 ? options.classes.base + " " + options.classes.empty + " " : options.classes.base;
          var isCardDetailsValid = isValidValue === "valid" ? " " + options.classes.complete + " " : "";
          var isCardDetailsInvalid = isValidValue === "invalid" ? " " + options.classes.invalid + " " : "";
          var isCardDetailsFocused = isFocus ? " " + options.classes.focus + " " : "";
          return [
                  isCardDetailsEmpty,
                  isCardDetailsValid,
                  isCardDetailsInvalid,
                  isCardDetailsFocused
                ];
        }), [
        isValid,
        setIsValid,
        value,
        onChange,
        onBlur
      ]);
  var concatString = [
      match$2[0],
      match$2[1],
      match$2[2],
      match$2[3]
    ].join("");
  React.useEffect((function () {
          Utils$OrcaPaymentPage.handlePostMessage(undefined, [
                [
                  "id",
                  iframeId
                ],
                [
                  "concatedString",
                  concatString
                ]
              ]);
        }), [
        isValid,
        setIsValid,
        value,
        onChange,
        onBlur
      ]);
  return React.createElement("div", {
              className: " flex flex-col w-full"
            }, React.createElement(RenderIf$OrcaPaymentPage.make, {
                  condition: fieldName.length > 0,
                  children: React.createElement("div", {
                        style: {}
                      }, fieldName)
                }), React.createElement("div", {
                  className: "flex flex-row ",
                  style: {
                    direction: direction
                  }
                }, React.createElement("input", {
                      ref: Caml_option.some(props.inputRef),
                      className: match[0] + " " + className + " focus:outline-none transition-shadow ease-out duration-200",
                      id: id,
                      style: {
                        background: "transparent",
                        color: textColor,
                        width: "-webkit-fill-available"
                      },
                      disabled: options.disabled,
                      maxLength: props.maxLength,
                      pattern: props.pattern,
                      placeholder: placeholder,
                      type: type_,
                      value: value,
                      onKeyDown: props.onKeyDown,
                      onFocus: handleFocus,
                      onBlur: handleBlur,
                      onChange: onChange
                    }), React.createElement("div", {
                      className: "flex -ml-10  items-center"
                    }, rightIcon)), errorString !== undefined ? React.createElement(RenderIf$OrcaPaymentPage.make, {
                    condition: errorString.length > 0,
                    children: React.createElement("div", {
                          className: "py-1",
                          style: {}
                        }, errorString)
                  }) : null);
}

var make = InputField;

export {
  make ,
}
/* react Not a pure module */
