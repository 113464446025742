// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Recoil from "recoil";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as React$1 from "@sentry/react";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as OrcaLogger$OrcaPaymentPage from "./OrcaLogger.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "../Utilities/RecoilAtoms.bs.js";

var errorIcon = React.createElement("svg", {
      id: "dead-ghost",
      height: "50",
      width: "50",
      viewBox: "0 0 512 512",
      xmlns: "http://www.w3.org/2000/svg"
    }, React.createElement("path", {
          d: "M73.73 398.49C86.92 453.5 174.24 496 280 496M312 304H264a40 40 0 00-40 40v24a32 32 0 0032 32h0a32 32 0 0032-32v-4a12 12 0 0112-12h12",
          fill: "none",
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "16"
        }), React.createElement("path", {
          d: "M120,352H108a12,12,0,0,0-12,12v4a32,32,0,0,1-64,0V344a40,40,0,0,1,40-40h48",
          fill: "none",
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "16"
        }), React.createElement("path", {
          d: "M160,264h27.2q11.6,0,23.189,0c3.22,0,5.97-.155,8.887.877,3.47,1.226,4.593,4.619,9.168,3.25,4.705-1.408,6.016-5.086,11.556-4.127",
          fill: "none",
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "8"
        }), React.createElement("line", {
          fill: "none",
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "8",
          x1: "240",
          x2: "288",
          y1: "128",
          y2: "208"
        }), React.createElement("path", {
          d: "M288,128c-2.072,3.453-3.863,7.874-6.512,10.853-3.167,3.563-10.091,5.347-9.376,11.508.38,3.278,2.813,6.644,1.294,9.574-1,1.924-3.305,2.7-4.969,4.086-6.256,5.221-1.762,16.789-7.45,22.623-8.539,8.759-16,15.234-28.222,14.63",
          fill: "none",
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "8"
        }), React.createElement("line", {
          fill: "none",
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "8",
          x1: "120",
          x2: "168",
          y1: "128",
          y2: "208"
        }), React.createElement("path", {
          d: "M168,128c-2.142,3.57-4.093,8.789-7.386,11.311-4.942,3.783-11.6,2.606-12.25,11.195-.218,2.885.646,5.866-.189,8.635-1.884,6.246-10.446,7.178-15.029,11.82-3.055,3.094-4.084,7.6-4.984,11.852C126.194,192.122,124.9,199.849,120,208",
          fill: "none",
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "8"
        }), React.createElement("path", {
          d: "M168,264h32a0,0,0,0,1,0,0v16a16,16,0,0,1-16,16h0a16,16,0,0,1-16-16V264A0,0,0,0,1,168,264Z",
          fill: "none",
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "16"
        }), React.createElement("path", {
          d: "M72,304a652.669,652.669,0,0,0-3.127-67.367C66.478,213.265,72,191.111,72,168a152,152,0,0,1,304,0v70.071c0,17.689-2.939,37.871.849,54.981a158.13,158.13,0,0,1,2.545,57.075c-1.179,8.136-3,16.563-.638,24.438,6.368,21.233,27.016,20.563,44.877,19.524,21.843-1.272,43.7-5.16,64.317-12.589.03.83.05,1.66.05,2.5,0,43.21-45.44,80.71-112,99.38C347.28,491.44,314.63,496,280,496c-105.76,0-193.08-42.5-206.27-97.51",
          fill: "none",
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "16"
        }), React.createElement("path", {
          d: "M342.153 74.512l-21.939 27.094M352.83 95.734l-30.147 39.31M102.509 437.932c12.07-13.545 24.072-26.889 36.9-39.716M117.17 454.577l26.994-27.829",
          fill: "none",
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "8"
        }));

function ErrorBoundary$ErrorCard(props) {
  var level = props.level;
  var error = props.error;
  var beaconApiCall = function (data) {
    if (data.length <= 0) {
      return ;
    }
    var logData = JSON.stringify(data.map(OrcaLogger$OrcaPaymentPage.logFileToObj));
    navigator.sendBeacon(logEndpoint, logData);
  };
  React.useEffect((function () {
          var loggingLevel$1 = loggingLevel;
          var enableLogging$1 = enableLogging;
          if (enableLogging$1 && [
                "DEBUG",
                "INFO",
                "WARN",
                "ERROR"
              ].includes(loggingLevel$1)) {
            var errorLog_timestamp = String(Date.now());
            var errorLog_value = JSON.stringify(error);
            var errorLog_platform = navigator.platform;
            var errorLog_browserName = Core__Option.getOr(OrcaLogger$OrcaPaymentPage.arrayOfNameAndVersion[0], "Others");
            var errorLog_browserVersion = Core__Option.getOr(OrcaLogger$OrcaPaymentPage.arrayOfNameAndVersion[1], "0");
            var errorLog_userAgent = navigator.userAgent;
            var errorLog_metadata = null;
            var errorLog = {
              timestamp: errorLog_timestamp,
              logType: "ERROR",
              category: "USER_ERROR",
              source: "orca-elements",
              version: repoVersion,
              value: errorLog_value,
              internalMetadata: "",
              sessionId: "",
              merchantId: "",
              paymentId: "",
              appId: "",
              platform: errorLog_platform,
              browserName: errorLog_browserName,
              browserVersion: errorLog_browserVersion,
              userAgent: errorLog_userAgent,
              eventName: "SDK_CRASH",
              latency: "",
              firstEvent: false,
              paymentMethod: "",
              metadata: errorLog_metadata
            };
            beaconApiCall([errorLog]);
          }
          
        }), []);
  var match = React.useState(function () {
        return 0.0;
      });
  var setDivH = match[1];
  var divH = match[0];
  var match$1 = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.configAtom);
  var themeObj = match$1.themeObj;
  var match$2 = Recoil.useRecoilState(RecoilAtoms$OrcaPaymentPage.keys);
  var iframeId = match$2[0].iframeId;
  var divRef = React.useRef(null);
  var observer = new ResizeObserver((function (entries) {
          entries.map(function (item) {
                setDivH(function (param) {
                      return item.contentRect.height;
                    });
              });
        }));
  var r = divRef.current;
  if (!(r == null)) {
    observer.observe(r);
  }
  React.useEffect((function () {
          switch (level) {
            case "Top" :
                Utils$OrcaPaymentPage.handlePostMessage(undefined, [
                      [
                        "iframeHeight",
                        divH + 1.0
                      ],
                      [
                        "iframeId",
                        iframeId
                      ]
                    ]);
                break;
            case "RequestButton" :
            case "PaymentMethod" :
                break;
            
          }
        }), [
        divH,
        iframeId
      ]);
  var message;
  switch (level) {
    case "Top" :
        message = "We'll be back with you shortly :)";
        break;
    case "RequestButton" :
    case "PaymentMethod" :
        message = "Try another payment method :)";
        break;
    
  }
  switch (level) {
    case "RequestButton" :
        return null;
    case "Top" :
    case "PaymentMethod" :
        break;
    
  }
  return React.createElement("div", {
              ref: Caml_option.some(divRef),
              className: "flex  items-center",
              style: {
                backgroundColor: themeObj.colorBackground,
                borderColor: themeObj.borderColor,
                color: themeObj.colorPrimary,
                borderRadius: themeObj.borderRadius
              }
            }, React.createElement("div", {
                  className: "flex flex-row  items-center m-6"
                }, React.createElement("div", {
                      style: {
                        marginLeft: "1rem"
                      }
                    }, errorIcon), React.createElement("div", {
                      className: "flex flex-col items-center justify-center",
                      style: {
                        marginLeft: "3rem"
                      }
                    }, React.createElement("div", undefined, "Oops, something went wrong!"), React.createElement("div", {
                          className: "text-sm"
                        }, message))));
}

var ErrorCard = {
  make: ErrorBoundary$ErrorCard
};

function defaultFallback(e, level) {
  return React.createElement(ErrorBoundary$ErrorCard, {
              error: e,
              level: level
            });
}

function ErrorBoundary(props) {
  var __level = props.level;
  var __renderFallback = props.renderFallback;
  var renderFallback = __renderFallback !== undefined ? __renderFallback : defaultFallback;
  var level = __level !== undefined ? __level : "PaymentMethod";
  return React.createElement(React$1.ErrorBoundary, {
              fallback: (function (e) {
                  return renderFallback(e, level);
                }),
              children: props.children
            });
}

var make = ErrorBoundary;

export {
  errorIcon ,
  ErrorCard ,
  defaultFallback ,
  make ,
}
/* errorIcon Not a pure module */
