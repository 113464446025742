// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

function localeStrings_achBankDebitTerms(str) {
  return "Genom att ange ditt kontonummer och bekräfta denna betalning godkänner du att " + str + " och Hyperswitch, vår betaltjänstleverantör, skickar instruktioner till din bank att debitera ditt konto och att din bank debiterar ditt konto i enlighet med dessa instruktioner. Du har rätt till återbetalning från din bank enligt villkoren i ditt avtal med din bank. En återbetalning måste begäras inom 8 veckor från det datum då ditt konto debiterades.";
}

function localeStrings_sepaDebitTerms(str) {
  return "Genom att ange din betalningsinformation och bekräfta denna betalning godkänner du att (A) " + str + " och Hyperswitch, vår betaltjänstleverantör och/eller PPRO, dess lokala tjänsteleverantör, skickar instruktioner till din bank att debitera ditt konto och (B) att din bank debiterar ditt konto i enlighet med dessa instruktioner. Som en del av dina rättigheter har du rätt till återbetalning från din bank enligt villkoren i ditt avtal med din bank. En återbetalning måste begäras inom 8 veckor från det datum då ditt konto debiterades. Dina rättigheter förklaras i ett uttalande som du kan erhålla från din bank. Du samtycker till att få aviseringar för framtida debiteringar upp till 2 dagar innan de inträffar.";
}

function localeStrings_cardTerms(str) {
  return "Genom att ange din kortinformation tillåter du att " + str + " debiterar ditt kort för framtida betalningar i enlighet med deras villkor.";
}

function localeStrings_surchargeMsgAmount(currency, str) {
  return React.createElement(React.Fragment, {}, "Ett tilläggsbelopp på" + Utils$OrcaPaymentPage.nbsp, React.createElement("strong", undefined, currency + " " + str), Utils$OrcaPaymentPage.nbsp + "kommer att tillämpas för denna transaktion");
}

function localeStrings_surchargeMsgAmountForCard(currency, str) {
  return React.createElement(React.Fragment, {}, "Ett tilläggsbelopp på upp till" + Utils$OrcaPaymentPage.nbsp, React.createElement("strong", undefined, currency + " " + str), Utils$OrcaPaymentPage.nbsp + "kommer att tillämpas för denna transaktion");
}

function localeStrings_nameEmptyText(str) {
  return "Vänligen ange din " + str;
}

function localeStrings_completeNameEmptyText(str) {
  return "Vänligen ange din fullständiga " + str;
}

var localeStrings = {
  locale: "sv",
  cardNumberLabel: "Kortnummer",
  localeDirection: "ltr",
  inValidCardErrorText: "Kortnumret är ogiltigt.",
  inCompleteCVCErrorText: "Kortets säkerhetskod är ofullständig.",
  inCompleteExpiryErrorText: "Kortets utgångsdatum är ofullständigt.",
  pastExpiryErrorText: "Kortets utgångsår är i det förflutna.",
  poweredBy: "Drivs av Hyperswitch",
  validThruText: "Utgångsdatum",
  sortCodeText: "Sorteringskod",
  cvcTextLabel: "CVC",
  emailLabel: "E-postadress",
  emailEmptyText: "E-post får inte vara tom",
  emailInvalidText: "Ogiltig e-postadress",
  accountNumberText: "Kontonummer",
  fullNameLabel: "Fullständigt namn",
  line1Label: "Adressrad 1",
  line1Placeholder: "Gatuadress",
  line1EmptyText: "Adressrad 1 får inte vara tom",
  line2Label: "Adressrad 2",
  line2Placeholder: "Lägenhetsnummer osv. (valfritt)",
  line2EmptyText: "Adressrad 2 får inte vara tom",
  cityLabel: "Ort",
  cityEmptyText: "Staden får inte vara tom",
  postalCodeLabel: "Postnummer",
  postalCodeEmptyText: "Postnummer får inte vara tomt",
  postalCodeInvalidText: "Ogiltigt postnummer",
  stateLabel: "Region",
  stateEmptyText: "Staten får inte vara tom",
  fullNamePlaceholder: "För- och efternamn",
  countryLabel: "Land",
  currencyLabel: "Valuta",
  bankLabel: "Välj bank",
  redirectText: "När du har skickat in din beställning kommer du att omdirigeras för att säkert slutföra ditt köp.",
  bankDetailsText: "När du har skickat in dessa uppgifter får du bankkontoinformation för att göra betalningen. Se till att komma ihåg den.",
  orPayUsing: "Eller betala med",
  addNewCard: "Lägg till kredit-/betalkort",
  useExisitingSavedCards: "Använd sparade betal-/kreditkort",
  saveCardDetails: "Spara kortuppgifter",
  addBankAccount: "Lägg till bankkonto",
  achBankDebitTerms: localeStrings_achBankDebitTerms,
  sepaDebitTerms: localeStrings_sepaDebitTerms,
  becsDebitTerms: "Genom att ange dina bankkontouppgifter och bekräfta denna betalning godkänner du denna autogirering och serviceavtalet för autogirering och godkänner att Hyperswitch Payments Australia Pty Ltd ACN 160 180 343 med användar-ID för direktdebitering 507156 ( \"Hyperswitch\") debiterar ditt konto via Bulk Electronic Clearing System (BECS) på uppdrag av Hyperswitch Payment Widget (\"Handlaren\") för eventuella belopp som separat meddelats dig av Handlaren. Du intygar att du antingen är kontoinnehavare eller behörig undertecknare för kontot som anges ovan.",
  cardTerms: localeStrings_cardTerms,
  payNowButton: "Betala nu",
  cardNumberEmptyText: "Kortnummer får inte vara tomt",
  cardExpiryDateEmptyText: "Kortets utgångsdatum får inte vara tomt",
  cvcNumberEmptyText: "CVC-nummer får inte vara tomt",
  enterFieldsText: "Fyll i samtliga fält",
  enterValidDetailsText: "Ange giltiga uppgifter",
  selectPaymentMethodText: "Välj en betalningsmetod och försök igen",
  card: "Kort",
  surchargeMsgAmount: localeStrings_surchargeMsgAmount,
  surchargeMsgAmountForCard: localeStrings_surchargeMsgAmountForCard,
  surchargeMsgAmountForOneClickWallets: "Tilläggsavgift tillkommer",
  billingNameLabel: "Faktureringsnamn",
  billingNamePlaceholder: "Förnamn och efternamn",
  cardHolderName: "Korthållarens namn",
  on: "på",
  and: "och",
  nameEmptyText: localeStrings_nameEmptyText,
  completeNameEmptyText: localeStrings_completeNameEmptyText,
  billingDetailsText: "Faktureringsuppgifter",
  socialSecurityNumberLabel: "Personnummer",
  saveWalletDetails: "Information om plånböcker kommer att sparas vid val",
  morePaymentMethods: "Fler betalningsmetoder",
  useExistingPaymentMethods: "Använd sparade betalningsmetoder",
  cardNickname: "Kortets smeknamn",
  nicknamePlaceholder: "Kortets smeknamn (valfritt)"
};

export {
  localeStrings ,
}
/* react Not a pure module */
