// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GlobalVars$OrcaPaymentPage from "../GlobalVars.bs.js";

var sdkDomainUrl = sdkUrl + publicPath;

var apiEndPoint = {
  contents: undefined
};

function setApiEndPoint(str) {
  apiEndPoint.contents = str;
}

function getApiEndPoint(publishableKeyOpt, isConfirmCallOpt, param) {
  var publishableKey = publishableKeyOpt !== undefined ? publishableKeyOpt : "";
  var isConfirmCall = isConfirmCallOpt !== undefined ? isConfirmCallOpt : false;
  var testMode = publishableKey.startsWith("pk_snd_");
  var str = apiEndPoint.contents;
  if (str !== undefined) {
    return str;
  }
  var backendEndPoint$1 = isConfirmCall ? confirmEndPoint : backendEndPoint;
  if (GlobalVars$OrcaPaymentPage.isProd && testMode) {
    return "https://sandbox.hyperswitch.io";
  } else {
    return backendEndPoint$1;
  }
}

function addCustomPodHeader(arr, switchToCustomPod, param) {
  var customPod = switchToCustomPod !== undefined ? switchToCustomPod : false;
  if (customPod) {
    return Object.fromEntries(arr.concat([[
                      "x-feature",
                      "router-custom-dbd"
                    ]]));
  } else {
    return Object.fromEntries(arr);
  }
}

var switchToInteg = false;

var isLocal = false;

export {
  switchToInteg ,
  isLocal ,
  sdkDomainUrl ,
  apiEndPoint ,
  setApiEndPoint ,
  getApiEndPoint ,
  addCustomPodHeader ,
}
/* sdkDomainUrl Not a pure module */
