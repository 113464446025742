// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

function localeStrings_achBankDebitTerms(str) {
  return "En fournissant votre numéro de compte et en confirmant ce paiement, vous autorisez " + str + " et Hyperswitch, notre fournisseur de services de paiement, à envoyer des instructions à votre banque pour débiter votre compte et à débiter votre compte conformément à ces instructions. Vous avez droit à un remboursement de votre banque selon les modalités de votre accord avec votre banque. Une demande de remboursement doit être effectuée dans les 8 semaines à compter de la date à laquelle votre compte a été débité.";
}

function localeStrings_sepaDebitTerms(str) {
  return "En fournissant vos informations de paiement et en confirmant ce paiement, vous autorisez (A) " + str + " et Hyperswitch, notre fournisseur de services de paiement, et/ou PPRO, son prestataire de services local, à envoyer des instructions à votre banque pour débiter votre compte et (B) votre banque à débiter votre compte conformément à ces instructions. Dans le cadre de vos droits, vous avez droit à un remboursement de votre banque selon les modalités de votre accord avec votre banque. Une demande de remboursement doit être effectuée dans les 8 semaines à compter de la date à laquelle votre compte a été débité. Vos droits sont expliqués dans une déclaration que vous pouvez obtenir auprès de votre banque. Vous acceptez de recevoir des notifications pour les débits futurs jusqu'à 2 jours avant leur occurrence.";
}

function localeStrings_cardTerms(str) {
  return "En fournissant les informations de votre carte, vous autorisez " + str + " à débiter votre carte pour les paiements futurs conformément à leurs conditions.";
}

function localeStrings_surchargeMsgAmount(currency, str) {
  return React.createElement(React.Fragment, {}, "Un montant supplémentaire d'" + Utils$OrcaPaymentPage.nbsp, React.createElement("strong", undefined, currency + " " + str), Utils$OrcaPaymentPage.nbsp + "sera appliqué pour cette transaction");
}

function localeStrings_surchargeMsgAmountForCard(currency, str) {
  return React.createElement(React.Fragment, {}, "Un montant supplémentaire allant jusqu'à" + Utils$OrcaPaymentPage.nbsp, React.createElement("strong", undefined, currency + " " + str), Utils$OrcaPaymentPage.nbsp + "sera appliqué pour cette transaction.");
}

function localeStrings_nameEmptyText(str) {
  return "Veuillez fournir votre " + str;
}

function localeStrings_completeNameEmptyText(str) {
  return "Veuillez fournir votre complet " + str;
}

var localeStrings = {
  locale: "fr",
  cardNumberLabel: "Numéro de carte",
  localeDirection: "ltr",
  inValidCardErrorText: "Le numéro de carte n'est pas valide.",
  inCompleteCVCErrorText: "Le code de sécurité de votre carte est incomplet.",
  inCompleteExpiryErrorText: "La date d'expiration de votre carte est incomplète.",
  pastExpiryErrorText: "L'année d'expiration de votre carte est passée.",
  poweredBy: "Propulsé par Hyperswitch",
  validThruText: "Expiration",
  sortCodeText: "Code de tri",
  cvcTextLabel: "Code CVC",
  emailLabel: "E-mail",
  emailEmptyText: "L'e-mail ne peut pas être vide",
  emailInvalidText: "Adresse e-mail invalide",
  accountNumberText: "Numéro de compte",
  fullNameLabel: "Nom complet",
  line1Label: "Adresse - Ligne 1",
  line1Placeholder: "Adresse de rue",
  line1EmptyText: "La ligne d'adresse 1 ne peut pas être vide",
  line2Label: "Adresse - Ligne 2",
  line2Placeholder: "Appartement, numéro d'unité, etc (facultatif)",
  line2EmptyText: "La ligne d'adresse 2 ne peut pas être vide",
  cityLabel: "Ville",
  cityEmptyText: "La ville ne peut pas être vide",
  postalCodeLabel: "Code postal",
  postalCodeEmptyText: "Le code postal ne peut pas être vide",
  postalCodeInvalidText: "Code postal invalide",
  stateLabel: "État",
  stateEmptyText: "L'état ne peut pas être vide",
  fullNamePlaceholder: "Prénom et nom de famille",
  countryLabel: "Pays",
  currencyLabel: "Devise",
  bankLabel: "Sélectionnez une banque",
  redirectText: "Après avoir soumis votre commande, vous serez redirigé(e) pour compléter votre achat en toute sécurité.",
  bankDetailsText: "Après avoir soumis ces détails, vous obtiendrez les informations de compte bancaire pour effectuer le paiement. Assurez-vous de les noter.",
  orPayUsing: "Ou payer avec",
  addNewCard: "Ajouter une carte de crédit/débit",
  useExisitingSavedCards: "Utiliser les cartes de débit/crédit enregistrées",
  saveCardDetails: "Enregistrer les détails de la carte",
  addBankAccount: "Ajouter un compte bancaire",
  achBankDebitTerms: localeStrings_achBankDebitTerms,
  sepaDebitTerms: localeStrings_sepaDebitTerms,
  becsDebitTerms: "En fournissant les détails de votre compte bancaire et en confirmant ce paiement, vous acceptez cette demande de prélèvement direct et l'accord de service de demande de prélèvement direct, et autorisez Hyperswitch Payments Australia Pty Ltd ACN 160 180 343 numéro d'identification de l'utilisateur de prélèvement direct 507156 (“Hyperswitch”) à débiter votre compte via le système de compensation électronique en masse (BECS) au nom de Hyperswitch Payment Widget (le \"Marchand\") pour les montants communiqués séparément par le Marchand. Vous certifiez que vous êtes soit titulaire d'un compte, soit signataire autorisé sur le compte indiqué ci-dessus.",
  cardTerms: localeStrings_cardTerms,
  payNowButton: "Payer maintenant",
  cardNumberEmptyText: "Le numéro de carte ne peut pas être vide",
  cardExpiryDateEmptyText: "La date d'expiration de la carte ne peut pas être vide",
  cvcNumberEmptyText: "Le numéro CVC ne peut pas être vide",
  enterFieldsText: "Veuillez saisir tous les champs",
  enterValidDetailsText: "Veuillez saisir des informations valides",
  selectPaymentMethodText: "Veuillez sélectionner un mode de paiement et réessayer",
  card: "Carte",
  surchargeMsgAmount: localeStrings_surchargeMsgAmount,
  surchargeMsgAmountForCard: localeStrings_surchargeMsgAmountForCard,
  surchargeMsgAmountForOneClickWallets: "Frais supplémentaires applicables",
  billingNameLabel: "Nom de facturation",
  billingNamePlaceholder: "Prénom et nom de famille",
  cardHolderName: "Nom du titulaire",
  on: "sur",
  and: "et",
  nameEmptyText: localeStrings_nameEmptyText,
  completeNameEmptyText: localeStrings_completeNameEmptyText,
  billingDetailsText: "Détails de la facturation",
  socialSecurityNumberLabel: "Numéro de sécurité sociale",
  saveWalletDetails: "Les détails du portefeuille seront enregistrés lors de la sélection",
  morePaymentMethods: "Plus de méthodes de paiement",
  useExistingPaymentMethods: "Utiliser les modes de paiement enregistrés",
  cardNickname: "Pseudonyme de la carte",
  nicknamePlaceholder: "Surnom de la carte (facultatif)"
};

export {
  localeStrings ,
}
/* react Not a pure module */
