// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Utils$OrcaPaymentPage from "./Utils.bs.js";
import * as CardUtils$OrcaPaymentPage from "../CardUtils.bs.js";
import * as PaymentMethodsRecord$OrcaPaymentPage from "../Payments/PaymentMethodsRecord.bs.js";

function cardPaymentBody(cardNumber, month, year, cardHolderName, cvcNumber, cardBrand, nicknameOpt, param) {
  var nickname = nicknameOpt !== undefined ? nicknameOpt : "";
  var cardBody = [
    [
      "card_number",
      CardUtils$OrcaPaymentPage.clearSpaces(cardNumber)
    ],
    [
      "card_exp_month",
      month
    ],
    [
      "card_exp_year",
      year
    ],
    [
      "card_holder_name",
      cardHolderName
    ],
    [
      "card_cvc",
      cvcNumber
    ],
    [
      "card_issuer",
      ""
    ]
  ];
  if (nickname !== "") {
    cardBody.push([
          "nick_name",
          nickname
        ]);
  }
  return [
          [
            "payment_method",
            "card"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "card",
                    Object.fromEntries(cardBody.concat(cardBrand))
                  ]])
          ]
        ];
}

function bancontactBody() {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "bancontact_card"
          ]
        ];
}

function boletoBody(socialSecurityNumber) {
  return [
          [
            "payment_method",
            "voucher"
          ],
          [
            "payment_method_type",
            "boleto"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "voucher",
                    Object.fromEntries([[
                            "boleto",
                            Object.fromEntries([[
                                    "social_security_number",
                                    socialSecurityNumber
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function savedCardBody(paymentToken, customerId, cvcNumber, requiresCvv) {
  var savedCardBody$1 = [
    [
      "payment_method",
      "card"
    ],
    [
      "payment_token",
      paymentToken
    ],
    [
      "customer_id",
      customerId
    ]
  ];
  if (requiresCvv) {
    savedCardBody$1.push([
          "card_cvc",
          cvcNumber
        ]);
  }
  return savedCardBody$1;
}

var customerAcceptanceBody = Object.fromEntries([
      [
        "acceptance_type",
        "online"
      ],
      [
        "accepted_at",
        new Date(Date.now()).toISOString()
      ],
      [
        "online",
        Object.fromEntries([[
                "user_agent",
                navigator.userAgent
              ]])
      ]
    ]);

function savedPaymentMethodBody(paymentToken, customerId, paymentMethod, paymentMethodType) {
  return [
          [
            "payment_method",
            paymentMethod
          ],
          [
            "payment_token",
            paymentToken
          ],
          [
            "customer_id",
            customerId
          ],
          [
            "payment_method_type",
            paymentMethodType
          ]
        ];
}

function mandateBody(paymentType) {
  return [
          [
            "mandate_data",
            Object.fromEntries([[
                    "customer_acceptance",
                    customerAcceptanceBody
                  ]])
          ],
          [
            "customer_acceptance",
            customerAcceptanceBody
          ],
          [
            "setup_future_usage",
            "off_session"
          ],
          [
            "payment_type",
            paymentType === "" ? null : paymentType
          ]
        ];
}

function paymentTypeBody(paymentType) {
  if (paymentType !== "") {
    return [[
              "payment_type",
              paymentType
            ]];
  } else {
    return [];
  }
}

function confirmPayloadForSDKButton(sdkHandleConfirmPayment) {
  return Object.fromEntries([
              [
                "redirect",
                "always"
              ],
              [
                "confirmParams",
                Object.fromEntries([[
                        "return_url",
                        sdkHandleConfirmPayment.confirmParams.return_url
                      ]])
              ]
            ]);
}

function achBankDebitBody(email, bank, cardHolderName, line1, line2, country, city, postalCode, state, paymentType) {
  return [
            [
              "payment_method",
              "bank_debit"
            ],
            [
              "setup_future_usage",
              "off_session"
            ],
            [
              "payment_method_type",
              "ach"
            ],
            [
              "payment_method_data",
              Object.fromEntries([[
                      "bank_debit",
                      Object.fromEntries([[
                              "ach_bank_debit",
                              Object.fromEntries([
                                    [
                                      "billing_details",
                                      Object.fromEntries([
                                            [
                                              "name",
                                              cardHolderName
                                            ],
                                            [
                                              "email",
                                              email
                                            ],
                                            [
                                              "address",
                                              Object.fromEntries([
                                                    [
                                                      "line1",
                                                      line1
                                                    ],
                                                    [
                                                      "line2",
                                                      line2
                                                    ],
                                                    [
                                                      "city",
                                                      city
                                                    ],
                                                    [
                                                      "state",
                                                      state
                                                    ],
                                                    [
                                                      "zip",
                                                      postalCode
                                                    ],
                                                    [
                                                      "country",
                                                      country
                                                    ]
                                                  ])
                                            ]
                                          ])
                                    ],
                                    [
                                      "account_number",
                                      bank.accountNumber
                                    ],
                                    [
                                      "bank_account_holder_name",
                                      bank.accountHolderName
                                    ],
                                    [
                                      "routing_number",
                                      bank.routingNumber
                                    ],
                                    [
                                      "bank_type",
                                      bank.accountType
                                    ]
                                  ])
                            ]])
                    ]])
            ]
          ].concat(mandateBody(PaymentMethodsRecord$OrcaPaymentPage.paymentTypeToStringMapper(paymentType)));
}

function sepaBankDebitBody(fullName, email, data, line1, line2, country, city, postalCode, state) {
  return [
          [
            "payment_method",
            "bank_debit"
          ],
          [
            "payment_method_type",
            "sepa"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_debit",
                    Object.fromEntries([[
                            "sepa_bank_debit",
                            Object.fromEntries([
                                  [
                                    "billing_details",
                                    Object.fromEntries([
                                          [
                                            "name",
                                            fullName
                                          ],
                                          [
                                            "email",
                                            email
                                          ],
                                          [
                                            "address",
                                            Object.fromEntries([
                                                  [
                                                    "line1",
                                                    line1
                                                  ],
                                                  [
                                                    "line2",
                                                    line2
                                                  ],
                                                  [
                                                    "city",
                                                    city
                                                  ],
                                                  [
                                                    "state",
                                                    state
                                                  ],
                                                  [
                                                    "zip",
                                                    postalCode
                                                  ],
                                                  [
                                                    "country",
                                                    country
                                                  ]
                                                ])
                                          ]
                                        ])
                                  ],
                                  [
                                    "iban",
                                    data.iban
                                  ],
                                  [
                                    "bank_account_holder_name",
                                    data.accountHolderName
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function bacsBankDebitBody(email, accNum, sortCode, line1, line2, city, zip, state, country, bankAccountHolderName) {
  return [
          [
            "payment_method",
            "bank_debit"
          ],
          [
            "payment_method_type",
            "bacs"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_debit",
                    Object.fromEntries([[
                            "bacs_bank_debit",
                            Object.fromEntries([
                                  [
                                    "billing_details",
                                    Object.fromEntries([
                                          [
                                            "name",
                                            bankAccountHolderName
                                          ],
                                          [
                                            "email",
                                            email
                                          ],
                                          [
                                            "address",
                                            Object.fromEntries([
                                                  [
                                                    "line1",
                                                    line1
                                                  ],
                                                  [
                                                    "line2",
                                                    line2
                                                  ],
                                                  [
                                                    "city",
                                                    city
                                                  ],
                                                  [
                                                    "zip",
                                                    zip
                                                  ],
                                                  [
                                                    "state",
                                                    state
                                                  ],
                                                  [
                                                    "country",
                                                    country
                                                  ]
                                                ])
                                          ]
                                        ])
                                  ],
                                  [
                                    "bank_account_holder_name",
                                    bankAccountHolderName
                                  ],
                                  [
                                    "sort_code",
                                    sortCode
                                  ],
                                  [
                                    "account_number",
                                    accNum
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function becsBankDebitBody(fullName, email, data, line1, line2, country, city, postalCode, state) {
  return [
          [
            "payment_method",
            "bank_debit"
          ],
          [
            "payment_method_type",
            "becs"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_debit",
                    Object.fromEntries([[
                            "becs_bank_debit",
                            Object.fromEntries([
                                  [
                                    "billing_details",
                                    Object.fromEntries([
                                          [
                                            "name",
                                            fullName
                                          ],
                                          [
                                            "email",
                                            email
                                          ],
                                          [
                                            "address",
                                            Object.fromEntries([
                                                  [
                                                    "line1",
                                                    line1
                                                  ],
                                                  [
                                                    "line2",
                                                    line2
                                                  ],
                                                  [
                                                    "city",
                                                    city
                                                  ],
                                                  [
                                                    "state",
                                                    state
                                                  ],
                                                  [
                                                    "zip",
                                                    postalCode
                                                  ],
                                                  [
                                                    "country",
                                                    country
                                                  ]
                                                ])
                                          ]
                                        ])
                                  ],
                                  [
                                    "bsb_number",
                                    data.sortCode
                                  ],
                                  [
                                    "account_number",
                                    data.accountNumber
                                  ],
                                  [
                                    "bank_account_holder_name",
                                    data.accountHolderName
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function klarnaRedirectionBody(fullName, email, country, connectors) {
  return [
          [
            "payment_method",
            "pay_later"
          ],
          [
            "payment_method_type",
            "klarna"
          ],
          [
            "payment_experience",
            "redirect_to_url"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "name",
            fullName
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "pay_later",
                    Object.fromEntries([[
                            "klarna_redirect",
                            Object.fromEntries([
                                  [
                                    "billing_email",
                                    email
                                  ],
                                  [
                                    "billing_country",
                                    country
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function klarnaSDKbody(token, connectors) {
  return [
          [
            "payment_method",
            "pay_later"
          ],
          [
            "payment_method_type",
            "klarna"
          ],
          [
            "payment_experience",
            "invoke_sdk_client"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "pay_later",
                    Object.fromEntries([[
                            "klarna_sdk",
                            Object.fromEntries([[
                                    "token",
                                    token
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function paypalRedirectionBody(connectors) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "paypal"
          ],
          [
            "payment_experience",
            "redirect_to_url"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "paypal_redirect",
                            Object.fromEntries([])
                          ]])
                  ]])
          ]
        ];
}

function paypalSdkBody(token, connectors) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "paypal"
          ],
          [
            "payment_experience",
            "invoke_sdk_client"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "paypal_sdk",
                            Object.fromEntries([[
                                    "token",
                                    token
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function gpayBody(payObj, connectors) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "google_pay"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "google_pay",
                            Object.fromEntries([
                                  [
                                    "type",
                                    payObj.paymentMethodData.type
                                  ],
                                  [
                                    "description",
                                    payObj.paymentMethodData.description
                                  ],
                                  [
                                    "info",
                                    Utils$OrcaPaymentPage.transformKeys(payObj.paymentMethodData.info, "SnakeCase")
                                  ],
                                  [
                                    "tokenization_data",
                                    Utils$OrcaPaymentPage.transformKeys(payObj.paymentMethodData.tokenizationData, "SnakeCase")
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function gpayRedirectBody(connectors) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "google_pay"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "google_pay_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function gPayThirdPartySdkBody(connectors) {
  return [
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "google_pay"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "google_pay_third_party_sdk",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function applePayBody(token, connectors) {
  var dict = Core__Option.getOr(Core__JSON.Decode.object(token), {});
  var paymentDataString = window.btoa(JSON.stringify(Core__Option.getOr(dict["paymentData"], {})));
  dict["paymentData"] = paymentDataString;
  return [
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "apple_pay"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "apple_pay",
                            Utils$OrcaPaymentPage.transformKeys(dict, "SnakeCase")
                          ]])
                  ]])
          ]
        ];
}

function applePayRedirectBody(connectors) {
  return [
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "apple_pay"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "apple_pay_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function applePayThirdPartySdkBody(connectors) {
  return [
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "apple_pay"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "apple_pay_third_party_sdk",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function affirmBody() {
  return [
          [
            "payment_method",
            "pay_later"
          ],
          [
            "payment_method_type",
            "affirm"
          ],
          [
            "payment_experience",
            "redirect_to_url"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "pay_later",
                    Object.fromEntries([[
                            "affirm_redirect",
                            Object.fromEntries([])
                          ]])
                  ]])
          ]
        ];
}

function cryptoBody(currency) {
  return [
          [
            "payment_method",
            "crypto"
          ],
          [
            "payment_method_type",
            "crypto_currency"
          ],
          [
            "payment_experience",
            "redirect_to_url"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "crypto",
                    Object.fromEntries([[
                            "pay_currency",
                            currency
                          ]])
                  ]])
          ]
        ];
}

function afterpayRedirectionBody(fullName, email) {
  return [
          [
            "payment_method",
            "pay_later"
          ],
          [
            "payment_method_type",
            "afterpay_clearpay"
          ],
          [
            "payment_experience",
            "redirect_to_url"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "pay_later",
                    Object.fromEntries([[
                            "afterpay_clearpay_redirect",
                            Object.fromEntries([
                                  [
                                    "billing_email",
                                    email
                                  ],
                                  [
                                    "billing_name",
                                    fullName
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function giroPayBody(name, ibanOpt, param) {
  var iban = ibanOpt !== undefined ? ibanOpt : "";
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "giropay"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_redirect",
                    Object.fromEntries([[
                            "giropay",
                            Object.fromEntries([
                                  [
                                    "billing_details",
                                    Object.fromEntries([[
                                            "billing_name",
                                            name
                                          ]])
                                  ],
                                  [
                                    "bank_account_iban",
                                    iban
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function sofortBody(country, name, email) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "sofort"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_redirect",
                    Object.fromEntries([[
                            "sofort",
                            Object.fromEntries([
                                  [
                                    "country",
                                    country === "" ? "US" : country
                                  ],
                                  [
                                    "preferred_language",
                                    "en"
                                  ],
                                  [
                                    "billing_details",
                                    Object.fromEntries([
                                          [
                                            "billing_name",
                                            name
                                          ],
                                          [
                                            "email",
                                            email === "" ? "test@gmail.com" : email
                                          ]
                                        ])
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function iDealBody(name, bankName) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "ideal"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_redirect",
                    Object.fromEntries([[
                            "ideal",
                            Object.fromEntries([
                                  [
                                    "billing_details",
                                    Object.fromEntries([[
                                            "billing_name",
                                            name
                                          ]])
                                  ],
                                  [
                                    "bank_name",
                                    bankName === "" ? "american_express" : bankName
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function epsBody(name, bankName) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "eps"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_redirect",
                    Object.fromEntries([[
                            "eps",
                            Object.fromEntries([
                                  [
                                    "billing_details",
                                    Object.fromEntries([[
                                            "billing_name",
                                            name
                                          ]])
                                  ],
                                  [
                                    "bank_name",
                                    bankName === "" ? "american_express" : bankName
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function achBankTransferBody(email, connectors) {
  return [
          [
            "payment_method",
            "bank_transfer"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_type",
            "ach"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_transfer",
                    Object.fromEntries([[
                            "ach_bank_transfer",
                            Object.fromEntries([[
                                    "billing_details",
                                    Object.fromEntries([[
                                            "email",
                                            email
                                          ]])
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function bacsBankTransferBody(email, name, connectors) {
  return [
          [
            "payment_method",
            "bank_transfer"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_type",
            "bacs"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_transfer",
                    Object.fromEntries([[
                            "bacs_bank_transfer",
                            Object.fromEntries([[
                                    "billing_details",
                                    Object.fromEntries([
                                          [
                                            "email",
                                            email
                                          ],
                                          [
                                            "name",
                                            name
                                          ]
                                        ])
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function sepaBankTransferBody(email, name, country, connectors) {
  return [
          [
            "payment_method",
            "bank_transfer"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_type",
            "sepa"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_transfer",
                    Object.fromEntries([[
                            "sepa_bank_transfer",
                            Object.fromEntries([
                                  [
                                    "billing_details",
                                    Object.fromEntries([
                                          [
                                            "email",
                                            email
                                          ],
                                          [
                                            "name",
                                            name
                                          ]
                                        ])
                                  ],
                                  [
                                    "country",
                                    country
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function blikBody(blikCode) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "blik"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_redirect",
                    Object.fromEntries([[
                            "blik",
                            Object.fromEntries([[
                                    "blik_code",
                                    blikCode
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function p24Body(email) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "przelewy24"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_redirect",
                    Object.fromEntries([[
                            "przelewy24",
                            Object.fromEntries([[
                                    "billing_details",
                                    Object.fromEntries([[
                                            "email",
                                            email
                                          ]])
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function interacBody(email, country) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "interac"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_redirect",
                    Object.fromEntries([[
                            "interac",
                            Object.fromEntries([
                                  [
                                    "email",
                                    email
                                  ],
                                  [
                                    "country",
                                    country
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function mobilePayBody() {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "mobile_pay"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "mobile_pay",
                            Object.fromEntries([])
                          ]])
                  ]])
          ]
        ];
}

function aliPayRedirectBody() {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "ali_pay"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "ali_pay_redirect",
                            Object.fromEntries([])
                          ]])
                  ]])
          ]
        ];
}

function aliPayQrBody() {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "ali_pay"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "ali_pay_qr",
                            Object.fromEntries([])
                          ]])
                  ]])
          ]
        ];
}

function weChatPayRedirectBody() {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "we_chat_pay"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "we_chat_pay_redirect",
                            Object.fromEntries([])
                          ]])
                  ]])
          ]
        ];
}

function weChatPayQrBody() {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "we_chat_pay"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "we_chat_pay_qr",
                            Object.fromEntries([])
                          ]])
                  ]])
          ]
        ];
}

function trustlyBody(country) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "trustly"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_redirect",
                    Object.fromEntries([[
                            "trustly",
                            Object.fromEntries([[
                                    "country",
                                    country
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function finlandOB() {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "online_banking_finland"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_redirect",
                    Object.fromEntries([[
                            "online_banking_finland",
                            Object.fromEntries([])
                          ]])
                  ]])
          ]
        ];
}

function polandOB(bank) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "online_banking_poland"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_redirect",
                    Object.fromEntries([[
                            "online_banking_poland",
                            Object.fromEntries([[
                                    "issuer",
                                    bank
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function czechOB(bank) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "online_banking_czech_republic"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_redirect",
                    Object.fromEntries([[
                            "online_banking_czech_republic",
                            Object.fromEntries([[
                                    "issuer",
                                    bank
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function slovakiaOB(bank) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "online_banking_slovakia"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_redirect",
                    Object.fromEntries([[
                            "online_banking_slovakia",
                            Object.fromEntries([[
                                    "issuer",
                                    bank
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function walleyBody() {
  return [
          [
            "payment_method",
            "pay_later"
          ],
          [
            "payment_method_type",
            "walley"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "pay_later",
                    Object.fromEntries([[
                            "walley_redirect",
                            Object.fromEntries([])
                          ]])
                  ]])
          ]
        ];
}

function payBrightBody() {
  return [
          [
            "payment_method",
            "pay_later"
          ],
          [
            "payment_method_type",
            "pay_bright"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "pay_later",
                    Object.fromEntries([[
                            "pay_bright_redirect",
                            Object.fromEntries([])
                          ]])
                  ]])
          ]
        ];
}

function mbWayBody(phoneNumber) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "mb_way"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "mb_way_redirect",
                            Object.fromEntries([[
                                    "telephone_number",
                                    phoneNumber
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function twintBody() {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "twint"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "twint_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function vippsBody() {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "vipps"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "vipps_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function danaBody() {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "dana"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "dana_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function goPayBody() {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "go_pay"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "go_pay_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function kakaoPayBody() {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "kakao_pay"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "kakao_pay_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function gcashBody() {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "gcash"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "gcash_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function momoBody() {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "momo"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "momo_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function touchNGoBody() {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "touch_n_go"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Object.fromEntries([[
                            "touch_n_go_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function bizumBody() {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "bizum"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_redirect",
                    Object.fromEntries([[
                            "bizum",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function rewardBody(paymentMethodType) {
  return [
          [
            "payment_method",
            "reward"
          ],
          [
            "payment_method_type",
            paymentMethodType
          ],
          [
            "payment_method_data",
            "reward"
          ]
        ];
}

function fpxOBBody(bank) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "online_banking_fpx"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_redirect",
                    Object.fromEntries([[
                            "online_banking_fpx",
                            Object.fromEntries([[
                                    "issuer",
                                    bank
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function thailandOBBody(bank) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "online_banking_thailand"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_redirect",
                    Object.fromEntries([[
                            "online_banking_thailand",
                            Object.fromEntries([[
                                    "issuer",
                                    bank
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function almaBody() {
  return [
          [
            "payment_method",
            "pay_later"
          ],
          [
            "payment_method_type",
            "alma"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "pay_later",
                    Object.fromEntries([[
                            "alma",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function atomeBody() {
  return [
          [
            "payment_method",
            "pay_later"
          ],
          [
            "payment_method_type",
            "atome"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "pay_later",
                    Object.fromEntries([[
                            "atome_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function multibancoBody(email) {
  return [
          [
            "payment_method",
            "bank_transfer"
          ],
          [
            "payment_method_type",
            "multibanco"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_transfer",
                    Object.fromEntries([[
                            "multibanco_bank_transfer",
                            Object.fromEntries([[
                                    "billing_details",
                                    Object.fromEntries([[
                                            "email",
                                            email
                                          ]])
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function cardRedirectBody() {
  return [
          [
            "payment_method",
            "card_redirect"
          ],
          [
            "payment_method_type",
            "card_redirect"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "card_redirect",
                    Object.fromEntries([[
                            "card_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function openBankingUKBody() {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "open_banking_uk"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_redirect",
                    Object.fromEntries([[
                            "open_banking_uk",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function pixTransferBody() {
  return [
          [
            "payment_method",
            "bank_transfer"
          ],
          [
            "payment_method_type",
            "pix"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_transfer",
                    Object.fromEntries([[
                            "pix",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function localBankTransferBody() {
  return [
          [
            "payment_method",
            "bank_transfer"
          ],
          [
            "payment_method_type",
            "local_bank_transfer"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "bank_transfer",
                    Object.fromEntries([[
                            "local_bank_transfer",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function getPaymentBody(paymentMethod, fullName, email, country, bank, blikCode, paymentExperienceOpt, phoneNumber, currency) {
  var paymentExperience = paymentExperienceOpt !== undefined ? paymentExperienceOpt : "RedirectToURL";
  switch (paymentMethod) {
    case "affirm" :
        return affirmBody();
    case "afterpay_clearpay" :
        return afterpayRedirectionBody(fullName, email);
    case "ali_pay" :
        switch (paymentExperience) {
          case "InvokeSDK" :
          case "RedirectToURL" :
              return aliPayRedirectBody();
          case "QrFlow" :
              return aliPayQrBody();
          
        }
    case "alma" :
        return almaBody();
    case "atome" :
        return atomeBody();
    case "bizum" :
        return bizumBody();
    case "blik" :
        return blikBody(blikCode);
    case "card_redirect" :
        return cardRedirectBody();
    case "crypto_currency" :
        return cryptoBody(currency);
    case "dana" :
        return danaBody();
    case "eps" :
        return epsBody(fullName, bank);
    case "classic" :
    case "evoucher" :
        return rewardBody(paymentMethod);
    case "gcash" :
        return gcashBody();
    case "giropay" :
        return giroPayBody(fullName, undefined, undefined);
    case "go_pay" :
        return goPayBody();
    case "ideal" :
        return iDealBody(fullName, bank);
    case "interac" :
        return interacBody(email, country);
    case "kakao_pay" :
        return kakaoPayBody();
    case "local_bank_transfer_transfer" :
        return localBankTransferBody();
    case "mb_way" :
        return mbWayBody(phoneNumber);
    case "mobile_pay" :
        return mobilePayBody();
    case "momo" :
        return momoBody();
    case "multibanco" :
        return multibancoBody(email);
    case "online_banking_czech_republic" :
        return czechOB(bank);
    case "online_banking_finland" :
        return finlandOB();
    case "online_banking_fpx" :
        return fpxOBBody(bank);
    case "online_banking_poland" :
        return polandOB(bank);
    case "online_banking_slovakia" :
        return slovakiaOB(bank);
    case "online_banking_thailand" :
        return thailandOBBody(bank);
    case "open_banking_uk" :
        return openBankingUKBody();
    case "pay_bright" :
        return payBrightBody();
    case "pix_transfer" :
        return pixTransferBody();
    case "przelewy24" :
        return p24Body(email);
    case "sofort" :
        return sofortBody(country, fullName, email);
    case "touch_n_go" :
        return touchNGoBody();
    case "trustly" :
        return trustlyBody(country);
    case "twint" :
        return twintBody();
    case "vipps" :
        return vippsBody();
    case "walley" :
        return walleyBody();
    case "we_chat_pay" :
        switch (paymentExperience) {
          case "InvokeSDK" :
          case "RedirectToURL" :
              return weChatPayRedirectBody();
          case "QrFlow" :
              return weChatPayQrBody();
          
        }
    default:
      return [];
  }
}

export {
  cardPaymentBody ,
  bancontactBody ,
  boletoBody ,
  savedCardBody ,
  customerAcceptanceBody ,
  savedPaymentMethodBody ,
  mandateBody ,
  paymentTypeBody ,
  confirmPayloadForSDKButton ,
  achBankDebitBody ,
  sepaBankDebitBody ,
  bacsBankDebitBody ,
  becsBankDebitBody ,
  klarnaRedirectionBody ,
  klarnaSDKbody ,
  paypalRedirectionBody ,
  paypalSdkBody ,
  gpayBody ,
  gpayRedirectBody ,
  gPayThirdPartySdkBody ,
  applePayBody ,
  applePayRedirectBody ,
  applePayThirdPartySdkBody ,
  affirmBody ,
  cryptoBody ,
  afterpayRedirectionBody ,
  giroPayBody ,
  sofortBody ,
  iDealBody ,
  epsBody ,
  achBankTransferBody ,
  bacsBankTransferBody ,
  sepaBankTransferBody ,
  blikBody ,
  p24Body ,
  interacBody ,
  mobilePayBody ,
  aliPayRedirectBody ,
  aliPayQrBody ,
  weChatPayRedirectBody ,
  weChatPayQrBody ,
  trustlyBody ,
  finlandOB ,
  polandOB ,
  czechOB ,
  slovakiaOB ,
  walleyBody ,
  payBrightBody ,
  mbWayBody ,
  twintBody ,
  vippsBody ,
  danaBody ,
  goPayBody ,
  kakaoPayBody ,
  gcashBody ,
  momoBody ,
  touchNGoBody ,
  bizumBody ,
  rewardBody ,
  fpxOBBody ,
  thailandOBBody ,
  almaBody ,
  atomeBody ,
  multibancoBody ,
  cardRedirectBody ,
  openBankingUKBody ,
  pixTransferBody ,
  localBankTransferBody ,
  getPaymentBody ,
}
/* customerAcceptanceBody Not a pure module */
