// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Icon$OrcaPaymentPage from "../Icon.bs.js";
import * as Modal$OrcaPaymentPage from "../Components/Modal.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

function getKeyValue(json, str) {
  return Core__Option.getOr(Core__JSON.Decode.string(Core__Option.getOr(json[str], {})), "");
}

function BankTransfersPopup(props) {
  var match = React.useState(function () {
        return [];
      });
  var setKeys = match[1];
  var keys = match[0];
  var match$1 = React.useState(function () {
        return {};
      });
  var setJson = match$1[1];
  var json = match$1[0];
  var match$2 = React.useState(function () {
        return {};
      });
  var setPostData = match$2[1];
  var postData = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var setReturnUrl = match$3[1];
  var return_url = match$3[0];
  var match$4;
  switch (props.transferType) {
    case "achBankTransfer" :
        match$4 = [
          "ach_credit_transfer",
          "ACH"
        ];
        break;
    case "bacsBankTransfer" :
        match$4 = [
          "bacs_bank_instructions",
          "BACS"
        ];
        break;
    case "sepaBankTransfer" :
        match$4 = [
          "sepa_bank_instructions",
          "SEPA"
        ];
        break;
    default:
      match$4 = [
        "",
        ""
      ];
  }
  var responseType = match$4[0];
  var match$5 = React.useState(function () {
        return false;
      });
  var setIsCopied = match$5[1];
  var isCopied = match$5[0];
  var match$6 = React.useState(function () {
        return false;
      });
  var setOpenModal = match$6[1];
  var match$7 = React.useMemo((function () {
          if (isCopied) {
            return [
                    React.createElement(React.Fragment, {}, React.createElement(Icon$OrcaPaymentPage.make, {
                              name: "ticMark",
                              size: 22
                            }), "Copied"),
                    "text-[#c0c0c0] font-medium "
                  ];
          } else {
            return [
                    React.createElement(React.Fragment, {}, React.createElement(Icon$OrcaPaymentPage.make, {
                              name: "copyIcon",
                              size: 22
                            }), "Copy"),
                    "text-[#006DF9]"
                  ];
          }
        }), [isCopied]);
  React.useEffect((function () {
          Utils$OrcaPaymentPage.handlePostMessage(undefined, [[
                  "iframeMountedCallback",
                  true
                ]]);
          var handle = function (ev) {
            var json = JSON.parse(ev.data);
            var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
            if (!Core__Option.isSome(dict["fullScreenIframeMounted"])) {
              return ;
            }
            var metadata = Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "metadata");
            var dictMetadata = Utils$OrcaPaymentPage.getDictFromJson(Core__Option.getOr(Utils$OrcaPaymentPage.getDictFromJson(Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "metadata"))[responseType], {}));
            setKeys(function (param) {
                  return Object.keys(dictMetadata);
                });
            setJson(function (param) {
                  return dictMetadata;
                });
            setPostData(function (param) {
                  return Utils$OrcaPaymentPage.getJsonObjectFromDict(Utils$OrcaPaymentPage.getDictFromJson(metadata), "data");
                });
            setReturnUrl(function (param) {
                  return Utils$OrcaPaymentPage.getString(Utils$OrcaPaymentPage.getDictFromJson(metadata), "url", "");
                });
          };
          window.addEventListener("message", handle);
          return (function () {
                    window.removeEventListener("message", handle);
                  });
        }), []);
  return React.createElement(Modal$OrcaPaymentPage.make, {
              children: React.createElement("div", {
                    className: "flex flex-col h-full justify-between items-center"
                  }, React.createElement("div", {
                        className: "flex flex-col w-full mt-4 max-w-md justify-between items-center"
                      }, React.createElement("div", {
                            className: "PopupIcon m-1 p-2"
                          }, React.createElement(Icon$OrcaPaymentPage.make, {
                                name: "ach-transfer",
                                size: 45
                              })), React.createElement("div", {
                            className: "Popuptitle flex w-[90%] justify-center"
                          }, React.createElement("span", {
                                className: "font-bold text-lg"
                              }, match$4[1] + " bank transfer")), React.createElement("div", {
                            className: "PopupSubtitle w-[90%] text-center"
                          }, React.createElement("span", {
                                className: "font-medium text-sm text-[#151A1F] opacity-50"
                              }, "Use these details to transfer amount")), React.createElement("div", {
                            className: "DetailsSection w-full m-8 text-center",
                            style: {
                              background: "rgba(21, 26, 31, 0.03)",
                              border: "1px solid rgba(21, 26, 31, 0.06)",
                              borderRadius: "5px"
                            }
                          }, React.createElement("div", {
                                className: "flex font-medium p-5 justify-between text-sm",
                                style: {
                                  borderBottom: "1px dashed rgba(21, 26, 31, 0.06)"
                                }
                              }, React.createElement("span", {
                                    className: "text-[#151a1fe6]"
                                  }, "Bank Account Details"), React.createElement("button", {
                                    className: "flex flex-row " + match$7[1] + " cursor-pointer",
                                    onClick: (function (param) {
                                        var text = keys.map(function (item) {
                                                return Utils$OrcaPaymentPage.snakeToTitleCase(item) + " : " + getKeyValue(json, item);
                                              }).join("\n");
                                        Utils$OrcaPaymentPage.handlePostMessage(undefined, [
                                              [
                                                "copy",
                                                true
                                              ],
                                              [
                                                "copyDetails",
                                                text
                                              ]
                                            ]);
                                        setIsCopied(function (param) {
                                              return true;
                                            });
                                      })
                                  }, match$7[0])), React.createElement("div", {
                                className: "Details pt-5"
                              }, keys.map(function (item) {
                                    return React.createElement("div", {
                                                className: "flex px-5 pb-5 justify-between text-sm"
                                              }, React.createElement("div", undefined, React.createElement("span", {
                                                        className: "text-[#151A1F] font-medium opacity-60"
                                                      }, Utils$OrcaPaymentPage.snakeToTitleCase(item) + " : "), React.createElement("span", {
                                                        className: "text-[#151A1F] font-semibold opacity-80"
                                                      }, getKeyValue(json, item))));
                                  })))), React.createElement("div", {
                        className: " flex flex-col max-w-md justify-between items-center"
                      }, React.createElement("div", {
                            className: "Disclaimer w-full mt-16 font-medium text-xs text-[#151A1F] opacity-50"
                          }, " Please make a note of these details, before closing this popup. You will not be able to generate this details again. "), React.createElement("div", {
                            className: "button w-full"
                          }, React.createElement("div", undefined, React.createElement("button", {
                                    className: "w-full mt-6 p-2 h-[40px]",
                                    style: {
                                      background: "#006DF9",
                                      color: "#ffffff",
                                      borderRadius: "4px"
                                    },
                                    onClick: (function (param) {
                                        Utils$OrcaPaymentPage.postSubmitResponse(postData, return_url);
                                        Modal$OrcaPaymentPage.close(setOpenModal);
                                      })
                                  }, "Done"))))),
              showClose: false,
              setOpenModal: setOpenModal,
              openModal: match$6[0]
            });
}

var make = BankTransfersPopup;

export {
  getKeyValue ,
  make ,
}
/* react Not a pure module */
