// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

function localeStrings_achBankDebitTerms(str) {
  return "Podając numer konta i potwierdzając tę płatność, upoważniasz " + str + " i Hyperswitch, naszego dostawcę usług płatniczych, do przekazania Twojemu bankowi dyspozycji obciążenia Twojego konta, a Twój bank – do obciążenia Twojego konta zgodnie z tymi instrukcjami. Przysługuje Ci prawo do zwrotu pieniędzy od banku zgodnie z warunkami umowy zawartej z bankiem. Wniosek o zwrot pieniędzy należy złożyć w ciągu 8 dni od dnia obciążenia konta.";
}

function localeStrings_sepaDebitTerms(str) {
  return "Podając dane do płatności i potwierdzając tę płatność, upoważniasz (A) " + str + " i Hyperswitch, naszego dostawcę usług płatniczych, oraz PPRO, lokalnego dostawcy usług tej firmy do przekazania Twojemu bankowi dyspozycji obciążenia Twojego konta, a także (B) Twój bank – do obciążenia Twojego konta zgodnie z tymi instrukcjami. Twoje prawa obejmują prawo do zwrotu pieniędzy przez Twój bank zgodnie z warunkami umowy zawartej z bankiem. Wniosek o zwrot pieniędzy należy złożyć w ciągu 8 dni od dnia obciążenia konta. Twoje prawa są wyjaśnione w wyciągu, który możesz otrzymać od swojego banku. Wyrażasz zgodę na otrzymywanie powiadomień o przyszłych obciążeniach do 2 dni przed ich realizacją.";
}

function localeStrings_cardTerms(str) {
  return "Podając dane swojej karty, zezwalasz " + str + " na obciążenie jej przyszłymi płatnościami zgodnie z jej warunkami.";
}

function localeStrings_surchargeMsgAmount(currency, str) {
  return React.createElement(React.Fragment, {}, "Dopłata w wysokości" + Utils$OrcaPaymentPage.nbsp, React.createElement("strong", undefined, currency + " " + str), Utils$OrcaPaymentPage.nbsp + "zostaną zastosowane do tej transakcji");
}

function localeStrings_surchargeMsgAmountForCard(currency, str) {
  return React.createElement(React.Fragment, {}, "Dopłata w wysokości do" + Utils$OrcaPaymentPage.nbsp, React.createElement("strong", undefined, currency + " " + str), Utils$OrcaPaymentPage.nbsp + "zostaną zastosowane do tej transakcji");
}

function localeStrings_nameEmptyText(str) {
  return "Proszę podać swoje " + str;
}

function localeStrings_completeNameEmptyText(str) {
  return "Proszę podać kompletne " + str;
}

var localeStrings = {
  locale: "pl",
  cardNumberLabel: "Numer karty",
  localeDirection: "ltr",
  inValidCardErrorText: "Numer karty jest nieprawidłowy.",
  inCompleteCVCErrorText: "Kod zabezpieczający karty jest niekompletny.",
  inCompleteExpiryErrorText: "Data ważności karty jest niepełna.",
  pastExpiryErrorText: "Rok w dacie ważności karty minął.",
  poweredBy: "Na oprogramowaniu Hyperswitch",
  validThruText: "Dada ważności",
  sortCodeText: "Kod sortowania",
  cvcTextLabel: "CVC",
  emailLabel: "E-mail",
  emailEmptyText: "Adres e-mail nie może być pusty",
  emailInvalidText: "Niepoprawny adres email",
  accountNumberText: "Numer rachunku",
  fullNameLabel: "Imię i nazwisko",
  line1Label: "Adres wiersz 1",
  line1Placeholder: "Adres (ulica, nr budynku)",
  line1EmptyText: "Linia adresu 1 nie może być pusta",
  line2Label: "Adres wiersz 2",
  line2Placeholder: "Nr lokalu itp. (opcjonalnie)",
  line2EmptyText: "Linia adresu 2 nie może być pusta",
  cityLabel: "Miejscowość",
  cityEmptyText: "Miasto nie może być puste",
  postalCodeLabel: "Kod pocztowy",
  postalCodeEmptyText: "Kod pocztowy nie może być pusty",
  postalCodeInvalidText: "Nieprawidłowy kod pocztowy",
  stateLabel: "Stan",
  stateEmptyText: "Stan nie może być pusty",
  fullNamePlaceholder: "Imię i nazwisko",
  countryLabel: "Państwo",
  currencyLabel: "Waluta",
  bankLabel: "Wybierz bank",
  redirectText: "Po złożeniu zamówienia nastąpi przekierowanie na stronę, na której możesz bezpiecznie sfinalizować zakup.",
  bankDetailsText: "Po przesłaniu tych informacji otrzymasz dane konta bankowego do płatności. Pamiętaj, aby je zanotować.",
  orPayUsing: "Lub zapłać za pomocą",
  addNewCard: "Dodaj kartę kredytową lub debetową",
  useExisitingSavedCards: "Użyj zapisanych kart debetowych lub kredytowych",
  saveCardDetails: "Zapisz dane karty",
  addBankAccount: "Dodaj konto bankowe",
  achBankDebitTerms: localeStrings_achBankDebitTerms,
  sepaDebitTerms: localeStrings_sepaDebitTerms,
  becsDebitTerms: "Podając dane konta bankowego i potwierdzając tę płatność, zgadzasz się na niniejsze polecenie zapłaty i umowę o świadczenie usług polecenia zapłaty oraz upoważniasz Hyperswitch Payments Australia Pty Ltd ACN 160 180 343 numer identyfikacyjny użytkownika polecenia zapłaty 507156 („Hyperswitch”) do obciążenia Twojego konta za pośrednictwem Zbiorczego Elektronicznego Systemu Rozliczeniowego (BECS) w imieniu Hyperswitch Payment Widget („Sprzedawca”) wszelkimi kwotami, o których Sprzedawca poinformował Cię osobno. Oświadczasz, że jesteś posiadaczem konta lub osobą upoważnioną do składania podpisów dla konta wymienionego powyżej.",
  cardTerms: localeStrings_cardTerms,
  payNowButton: "Zapłać teraz",
  cardNumberEmptyText: "Numer karty nie może być pusty",
  cardExpiryDateEmptyText: "Data ważności karty nie może być pusta",
  cvcNumberEmptyText: "Numer CVC nie może być pusty",
  enterFieldsText: "Uzupełnij wszystkie pola",
  enterValidDetailsText: "Wprowadź prawidłowe dane",
  selectPaymentMethodText: "Wybierz metodę płatności i spróbuj ponownie",
  card: "Karta",
  surchargeMsgAmount: localeStrings_surchargeMsgAmount,
  surchargeMsgAmountForCard: localeStrings_surchargeMsgAmountForCard,
  surchargeMsgAmountForOneClickWallets: "Obowiązuje dodatkowa opłata",
  billingNameLabel: "Nazwisko do faktury",
  billingNamePlaceholder: "Imię i nazwisko",
  cardHolderName: "Imię i nazwisko posiadacza karty",
  on: "NA",
  and: "I",
  nameEmptyText: localeStrings_nameEmptyText,
  completeNameEmptyText: localeStrings_completeNameEmptyText,
  billingDetailsText: "Szczegóły płatności",
  socialSecurityNumberLabel: "Numer ubezpieczenia społecznego",
  saveWalletDetails: "Po dokonaniu wyboru szczegóły portfela zostaną zapisane",
  morePaymentMethods: "Więcej metod płatności",
  useExistingPaymentMethods: "Skorzystaj z zapisanych metod płatności",
  cardNickname: "Przezwisko karty",
  nicknamePlaceholder: "Pseudonim karty (opcjonalnie)"
};

export {
  localeStrings ,
}
/* react Not a pure module */
