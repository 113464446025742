// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Recoil from "recoil";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as Utils$OrcaPaymentPage from "./Utils.bs.js";
import * as Window$OrcaPaymentPage from "../Window.bs.js";
import * as ApiEndpoint$OrcaPaymentPage from "./ApiEndpoint.bs.js";
import * as BrowserSpec$OrcaPaymentPage from "../BrowserSpec.bs.js";
import * as LoggerUtils$OrcaPaymentPage from "./LoggerUtils.bs.js";
import * as PaymentBody$OrcaPaymentPage from "./PaymentBody.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "./RecoilAtoms.bs.js";
import * as PaymentError$OrcaPaymentPage from "../Types/PaymentError.bs.js";
import * as PaymentConfirmTypes$OrcaPaymentPage from "../Types/PaymentConfirmTypes.bs.js";
import * as PaymentMethodsRecord$OrcaPaymentPage from "../Payments/PaymentMethodsRecord.bs.js";

function closePaymentLoaderIfAny() {
  Utils$OrcaPaymentPage.handlePostMessage(undefined, [[
          "fullscreen",
          false
        ]]);
}

function retrievePaymentIntent(clientSecret, headers, optLogger, switchToCustomPod, isForceSyncOpt) {
  var isForceSync = isForceSyncOpt !== undefined ? isForceSyncOpt : false;
  var paymentIntentID = Core__Option.getOr(clientSecret.split("_secret_")[0], "");
  var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(undefined, undefined, undefined);
  var forceSync = isForceSync ? "&force_sync=true" : "";
  var uri = endpoint + "/payments/" + paymentIntentID + "?client_secret=" + clientSecret + forceSync;
  LoggerUtils$OrcaPaymentPage.logApi("RETRIEVE_CALL_INIT", undefined, undefined, "Request", uri, undefined, undefined, optLogger, "INFO", "API", undefined, undefined);
  return Core__Promise.$$catch(Utils$OrcaPaymentPage.fetchApi(uri, undefined, Caml_option.some(ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers, switchToCustomPod, undefined)), "GET", undefined).then(function (res) {
                  var statusCode = res.status.toString();
                  if (statusCode.charAt(0) !== "2") {
                    return res.json().then(function (data) {
                                LoggerUtils$OrcaPaymentPage.logApi("RETRIEVE_CALL", statusCode, data, "Err", uri, undefined, undefined, optLogger, "ERROR", "API", undefined, undefined);
                                return Promise.resolve(null);
                              });
                  } else {
                    LoggerUtils$OrcaPaymentPage.logApi("RETRIEVE_CALL", statusCode, undefined, "Response", uri, undefined, undefined, optLogger, "INFO", "API", undefined, undefined);
                    return res.json();
                  }
                }), (function (e) {
                console.log("Unable to retrieve payment details because of ", e);
                return Promise.resolve(null);
              }));
}

function threeDsMethod(url, threeDsMethodData, optLogger) {
  LoggerUtils$OrcaPaymentPage.logApi("THREE_DS_METHOD_CALL_INIT", undefined, undefined, "Request", url, undefined, undefined, optLogger, "INFO", "API", undefined, undefined);
  var threeDsMethodStr = Core__Option.getOr(Core__JSON.Decode.string(threeDsMethodData), "");
  var body = encodeURIComponent("threeDSMethodData") + "=" + encodeURIComponent(threeDsMethodStr);
  return Core__Promise.$$catch(Utils$OrcaPaymentPage.fetchApiWithNoCors(url, body, undefined, "POST", undefined).then(function (res) {
                  var statusCode = res.status.toString();
                  if (statusCode.charAt(0) !== "2") {
                    return res.text().then(function (text) {
                                LoggerUtils$OrcaPaymentPage.logApi("THREE_DS_METHOD_CALL", statusCode, text, "Err", url, undefined, undefined, optLogger, "ERROR", "API", undefined, undefined);
                                return Promise.resolve("");
                              });
                  } else {
                    LoggerUtils$OrcaPaymentPage.logApi("THREE_DS_METHOD_CALL", statusCode, undefined, "Response", url, undefined, undefined, optLogger, undefined, undefined, undefined, undefined);
                    return res.text();
                  }
                }), (function (err) {
                var exceptionMessage = Utils$OrcaPaymentPage.formatException(err);
                console.log("Unable to call 3ds method ", exceptionMessage);
                LoggerUtils$OrcaPaymentPage.logApi("THREE_DS_METHOD_CALL", undefined, exceptionMessage, "NoResponse", url, undefined, undefined, optLogger, "ERROR", "API", undefined, undefined);
                return Promise.reject(err);
              }));
}

function threeDsAuth(clientSecret, optLogger, threeDsMethodComp, headers) {
  var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(undefined, undefined, undefined);
  var paymentIntentID = Core__Option.getOr(clientSecret.split("_secret_")[0], "");
  var url = endpoint + "/payments/" + paymentIntentID + "/3ds/authentication";
  var body = Object.fromEntries([
          [
            "client_secret",
            clientSecret
          ],
          [
            "device_channel",
            "BRW"
          ],
          [
            "threeds_method_comp_ind",
            threeDsMethodComp
          ]
        ].concat(BrowserSpec$OrcaPaymentPage.broswerInfo()));
  LoggerUtils$OrcaPaymentPage.logApi("AUTHENTICATION_CALL_INIT", undefined, undefined, "Request", url, undefined, undefined, optLogger, "INFO", "API", undefined, undefined);
  return Core__Promise.$$catch(Utils$OrcaPaymentPage.fetchApi(url, JSON.stringify(body), Caml_option.some(Object.fromEntries(headers)), "POST", undefined).then(function (res) {
                  var statusCode = res.status.toString();
                  if (statusCode.charAt(0) !== "2") {
                    return res.json().then(function (data) {
                                LoggerUtils$OrcaPaymentPage.logApi("AUTHENTICATION_CALL", statusCode, data, "Err", url, undefined, undefined, optLogger, "ERROR", "API", undefined, undefined);
                                return Promise.resolve(null);
                              });
                  } else {
                    LoggerUtils$OrcaPaymentPage.logApi("AUTHENTICATION_CALL", statusCode, undefined, "Response", url, undefined, undefined, optLogger, undefined, undefined, undefined, undefined);
                    return res.json();
                  }
                }), (function (err) {
                var exceptionMessage = Utils$OrcaPaymentPage.formatException(err);
                console.log("Unable to call 3ds auth ", exceptionMessage);
                LoggerUtils$OrcaPaymentPage.logApi("AUTHENTICATION_CALL", undefined, exceptionMessage, "NoResponse", url, undefined, undefined, optLogger, "ERROR", "API", undefined, undefined);
                return Promise.reject(err);
              }));
}

function pollRetrievePaymentIntent(clientSecret, headers, optLogger, switchToCustomPod, isForceSyncOpt) {
  var isForceSync = isForceSyncOpt !== undefined ? isForceSyncOpt : false;
  return Core__Promise.$$catch(retrievePaymentIntent(clientSecret, headers, optLogger, switchToCustomPod, isForceSync).then(function (json) {
                  var dict = Core__Option.getOr(Core__JSON.Decode.object(json), {});
                  var status = Utils$OrcaPaymentPage.getString(dict, "status", "");
                  if (status === "succeeded" || status === "failed") {
                    return Promise.resolve(json);
                  } else {
                    return Utils$OrcaPaymentPage.delay(2000).then(function (_val) {
                                return pollRetrievePaymentIntent(clientSecret, headers, optLogger, switchToCustomPod, isForceSync);
                              });
                  }
                }), (function (e) {
                console.log("Unable to retrieve payment due to following error", e);
                return pollRetrievePaymentIntent(clientSecret, headers, optLogger, switchToCustomPod, isForceSync);
              }));
}

function intentCall(fetchApi, uri, headers, bodyStr, confirmParam, clientSecret, optLogger, handleUserError, paymentType, iframeId, fetchMethod, setIsManualRetryEnabled, switchToCustomPod, sdkHandleOneClickConfirmPayment, counter, isPaymentSessionOpt, paymentSessionRedirectOpt, param) {
  var isPaymentSession = isPaymentSessionOpt !== undefined ? isPaymentSessionOpt : false;
  var paymentSessionRedirect = paymentSessionRedirectOpt !== undefined ? paymentSessionRedirectOpt : "if_redirect";
  var isConfirm = uri.includes("/confirm");
  var match = isConfirm ? [
      "CONFIRM_CALL",
      "CONFIRM_CALL_INIT"
    ] : [
      "RETRIEVE_CALL",
      "RETRIEVE_CALL_INIT"
    ];
  var eventName = match[0];
  LoggerUtils$OrcaPaymentPage.logApi(match[1], undefined, undefined, "Request", uri, undefined, undefined, optLogger, "INFO", "API", isPaymentSession, undefined);
  var handleOpenUrl = function (url) {
    if (isPaymentSession) {
      window.location.replace(url);
      return ;
    } else {
      return Utils$OrcaPaymentPage.openUrl(url);
    }
  };
  return Core__Promise.$$catch(fetchApi(uri, bodyStr, Caml_option.some(ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers, switchToCustomPod, undefined)), fetchMethod, undefined).then(function (res) {
                  var statusCode = res.status.toString();
                  var url = new URL(confirmParam.return_url);
                  url.searchParams.set("payment_intent_client_secret", clientSecret);
                  url.searchParams.set("status", "failed");
                  if (statusCode.charAt(0) !== "2") {
                    return Core__Promise.$$catch(res.json().then(function (data) {
                                    return new Promise((function (resolve, param) {
                                                    if (isConfirm) {
                                                      var paymentMethod;
                                                      paymentMethod = paymentType === "Card" ? "CARD" : Utils$OrcaPaymentPage.getString(Utils$OrcaPaymentPage.getDictFromJson(JSON.parse(bodyStr)), "payment_method_type", "");
                                                      LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, JSON.stringify(data), undefined, "PAYMENT_FAILED", paymentMethod, undefined, undefined);
                                                    }
                                                    LoggerUtils$OrcaPaymentPage.logApi(eventName, statusCode, data, "Err", uri, undefined, undefined, optLogger, "ERROR", "API", isPaymentSession, undefined);
                                                    var dict = Utils$OrcaPaymentPage.getDictFromJson(data);
                                                    var errorObj = PaymentError$OrcaPaymentPage.itemToObjMapper(dict);
                                                    if (!isPaymentSession) {
                                                      closePaymentLoaderIfAny();
                                                      Utils$OrcaPaymentPage.postFailedSubmitResponse(errorObj.error.type_, errorObj.error.message);
                                                    }
                                                    if (handleUserError) {
                                                      return handleOpenUrl(url.href);
                                                    }
                                                    var failedSubmitResponse = Utils$OrcaPaymentPage.getFailedSubmitResponse(errorObj.error.type_, errorObj.error.message);
                                                    resolve(failedSubmitResponse);
                                                  })).then(function (prim) {
                                                return Promise.resolve(prim);
                                              });
                                  }), (function (err) {
                                  return new Promise((function (resolve, param) {
                                                  var exceptionMessage = Utils$OrcaPaymentPage.formatException(err);
                                                  LoggerUtils$OrcaPaymentPage.logApi(eventName, statusCode, exceptionMessage, "NoResponse", uri, undefined, undefined, optLogger, "ERROR", "API", isPaymentSession, undefined);
                                                  if (counter >= 5) {
                                                    if (!isPaymentSession) {
                                                      closePaymentLoaderIfAny();
                                                      Utils$OrcaPaymentPage.postFailedSubmitResponse("server_error", "Something went wrong");
                                                    }
                                                    if (handleUserError) {
                                                      return handleOpenUrl(url.href);
                                                    }
                                                    var failedSubmitResponse = Utils$OrcaPaymentPage.getFailedSubmitResponse("server_error", "Something went wrong");
                                                    return resolve(failedSubmitResponse);
                                                  }
                                                  var paymentIntentID = Core__Option.getOr(clientSecret.split("_secret_")[0], "");
                                                  var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(confirmParam.publishableKey, undefined, undefined);
                                                  var retrieveUri = endpoint + "/payments/" + paymentIntentID + "?client_secret=" + clientSecret;
                                                  intentCall(fetchApi, retrieveUri, headers, bodyStr, confirmParam, clientSecret, optLogger, handleUserError, paymentType, iframeId, "GET", setIsManualRetryEnabled, switchToCustomPod, sdkHandleOneClickConfirmPayment, counter + 1 | 0, undefined, undefined, undefined).then(function (res) {
                                                        resolve(res);
                                                        return Promise.resolve();
                                                      });
                                                })).then(function (prim) {
                                              return Promise.resolve(prim);
                                            });
                                }));
                  } else {
                    return res.json().then(function (data) {
                                return new Promise((function (resolve, param) {
                                                LoggerUtils$OrcaPaymentPage.logApi(eventName, statusCode, undefined, "Response", uri, undefined, undefined, optLogger, undefined, undefined, isPaymentSession, undefined);
                                                var intent = PaymentConfirmTypes$OrcaPaymentPage.itemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(data));
                                                var paymentMethod;
                                                paymentMethod = paymentType === "Card" ? "CARD" : intent.payment_method_type;
                                                var url = new URL(confirmParam.return_url);
                                                url.searchParams.set("payment_intent_client_secret", clientSecret);
                                                url.searchParams.set("status", intent.status);
                                                var handleProcessingStatus = function (paymentType, sdkHandleOneClickConfirmPayment) {
                                                  var exit = 0;
                                                  switch (paymentType) {
                                                    case "Card" :
                                                        break;
                                                    case "Gpay" :
                                                    case "Applepay" :
                                                    case "Paypal" :
                                                        exit = 2;
                                                        break;
                                                    default:
                                                      return handleOpenUrl(url.href);
                                                  }
                                                  if (exit === 2 && sdkHandleOneClickConfirmPayment) {
                                                    return handleOpenUrl(url.href);
                                                  }
                                                  if (isPaymentSession) {
                                                    if (paymentSessionRedirect === "always") {
                                                      return handleOpenUrl(url.href);
                                                    } else {
                                                      return resolve(data);
                                                    }
                                                  } else {
                                                    return Utils$OrcaPaymentPage.postSubmitResponse(data, url.href);
                                                  }
                                                };
                                                if (intent.status === "requires_customer_action") {
                                                  if (intent.nextAction.type_ === "redirect_to_url") {
                                                    LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, "", intent.nextAction.redirectToUrl, "REDIRECTING_USER", paymentMethod, undefined, undefined);
                                                    return handleOpenUrl(intent.nextAction.redirectToUrl);
                                                  }
                                                  if (intent.nextAction.type_ === "display_bank_transfer_information") {
                                                    var obj = intent.nextAction.bank_transfer_steps_and_charges_details;
                                                    var metadata = obj !== undefined ? Utils$OrcaPaymentPage.getDictFromJson(obj) : ({});
                                                    var dict = Utils$OrcaPaymentPage.deepCopyDict(metadata);
                                                    dict["data"] = data;
                                                    dict["url"] = url.href;
                                                    LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, "", JSON.stringify(dict), "DISPLAY_BANK_TRANSFER_INFO_PAGE", paymentMethod, undefined, undefined);
                                                    if (!isPaymentSession) {
                                                      Utils$OrcaPaymentPage.handlePostMessage(undefined, [
                                                            [
                                                              "fullscreen",
                                                              true
                                                            ],
                                                            [
                                                              "param",
                                                              intent.payment_method_type + "BankTransfer"
                                                            ],
                                                            [
                                                              "iframeId",
                                                              iframeId
                                                            ],
                                                            [
                                                              "metadata",
                                                              dict
                                                            ]
                                                          ]);
                                                    }
                                                    return resolve(data);
                                                  }
                                                  if (intent.nextAction.type_ === "qr_code_information") {
                                                    var qrData = Core__Option.getOr(intent.nextAction.image_data_url, "");
                                                    var expiryTime = Core__Option.getOr(intent.nextAction.display_to_timestamp, 0.0);
                                                    var headerObj = {};
                                                    headers.forEach(function (entries) {
                                                          headerObj[entries[0]] = entries[1];
                                                        });
                                                    var metaData = Object.fromEntries([
                                                          [
                                                            "qrData",
                                                            qrData
                                                          ],
                                                          [
                                                            "paymentIntentId",
                                                            clientSecret
                                                          ],
                                                          [
                                                            "publishableKey",
                                                            confirmParam.publishableKey
                                                          ],
                                                          [
                                                            "headers",
                                                            headerObj
                                                          ],
                                                          [
                                                            "expiryTime",
                                                            String(expiryTime)
                                                          ],
                                                          [
                                                            "url",
                                                            url.href
                                                          ]
                                                        ]);
                                                    LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, "", JSON.stringify(metaData), "DISPLAY_QR_CODE_INFO_PAGE", paymentMethod, undefined, undefined);
                                                    if (!isPaymentSession) {
                                                      Utils$OrcaPaymentPage.handlePostMessage(undefined, [
                                                            [
                                                              "fullscreen",
                                                              true
                                                            ],
                                                            [
                                                              "param",
                                                              "qrData"
                                                            ],
                                                            [
                                                              "iframeId",
                                                              iframeId
                                                            ],
                                                            [
                                                              "metadata",
                                                              metaData
                                                            ]
                                                          ]);
                                                    }
                                                    return resolve(data);
                                                  }
                                                  if (intent.nextAction.type_ === "three_ds_invoke") {
                                                    var threeDsData = Core__Option.getOr(Belt_Option.flatMap(intent.nextAction.three_ds_data, Core__JSON.Decode.object), {});
                                                    var do3dsMethodCall = Utils$OrcaPaymentPage.getBoolValue(Core__JSON.Decode.bool(Core__Option.getOr(Belt_Option.flatMap(Belt_Option.flatMap(threeDsData["three_ds_method_details"], Core__JSON.Decode.object), (function (x) {
                                                                        return x["three_ds_method_data_submission"];
                                                                      })), {})));
                                                    var headerObj$1 = {};
                                                    headers.forEach(function (entries) {
                                                          headerObj$1[entries[0]] = entries[1];
                                                        });
                                                    var metaData$1 = Object.fromEntries([
                                                          [
                                                            "threeDSData",
                                                            threeDsData
                                                          ],
                                                          [
                                                            "paymentIntentId",
                                                            clientSecret
                                                          ],
                                                          [
                                                            "publishableKey",
                                                            confirmParam.publishableKey
                                                          ],
                                                          [
                                                            "headers",
                                                            headerObj$1
                                                          ],
                                                          [
                                                            "url",
                                                            url.href
                                                          ],
                                                          [
                                                            "iframeId",
                                                            iframeId
                                                          ]
                                                        ]);
                                                    LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, do3dsMethodCall ? "Y" : "N", undefined, "THREE_DS_METHOD", paymentMethod, undefined, undefined);
                                                    if (do3dsMethodCall) {
                                                      return Utils$OrcaPaymentPage.handlePostMessage(undefined, [
                                                                  [
                                                                    "fullscreen",
                                                                    true
                                                                  ],
                                                                  [
                                                                    "param",
                                                                    "3ds"
                                                                  ],
                                                                  [
                                                                    "iframeId",
                                                                    iframeId
                                                                  ],
                                                                  [
                                                                    "metadata",
                                                                    metaData$1
                                                                  ]
                                                                ]);
                                                    } else {
                                                      metaData$1["3dsMethodComp"] = "U";
                                                      return Utils$OrcaPaymentPage.handlePostMessage(undefined, [
                                                                  [
                                                                    "fullscreen",
                                                                    true
                                                                  ],
                                                                  [
                                                                    "param",
                                                                    "3dsAuth"
                                                                  ],
                                                                  [
                                                                    "iframeId",
                                                                    iframeId
                                                                  ],
                                                                  [
                                                                    "metadata",
                                                                    metaData$1
                                                                  ]
                                                                ]);
                                                    }
                                                  }
                                                  if (intent.nextAction.type_ === "third_party_sdk_session_token") {
                                                    var token = intent.nextAction.session_token;
                                                    var session_token = token !== undefined ? Utils$OrcaPaymentPage.getDictFromJson(token) : ({});
                                                    var walletName = Utils$OrcaPaymentPage.getString(session_token, "wallet_name", "");
                                                    var message;
                                                    switch (walletName) {
                                                      case "apple_pay" :
                                                          message = [
                                                            [
                                                              "applePayButtonClicked",
                                                              true
                                                            ],
                                                            [
                                                              "applePayPresent",
                                                              session_token
                                                            ]
                                                          ];
                                                          break;
                                                      case "google_pay" :
                                                          message = [[
                                                              "googlePayThirdPartyFlow",
                                                              session_token
                                                            ]];
                                                          break;
                                                      default:
                                                        message = [];
                                                    }
                                                    if (!isPaymentSession) {
                                                      Utils$OrcaPaymentPage.handlePostMessage(undefined, message);
                                                    }
                                                    return resolve(data);
                                                  }
                                                  if (!isPaymentSession) {
                                                    Utils$OrcaPaymentPage.postFailedSubmitResponse("confirm_payment_failed", "Payment failed. Try again!");
                                                  }
                                                  if (uri.includes("force_sync=true")) {
                                                    LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, intent.nextAction.type_, intent.nextAction.type_, "REDIRECTING_USER", paymentMethod, "ERROR", undefined);
                                                    return handleOpenUrl(url.href);
                                                  }
                                                  var failedSubmitResponse = Utils$OrcaPaymentPage.getFailedSubmitResponse("confirm_payment_failed", "Payment failed. Try again!");
                                                  return resolve(failedSubmitResponse);
                                                }
                                                if (intent.status === "processing") {
                                                  if (intent.nextAction.type_ === "third_party_sdk_session_token") {
                                                    var token$1 = intent.nextAction.session_token;
                                                    var session_token$1 = token$1 !== undefined ? Utils$OrcaPaymentPage.getDictFromJson(token$1) : ({});
                                                    var walletName$1 = Utils$OrcaPaymentPage.getString(session_token$1, "wallet_name", "");
                                                    var message$1;
                                                    switch (walletName$1) {
                                                      case "apple_pay" :
                                                          message$1 = [
                                                            [
                                                              "applePayButtonClicked",
                                                              true
                                                            ],
                                                            [
                                                              "applePayPresent",
                                                              session_token$1
                                                            ]
                                                          ];
                                                          break;
                                                      case "google_pay" :
                                                          message$1 = [[
                                                              "googlePayThirdPartyFlow",
                                                              session_token$1
                                                            ]];
                                                          break;
                                                      default:
                                                        message$1 = [];
                                                    }
                                                    if (!isPaymentSession) {
                                                      Utils$OrcaPaymentPage.handlePostMessage(undefined, message$1);
                                                    }
                                                    
                                                  } else {
                                                    handleProcessingStatus(paymentType, sdkHandleOneClickConfirmPayment);
                                                  }
                                                  return resolve(data);
                                                }
                                                if (intent.status !== "") {
                                                  if (intent.status === "succeeded") {
                                                    LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, intent.status, undefined, "PAYMENT_SUCCESS", paymentMethod, undefined, undefined);
                                                  } else if (intent.status === "failed") {
                                                    LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, intent.status, undefined, "PAYMENT_FAILED", paymentMethod, undefined, undefined);
                                                  }
                                                  if (intent.status === "failed") {
                                                    setIsManualRetryEnabled(function (param) {
                                                          return intent.manualRetryAllowed;
                                                        });
                                                  }
                                                  return handleProcessingStatus(paymentType, sdkHandleOneClickConfirmPayment);
                                                }
                                                if (!isPaymentSession) {
                                                  return Utils$OrcaPaymentPage.postFailedSubmitResponse("confirm_payment_failed", "Payment failed. Try again!");
                                                }
                                                var failedSubmitResponse$1 = Utils$OrcaPaymentPage.getFailedSubmitResponse("confirm_payment_failed", "Payment failed. Try again!");
                                                resolve(failedSubmitResponse$1);
                                              })).then(function (prim) {
                                            return Promise.resolve(prim);
                                          });
                              });
                  }
                }), (function (err) {
                return new Promise((function (resolve, param) {
                                var url = new URL(confirmParam.return_url);
                                url.searchParams.set("payment_intent_client_secret", clientSecret);
                                url.searchParams.set("status", "failed");
                                var exceptionMessage = Utils$OrcaPaymentPage.formatException(err);
                                LoggerUtils$OrcaPaymentPage.logApi(eventName, undefined, exceptionMessage, "NoResponse", uri, undefined, undefined, optLogger, "ERROR", "API", isPaymentSession, undefined);
                                if (counter >= 5) {
                                  if (!isPaymentSession) {
                                    closePaymentLoaderIfAny();
                                    Utils$OrcaPaymentPage.postFailedSubmitResponse("server_error", "Something went wrong");
                                  }
                                  if (handleUserError) {
                                    return handleOpenUrl(url.href);
                                  }
                                  var failedSubmitResponse = Utils$OrcaPaymentPage.getFailedSubmitResponse("server_error", "Something went wrong");
                                  return resolve(failedSubmitResponse);
                                }
                                var paymentIntentID = Core__Option.getOr(clientSecret.split("_secret_")[0], "");
                                var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(confirmParam.publishableKey, undefined, undefined);
                                var retrieveUri = endpoint + "/payments/" + paymentIntentID + "?client_secret=" + clientSecret;
                                intentCall(fetchApi, retrieveUri, headers, bodyStr, confirmParam, clientSecret, optLogger, handleUserError, paymentType, iframeId, "GET", setIsManualRetryEnabled, switchToCustomPod, sdkHandleOneClickConfirmPayment, counter + 1 | 0, isPaymentSession, undefined, undefined).then(function (res) {
                                      resolve(res);
                                      return Promise.resolve();
                                    });
                              })).then(function (prim) {
                            return Promise.resolve(prim);
                          });
              }));
}

function usePaymentSync(optLogger, paymentType) {
  var list = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.list);
  var keys = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.keys);
  var switchToCustomPod = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.switchToCustomPod);
  var setIsManualRetryEnabled = Recoil.useSetRecoilState(RecoilAtoms$OrcaPaymentPage.isManualRetryEnabled);
  return function (handleUserErrorOpt, confirmParam, iframeIdOpt, param) {
    var handleUserError = handleUserErrorOpt !== undefined ? handleUserErrorOpt : false;
    var iframeId = iframeIdOpt !== undefined ? iframeIdOpt : "";
    var clientSecret = keys.clientSecret;
    if (clientSecret === undefined) {
      return Utils$OrcaPaymentPage.postFailedSubmitResponse("sync_payment_failed", "Sync Payment Failed. Try Again!");
    }
    var paymentIntentID = Core__Option.getOr(clientSecret.split("_secret_")[0], "");
    var headers = [
      [
        "Content-Type",
        "application/json"
      ],
      [
        "api-key",
        confirmParam.publishableKey
      ]
    ];
    var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(confirmParam.publishableKey, undefined, undefined);
    var uri = endpoint + "/payments/" + paymentIntentID + "?force_sync=true&client_secret=" + clientSecret;
    var paymentSync = function () {
      intentCall(Utils$OrcaPaymentPage.fetchApi, uri, headers, "", confirmParam, clientSecret, optLogger, handleUserError, paymentType, iframeId, "GET", setIsManualRetryEnabled, switchToCustomPod, keys.sdkHandleOneClickConfirmPayment, 0, undefined, undefined, undefined);
    };
    if (typeof list !== "object" || list.TAG !== "Loaded") {
      return ;
    } else {
      return paymentSync();
    }
  };
}

function maskStr(str) {
  return str.replace(/\S/g, "x");
}

function maskPayload(payloadJson) {
  var valueDict = Core__JSON.Classify.classify(payloadJson);
  if (typeof valueDict !== "object") {
    return "null";
  }
  switch (valueDict.TAG) {
    case "Bool" :
        if (valueDict._0) {
          return "true";
        } else {
          return "false";
        }
    case "String" :
        return maskStr(valueDict._0);
    case "Number" :
        return maskStr(valueDict._0.toString());
    case "Object" :
        return Object.fromEntries(Object.entries(valueDict._0).map(function (entry) {
                        return [
                                entry[0],
                                maskPayload(entry[1])
                              ];
                      }));
    case "Array" :
        return valueDict._0.map(maskPayload);
    
  }
}

function usePaymentIntent(optLogger, paymentType) {
  var blockConfirm = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.isConfirmBlocked);
  var switchToCustomPod = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.switchToCustomPod);
  var list = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.list);
  var keys = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.keys);
  var match = Recoil.useRecoilState(RecoilAtoms$OrcaPaymentPage.isManualRetryEnabled);
  var setIsManualRetryEnabled = match[1];
  var isManualRetryEnabled = match[0];
  return function (handleUserErrorOpt, bodyArr, confirmParam, iframeIdOpt, param) {
    var handleUserError = handleUserErrorOpt !== undefined ? handleUserErrorOpt : false;
    var iframeId = iframeIdOpt !== undefined ? iframeIdOpt : keys.iframeId;
    var clientSecret = keys.clientSecret;
    if (clientSecret === undefined) {
      return Utils$OrcaPaymentPage.postFailedSubmitResponse("confirm_payment_failed", "Payment failed. Try again!");
    }
    var paymentIntentID = Core__Option.getOr(clientSecret.split("_secret_")[0], "");
    var headers = [
      [
        "Content-Type",
        "application/json"
      ],
      [
        "api-key",
        confirmParam.publishableKey
      ]
    ];
    var returnUrlArr = [[
        "return_url",
        confirmParam.return_url
      ]];
    var manual_retry = isManualRetryEnabled ? [[
          "retry_action",
          "manual_retry"
        ]] : [];
    var body = [[
          "client_secret",
          clientSecret
        ]].concat(returnUrlArr, manual_retry);
    var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(confirmParam.publishableKey, true, undefined);
    var uri = endpoint + "/payments/" + paymentIntentID + "/confirm";
    var callIntent = function (body) {
      var maskedPayload = JSON.stringify(maskPayload(Core__Option.getOr(Utils$OrcaPaymentPage.safeParseOpt(body), null)));
      var loggerPayload = JSON.stringify(Object.fromEntries([
                [
                  "payload",
                  maskedPayload
                ],
                [
                  "headers",
                  Object.fromEntries(headers.map(function (header) {
                            return [
                                    header[0],
                                    header[1]
                                  ];
                          }))
                ]
              ]));
      if (paymentType === "Card") {
        LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, "", loggerPayload, "PAYMENT_ATTEMPT", "CARD", undefined, undefined);
      } else {
        bodyArr.map(function (param) {
              if (param[0] === "payment_method_type") {
                LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, "", loggerPayload, "PAYMENT_ATTEMPT", Utils$OrcaPaymentPage.getStringFromJson(param[1], ""), undefined, undefined);
              }
              
            });
      }
      if (blockConfirm && Window$OrcaPaymentPage.isInteg) {
        console.log("CONFIRM IS BLOCKED", JSON.parse(body), headers);
      } else {
        intentCall(Utils$OrcaPaymentPage.fetchApi, uri, headers, body, confirmParam, clientSecret, optLogger, handleUserError, paymentType, iframeId, "POST", setIsManualRetryEnabled, switchToCustomPod, keys.sdkHandleOneClickConfirmPayment, 0, undefined, undefined, undefined);
      }
    };
    var intentWithoutMandate = function (mandatePaymentType) {
      var bodyStr = JSON.stringify(Object.fromEntries(body.concat(bodyArr.concat(BrowserSpec$OrcaPaymentPage.broswerInfo()), PaymentBody$OrcaPaymentPage.paymentTypeBody(mandatePaymentType))));
      callIntent(bodyStr);
    };
    if (typeof list !== "object") {
      if (list === "Loading") {
        return ;
      } else {
        return intentWithoutMandate("");
      }
    }
    if (list.TAG !== "Loaded") {
      return ;
    }
    var paymentList = PaymentMethodsRecord$OrcaPaymentPage.itemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(list._0));
    var mandatePaymentType = PaymentMethodsRecord$OrcaPaymentPage.paymentTypeToStringMapper(paymentList.payment_type);
    var match = paymentList.mandate_payment;
    if (match === undefined) {
      return intentWithoutMandate(mandatePaymentType);
    }
    switch (paymentType) {
      case "BankTransfer" :
      case "Other" :
          return intentWithoutMandate(mandatePaymentType);
      default:
        var bodyStr = JSON.stringify(Object.fromEntries(body.concat(bodyArr.concat(PaymentBody$OrcaPaymentPage.mandateBody(mandatePaymentType), BrowserSpec$OrcaPaymentPage.broswerInfo()))));
        return callIntent(bodyStr);
    }
  };
}

function fetchSessions(clientSecret, publishableKey, walletsOpt, isDelayedSessionTokenOpt, optLogger, switchToCustomPod, endpoint, param) {
  var wallets = walletsOpt !== undefined ? walletsOpt : [];
  var isDelayedSessionToken = isDelayedSessionTokenOpt !== undefined ? isDelayedSessionTokenOpt : false;
  var headers = [
    [
      "Content-Type",
      "application/json"
    ],
    [
      "api-key",
      publishableKey
    ]
  ];
  var paymentIntentID = Core__Option.getOr(clientSecret.split("_secret_")[0], "");
  var body = Object.fromEntries([
        [
          "payment_id",
          paymentIntentID
        ],
        [
          "client_secret",
          clientSecret
        ],
        [
          "wallets",
          wallets
        ],
        [
          "delayed_session_token",
          isDelayedSessionToken
        ]
      ]);
  var uri = endpoint + "/payments/session_tokens";
  LoggerUtils$OrcaPaymentPage.logApi("SESSIONS_CALL_INIT", undefined, undefined, "Request", uri, undefined, undefined, optLogger, "INFO", "API", undefined, undefined);
  return Core__Promise.$$catch(Utils$OrcaPaymentPage.fetchApi(uri, JSON.stringify(body), Caml_option.some(ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers, switchToCustomPod, undefined)), "POST", undefined).then(function (resp) {
                  var statusCode = resp.status.toString();
                  if (statusCode.charAt(0) !== "2") {
                    return resp.json().then(function (data) {
                                LoggerUtils$OrcaPaymentPage.logApi("SESSIONS_CALL", statusCode, data, "Err", uri, undefined, undefined, optLogger, "ERROR", "API", undefined, undefined);
                                return Promise.resolve(null);
                              });
                  } else {
                    LoggerUtils$OrcaPaymentPage.logApi("SESSIONS_CALL", statusCode, undefined, "Response", uri, undefined, undefined, optLogger, "INFO", "API", undefined, undefined);
                    return resp.json();
                  }
                }), (function (err) {
                var exceptionMessage = Utils$OrcaPaymentPage.formatException(err);
                LoggerUtils$OrcaPaymentPage.logApi("SESSIONS_CALL", undefined, exceptionMessage, "NoResponse", uri, undefined, undefined, optLogger, "ERROR", "API", undefined, undefined);
                return Promise.resolve(null);
              }));
}

function fetchPaymentMethodList(clientSecret, publishableKey, logger, switchToCustomPod, endpoint) {
  var headers = [
    [
      "Content-Type",
      "application/json"
    ],
    [
      "api-key",
      publishableKey
    ]
  ];
  var uri = endpoint + "/account/payment_methods?client_secret=" + clientSecret;
  LoggerUtils$OrcaPaymentPage.logApi("PAYMENT_METHODS_CALL_INIT", undefined, undefined, "Request", uri, undefined, undefined, logger, "INFO", "API", undefined, undefined);
  return Core__Promise.$$catch(Utils$OrcaPaymentPage.fetchApi(uri, undefined, Caml_option.some(ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers, switchToCustomPod, undefined)), "GET", undefined).then(function (resp) {
                  var statusCode = resp.status.toString();
                  if (statusCode.charAt(0) !== "2") {
                    return resp.json().then(function (data) {
                                LoggerUtils$OrcaPaymentPage.logApi("PAYMENT_METHODS_CALL", statusCode, data, "Err", uri, undefined, undefined, logger, "ERROR", "API", undefined, undefined);
                                return Promise.resolve(null);
                              });
                  } else {
                    LoggerUtils$OrcaPaymentPage.logApi("PAYMENT_METHODS_CALL", statusCode, undefined, "Response", uri, undefined, undefined, logger, "INFO", "API", undefined, undefined);
                    return resp.json();
                  }
                }), (function (err) {
                var exceptionMessage = Utils$OrcaPaymentPage.formatException(err);
                LoggerUtils$OrcaPaymentPage.logApi("PAYMENT_METHODS_CALL", undefined, exceptionMessage, "NoResponse", uri, undefined, undefined, logger, "ERROR", "API", undefined, undefined);
                return Promise.resolve(null);
              }));
}

function fetchCustomerDetails(clientSecret, publishableKey, endpoint, optLogger, switchToCustomPod, isPaymentSessionOpt) {
  var isPaymentSession = isPaymentSessionOpt !== undefined ? isPaymentSessionOpt : false;
  var headers = [
    [
      "Content-Type",
      "application/json"
    ],
    [
      "api-key",
      publishableKey
    ]
  ];
  var uri = endpoint + "/customers/payment_methods?client_secret=" + clientSecret;
  LoggerUtils$OrcaPaymentPage.logApi("CUSTOMER_PAYMENT_METHODS_CALL_INIT", undefined, undefined, "Request", uri, undefined, undefined, optLogger, "INFO", "API", isPaymentSession, undefined);
  return Core__Promise.$$catch(Utils$OrcaPaymentPage.fetchApi(uri, undefined, Caml_option.some(ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers, switchToCustomPod, undefined)), "GET", undefined).then(function (res) {
                  var statusCode = res.status.toString();
                  if (statusCode.charAt(0) !== "2") {
                    return res.json().then(function (data) {
                                LoggerUtils$OrcaPaymentPage.logApi("CUSTOMER_PAYMENT_METHODS_CALL", statusCode, data, "Err", uri, undefined, undefined, optLogger, "ERROR", "API", isPaymentSession, undefined);
                                return Promise.resolve(null);
                              });
                  } else {
                    LoggerUtils$OrcaPaymentPage.logApi("CUSTOMER_PAYMENT_METHODS_CALL", statusCode, undefined, "Response", uri, undefined, undefined, optLogger, "INFO", "API", isPaymentSession, undefined);
                    return res.json();
                  }
                }), (function (err) {
                var exceptionMessage = Utils$OrcaPaymentPage.formatException(err);
                LoggerUtils$OrcaPaymentPage.logApi("CUSTOMER_PAYMENT_METHODS_CALL", undefined, exceptionMessage, "NoResponse", uri, undefined, undefined, optLogger, "ERROR", "API", isPaymentSession, undefined);
                return Promise.resolve(null);
              }));
}

export {
  closePaymentLoaderIfAny ,
  retrievePaymentIntent ,
  threeDsMethod ,
  threeDsAuth ,
  pollRetrievePaymentIntent ,
  intentCall ,
  usePaymentSync ,
  maskStr ,
  maskPayload ,
  usePaymentIntent ,
  fetchSessions ,
  fetchPaymentMethodList ,
  fetchCustomerDetails ,
}
/* recoil Not a pure module */
