// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Recoil from "recoil";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Payment$OrcaPaymentPage from "./Payment.bs.js";
import * as CardUtils$OrcaPaymentPage from "./CardUtils.bs.js";
import * as OrcaLogger$OrcaPaymentPage from "./orca-log-catcher/OrcaLogger.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "./Utilities/RecoilAtoms.bs.js";
import * as ThreeDSAuth$OrcaPaymentPage from "./ThreeDSAuth.bs.js";
import * as PaymentLoader$OrcaPaymentPage from "./Components/PaymentLoader.bs.js";
import * as QRCodeDisplay$OrcaPaymentPage from "./Payments/QRCodeDisplay.bs.js";
import * as ThreeDSMethod$OrcaPaymentPage from "./ThreeDSMethod.bs.js";
import * as PreMountLoader$OrcaPaymentPage from "./Payments/PreMountLoader.bs.js";
import * as VoucherDisplay$OrcaPaymentPage from "./Payments/VoucherDisplay.bs.js";
import * as LoaderController$OrcaPaymentPage from "./LoaderController.bs.js";
import * as BankTransfersPopup$OrcaPaymentPage from "./Payments/BankTransfersPopup.bs.js";
import * as FullScreenDivDriver$OrcaPaymentPage from "./Components/FullScreenDivDriver.bs.js";

function App(props) {
  var match = React.useMemo((function () {
          return [
                  OrcaLogger$OrcaPaymentPage.make(undefined, undefined, undefined, undefined, undefined, undefined),
                  Date.now()
                ];
        }), []);
  var logger = match[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoggerState = Recoil.useSetRecoilState(RecoilAtoms$OrcaPaymentPage.loggerAtom);
  var paymentMode = CardUtils$OrcaPaymentPage.getQueryParamsDictforKey(url.search, "componentName");
  var fullscreenMode = CardUtils$OrcaPaymentPage.getQueryParamsDictforKey(url.search, "fullscreenType");
  React.useEffect((function () {
          setLoggerState(function (param) {
                return logger;
              });
        }), [logger]);
  switch (fullscreenMode) {
    case "3ds" :
        return React.createElement(ThreeDSMethod$OrcaPaymentPage.make, {});
    case "3dsAuth" :
        return React.createElement(ThreeDSAuth$OrcaPaymentPage.make, {});
    case "fullscreen" :
        return React.createElement("div", {
                    id: "fullscreen"
                  }, React.createElement(FullScreenDivDriver$OrcaPaymentPage.make, {}));
    case "paymentloader" :
        return React.createElement(PaymentLoader$OrcaPaymentPage.make, {});
    case "preMountLoader" :
        var clientSecret = CardUtils$OrcaPaymentPage.getQueryParamsDictforKey(url.search, "clientSecret");
        var sessionId = CardUtils$OrcaPaymentPage.getQueryParamsDictforKey(url.search, "sessionId");
        var publishableKey = CardUtils$OrcaPaymentPage.getQueryParamsDictforKey(url.search, "publishableKey");
        var endpoint = decodeURIComponent(CardUtils$OrcaPaymentPage.getQueryParamsDictforKey(url.search, "endpoint"));
        return React.createElement(PreMountLoader$OrcaPaymentPage.make, {
                    sessionId: sessionId,
                    publishableKey: publishableKey,
                    clientSecret: clientSecret,
                    endpoint: endpoint
                  });
    case "qrData" :
        return React.createElement(QRCodeDisplay$OrcaPaymentPage.make, {});
    case "achBankTransfer" :
    case "bacsBankTransfer" :
    case "sepaBankTransfer" :
        return React.createElement(BankTransfersPopup$OrcaPaymentPage.make, {
                    transferType: fullscreenMode
                  });
    case "voucherData" :
        return React.createElement(VoucherDisplay$OrcaPaymentPage.make, {});
    default:
      return React.createElement(LoaderController$OrcaPaymentPage.make, {
                  children: React.createElement(Payment$OrcaPaymentPage.make, {
                        paymentMode: paymentMode,
                        integrateError: match$1[0],
                        logger: logger
                      }),
                  paymentMode: paymentMode,
                  setIntegrateErrorError: match$1[1],
                  logger: logger,
                  initTimestamp: match[1]
                });
  }
}

var make = App;

export {
  make ,
}
/* react Not a pure module */
