// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Utils$OrcaPaymentPage from "./Utilities/Utils.bs.js";
import * as ErrorUtils$OrcaPaymentPage from "./Utilities/ErrorUtils.bs.js";
import * as DutchLocale$OrcaPaymentPage from "./LocaleStrings/DutchLocale.bs.js";
import * as ArabicLocale$OrcaPaymentPage from "./LocaleStrings/ArabicLocale.bs.js";
import * as DefaultTheme$OrcaPaymentPage from "./DefaultTheme.bs.js";
import * as FrenchLocale$OrcaPaymentPage from "./LocaleStrings/FrenchLocale.bs.js";
import * as HebrewLocale$OrcaPaymentPage from "./LocaleStrings/HebrewLocale.bs.js";
import * as PolishLocale$OrcaPaymentPage from "./LocaleStrings/PolishLocale.bs.js";
import * as CatalanLocale$OrcaPaymentPage from "./LocaleStrings/CatalanLocale.bs.js";
import * as DeutschLocale$OrcaPaymentPage from "./LocaleStrings/DeutschLocale.bs.js";
import * as EnglishLocale$OrcaPaymentPage from "./LocaleStrings/EnglishLocale.bs.js";
import * as ItalianLocale$OrcaPaymentPage from "./LocaleStrings/ItalianLocale.bs.js";
import * as RussianLocale$OrcaPaymentPage from "./LocaleStrings/RussianLocale.bs.js";
import * as SpanishLocale$OrcaPaymentPage from "./LocaleStrings/SpanishLocale.bs.js";
import * as SwedishLocale$OrcaPaymentPage from "./LocaleStrings/SwedishLocale.bs.js";
import * as JapaneseLocale$OrcaPaymentPage from "./LocaleStrings/JapaneseLocale.bs.js";
import * as EnglishGBLocale$OrcaPaymentPage from "./LocaleStrings/EnglishGBLocale.bs.js";
import * as PortugueseLocale$OrcaPaymentPage from "./LocaleStrings/PortugueseLocale.bs.js";
import * as LocaleStringHelper$OrcaPaymentPage from "./LocaleStrings/LocaleStringHelper.bs.js";
import * as FrenchBelgiumLocale$OrcaPaymentPage from "./LocaleStrings/FrenchBelgiumLocale.bs.js";

function getTheme(val, logger) {
  switch (val) {
    case "brutal" :
        return "Brutal";
    case "charcoal" :
        return "Charcoal";
    case "default" :
        return "Default";
    case "midnight" :
        return "Midnight";
    case "none" :
        return "NONE";
    case "soft" :
        return "Soft";
    default:
      ErrorUtils$OrcaPaymentPage.unknownPropValueWarning(val, [
            "default",
            "midnight",
            "brutal",
            "charcoal",
            "soft",
            "none"
          ], "appearance.theme", logger);
      return "Default";
  }
}

function getShowLoader(str, logger) {
  switch (str) {
    case "always" :
        return "Always";
    case "auto" :
        return "Auto";
    case "never" :
        return "Never";
    default:
      ErrorUtils$OrcaPaymentPage.unknownPropValueWarning(str, [
            "auto",
            "always",
            "never"
          ], "loader", logger);
      return "Auto";
  }
}

function getPaymentMode(val) {
  switch (val) {
    case "card" :
        return "Card";
    case "cardCvc" :
        return "CardCVCElement";
    case "cardExpiry" :
        return "CardExpiryElement";
    case "cardNumber" :
        return "CardNumberElement";
    case "payment" :
        return "Payment";
    default:
      return "NONE";
  }
}

var defaultAppearance_rules = {};

var defaultAppearance = {
  theme: "Default",
  componentType: "payment",
  variables: DefaultTheme$OrcaPaymentPage.default,
  rules: defaultAppearance_rules,
  labels: "Above"
};

var defaultConfig_fonts = [];

var defaultConfig = {
  appearance: defaultAppearance,
  locale: "auto",
  clientSecret: "",
  fonts: defaultConfig_fonts,
  loader: "Auto"
};

function getLocaleObject(string) {
  var locale = string === "auto" ? navigator.language : string;
  var match = LocaleStringHelper$OrcaPaymentPage.mapLocalStringToTypeLocale(locale);
  switch (match) {
    case "EN" :
        return EnglishLocale$OrcaPaymentPage.localeStrings;
    case "HE" :
        return HebrewLocale$OrcaPaymentPage.localeStrings;
    case "FR" :
        return FrenchLocale$OrcaPaymentPage.localeStrings;
    case "EN_GB" :
        return EnglishGBLocale$OrcaPaymentPage.localeStrings;
    case "AR" :
        return ArabicLocale$OrcaPaymentPage.localeStrings;
    case "JA" :
        return JapaneseLocale$OrcaPaymentPage.localeStrings;
    case "DE" :
        return DeutschLocale$OrcaPaymentPage.localeStrings;
    case "FR_BE" :
        return FrenchBelgiumLocale$OrcaPaymentPage.localeStrings;
    case "ES" :
        return SpanishLocale$OrcaPaymentPage.localeStrings;
    case "CA" :
        return CatalanLocale$OrcaPaymentPage.localeStrings;
    case "PT" :
        return PortugueseLocale$OrcaPaymentPage.localeStrings;
    case "IT" :
        return ItalianLocale$OrcaPaymentPage.localeStrings;
    case "PL" :
        return PolishLocale$OrcaPaymentPage.localeStrings;
    case "NL" :
        return DutchLocale$OrcaPaymentPage.localeStrings;
    case "SV" :
        return SwedishLocale$OrcaPaymentPage.localeStrings;
    case "RU" :
        return RussianLocale$OrcaPaymentPage.localeStrings;
    
  }
}

var defaultRecoilConfig_localeString = getLocaleObject("auto");

var defaultRecoilConfig = {
  config: defaultConfig,
  themeObj: DefaultTheme$OrcaPaymentPage.default,
  localeString: defaultRecoilConfig_localeString,
  showLoader: false
};

function getVariables(str, dict, $$default, logger) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                    var validKeys = [
                      "fontFamily",
                      "fontSizeBase",
                      "colorPrimary",
                      "colorBackground",
                      "colorText",
                      "colorDanger",
                      "colorDangerText",
                      "borderRadius",
                      "fontVariantLigatures",
                      "fontVariationSettings",
                      "spacingUnit",
                      "fontWeightLight",
                      "fontWeightNormal",
                      "fontWeightMedium",
                      "fontWeightBold",
                      "fontLineHeight",
                      "fontSizeXl",
                      "fontSizeLg",
                      "fontSizeSm",
                      "fontSizeXs",
                      "fontSize2Xs",
                      "fontSize3Xs",
                      "colorSuccess",
                      "colorWarning",
                      "colorPrimaryText",
                      "colorBackgroundText",
                      "colorSuccessText",
                      "colorWarningText",
                      "colorTextSecondary",
                      "colorTextPlaceholder",
                      "spacingTab",
                      "borderColor",
                      "spacingGridColumn",
                      "spacingGridRow",
                      "spacingAccordionItem",
                      "buttonBackgroundColor",
                      "buttonHeight",
                      "buttonWidth",
                      "buttonBorderRadius",
                      "buttonBorderColor",
                      "buttonTextColor",
                      "buttonTextFontSize",
                      "buttonTextFontWeight"
                    ];
                    ErrorUtils$OrcaPaymentPage.unknownKeysWarning(validKeys, json, "appearance.variables", logger);
                    return {
                            fontFamily: Utils$OrcaPaymentPage.getWarningString(json, "fontFamily", $$default.fontFamily, logger),
                            fontSizeBase: Utils$OrcaPaymentPage.getWarningString(json, "fontSizeBase", $$default.fontSizeBase, logger),
                            colorPrimary: Utils$OrcaPaymentPage.getWarningString(json, "colorPrimary", $$default.colorPrimary, logger),
                            colorBackground: Utils$OrcaPaymentPage.getWarningString(json, "colorBackground", $$default.colorBackground, logger),
                            colorText: Utils$OrcaPaymentPage.getWarningString(json, "colorText", $$default.colorText, logger),
                            colorDanger: Utils$OrcaPaymentPage.getWarningString(json, "colorDanger", $$default.colorDanger, logger),
                            borderRadius: Utils$OrcaPaymentPage.getWarningString(json, "borderRadius", $$default.borderRadius, logger),
                            fontVariantLigatures: Utils$OrcaPaymentPage.getWarningString(json, "fontVariantLigatures", $$default.fontVariantLigatures, logger),
                            fontVariationSettings: Utils$OrcaPaymentPage.getWarningString(json, "fontVariationSettings", $$default.fontVariationSettings, logger),
                            spacingUnit: Utils$OrcaPaymentPage.getWarningString(json, "spacingUnit", $$default.spacingUnit, logger),
                            fontWeightLight: Utils$OrcaPaymentPage.getWarningString(json, "fontWeightLight", $$default.fontWeightLight, logger),
                            fontWeightNormal: Utils$OrcaPaymentPage.getWarningString(json, "fontWeightNormal", $$default.fontWeightNormal, logger),
                            fontWeightMedium: Utils$OrcaPaymentPage.getWarningString(json, "fontWeightMedium", $$default.fontWeightMedium, logger),
                            fontWeightBold: Utils$OrcaPaymentPage.getWarningString(json, "fontWeightBold", $$default.fontWeightBold, logger),
                            fontLineHeight: Utils$OrcaPaymentPage.getWarningString(json, "fontLineHeight", $$default.fontLineHeight, logger),
                            fontSize2Xl: Utils$OrcaPaymentPage.getWarningString(json, "fontSize2Xl", $$default.fontSize2Xl, logger),
                            fontSizeXl: Utils$OrcaPaymentPage.getWarningString(json, "fontSizeXl", $$default.fontSizeXl, logger),
                            fontSizeLg: Utils$OrcaPaymentPage.getWarningString(json, "fontSizeLg", $$default.fontSizeLg, logger),
                            fontSizeSm: Utils$OrcaPaymentPage.getWarningString(json, "fontSizeSm", $$default.fontSizeSm, logger),
                            fontSizeXs: Utils$OrcaPaymentPage.getWarningString(json, "fontSizeXs", $$default.fontSizeXs, logger),
                            fontSize2Xs: Utils$OrcaPaymentPage.getWarningString(json, "fontSize2Xs", $$default.fontSize2Xs, logger),
                            fontSize3Xs: Utils$OrcaPaymentPage.getWarningString(json, "fontSize3Xs", $$default.fontSize3Xs, logger),
                            colorSuccess: Utils$OrcaPaymentPage.getWarningString(json, "colorSuccess", $$default.colorSuccess, logger),
                            colorWarning: Utils$OrcaPaymentPage.getWarningString(json, "colorWarning", $$default.colorWarning, logger),
                            colorPrimaryText: Utils$OrcaPaymentPage.getWarningString(json, "colorPrimaryText", $$default.colorPrimaryText, logger),
                            colorBackgroundText: Utils$OrcaPaymentPage.getWarningString(json, "colorBackgroundText", $$default.colorBackgroundText, logger),
                            colorSuccessText: Utils$OrcaPaymentPage.getWarningString(json, "colorSuccessText", $$default.colorSuccessText, logger),
                            colorDangerText: Utils$OrcaPaymentPage.getWarningString(json, "colorDangerText", $$default.colorDangerText, logger),
                            colorWarningText: Utils$OrcaPaymentPage.getWarningString(json, "colorWarningText", $$default.colorWarningText, logger),
                            colorTextSecondary: Utils$OrcaPaymentPage.getWarningString(json, "colorTextSecondary", $$default.colorTextSecondary, logger),
                            colorTextPlaceholder: Utils$OrcaPaymentPage.getWarningString(json, "colorTextPlaceholder", $$default.colorTextPlaceholder, logger),
                            spacingTab: Utils$OrcaPaymentPage.getWarningString(json, "spacingTab", $$default.spacingTab, logger),
                            borderColor: Utils$OrcaPaymentPage.getWarningString(json, "borderColor", $$default.borderColor, logger),
                            spacingAccordionItem: Utils$OrcaPaymentPage.getWarningString(json, "spacingAccordionItem", $$default.spacingAccordionItem, logger),
                            colorIconCardCvc: Utils$OrcaPaymentPage.getWarningString(json, "colorIconCardCvc", $$default.colorIconCardCvc, logger),
                            colorIconCardCvcError: Utils$OrcaPaymentPage.getWarningString(json, "colorIconCardCvcError", $$default.colorIconCardCvcError, logger),
                            colorIconCardError: Utils$OrcaPaymentPage.getWarningString(json, "colorIconCardError", $$default.colorIconCardError, logger),
                            spacingGridColumn: Utils$OrcaPaymentPage.getWarningString(json, "spacingGridColumn", $$default.spacingGridColumn, logger),
                            spacingGridRow: Utils$OrcaPaymentPage.getWarningString(json, "spacingGridRow", $$default.spacingGridRow, logger),
                            buttonBackgroundColor: Utils$OrcaPaymentPage.getWarningString(json, "buttonBackgroundColor", $$default.buttonBackgroundColor, logger),
                            buttonHeight: Utils$OrcaPaymentPage.getWarningString(json, "buttonHeight", $$default.buttonHeight, logger),
                            buttonWidth: Utils$OrcaPaymentPage.getWarningString(json, "buttonWidth", $$default.buttonWidth, logger),
                            buttonBorderRadius: Utils$OrcaPaymentPage.getWarningString(json, "buttonBorderRadius", $$default.buttonBorderRadius, logger),
                            buttonBorderColor: Utils$OrcaPaymentPage.getWarningString(json, "buttonBorderColor", $$default.buttonBorderColor, logger),
                            buttonTextColor: Utils$OrcaPaymentPage.getWarningString(json, "buttonTextColor", $$default.buttonTextColor, logger),
                            buttonTextFontSize: Utils$OrcaPaymentPage.getWarningString(json, "buttonTextFontSize", $$default.buttonTextFontSize, logger),
                            buttonTextFontWeight: Utils$OrcaPaymentPage.getWarningString(json, "buttonTextFontWeight", $$default.buttonTextFontWeight, logger)
                          };
                  })), $$default);
}

function getAppearance(str, dict, $$default, defaultRules, logger) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                    ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
                          "theme",
                          "variables",
                          "rules",
                          "labels"
                        ], json, "appearance", logger);
                    var rulesJson = defaultRules(getVariables("variables", json, $$default, logger));
                    var str = Utils$OrcaPaymentPage.getWarningString(json, "labels", "above", logger);
                    var tmp;
                    switch (str) {
                      case "above" :
                          tmp = "Above";
                          break;
                      case "floating" :
                          tmp = "Floating";
                          break;
                      case "none" :
                          tmp = "Never";
                          break;
                      default:
                        ErrorUtils$OrcaPaymentPage.unknownPropValueWarning(str, [
                              "above",
                              "floating",
                              "never"
                            ], "appearance.labels", logger);
                        tmp = "Above";
                    }
                    return {
                            theme: getTheme(Utils$OrcaPaymentPage.getWarningString(json, "theme", "default", logger), logger),
                            componentType: Utils$OrcaPaymentPage.getWarningString(json, "componentType", "", logger),
                            variables: getVariables("variables", json, $$default, logger),
                            rules: Utils$OrcaPaymentPage.mergeJsons(rulesJson, Utils$OrcaPaymentPage.getJsonObjectFromDict(json, "rules")),
                            labels: tmp
                          };
                  })), defaultAppearance);
}

function getFonts(str, dict, logger) {
  return Belt_Array.keepMap(Core__Option.getOr(Core__Option.flatMap(dict[str], Core__JSON.Decode.array), []), Core__JSON.Decode.object).map(function (json) {
              ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
                    "cssSrc",
                    "family",
                    "src",
                    "weight"
                  ], json, "fonts", logger);
              return {
                      cssSrc: Utils$OrcaPaymentPage.getWarningString(json, "cssSrc", "", logger),
                      family: Utils$OrcaPaymentPage.getWarningString(json, "family", "", logger),
                      src: Utils$OrcaPaymentPage.getWarningString(json, "src", "", logger),
                      weight: Utils$OrcaPaymentPage.getWarningString(json, "weight", "", logger)
                    };
            });
}

function itemToObjMapper(dict, $$default, defaultRules, logger) {
  ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
        "appearance",
        "fonts",
        "locale",
        "clientSecret",
        "loader"
      ], dict, "elements", logger);
  return {
          appearance: getAppearance("appearance", dict, $$default, defaultRules, logger),
          locale: Utils$OrcaPaymentPage.getWarningString(dict, "locale", "auto", logger),
          clientSecret: Utils$OrcaPaymentPage.getWarningString(dict, "clientSecret", "", logger),
          fonts: getFonts("fonts", dict, logger),
          loader: getShowLoader(Utils$OrcaPaymentPage.getWarningString(dict, "loader", "auto", logger), logger)
        };
}

var defaultFonts = {
  cssSrc: "",
  family: "",
  src: "",
  weight: ""
};

export {
  getTheme ,
  getShowLoader ,
  getPaymentMode ,
  defaultAppearance ,
  defaultFonts ,
  defaultConfig ,
  getLocaleObject ,
  defaultRecoilConfig ,
  getVariables ,
  getAppearance ,
  getFonts ,
  itemToObjMapper ,
}
/* defaultRecoilConfig Not a pure module */
