// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

function localeStrings_achBankDebitTerms(str) {
  return "Предоставляя номер своего счета и подтверждая этот платеж, вы уполномочиваете " + str + " и Hyperswitch, нашего поставщика платежных услуг, отправить инструкции в ваш банк для списания средств с вашего счета, а ваш банк — списать средства с вашего счета в соответствии с этими инструкциями. Вы имеете право на возврат средств от своего банка в соответствии с условиями вашего договора с банком. Заявление на возврат средств должно быть подано в течение 8 недель, начиная с даты списания средств с вашего счета.";
}

function localeStrings_sepaDebitTerms(str) {
  return "Предоставляя свои платежные данные и подтверждая данный платеж, вы уполномочиваете (А) " + str + " и Hyperswitch, нашего поставщика платежных услуг и/или PPRO, его местного поставщика услуг, отправить инструкции в ваш банк для списания средств с вашего счета и (Б) ваш банк списать средства с вашего счета в соответствии с этими инструкциями. В рамках своих прав вы имеете право на возврат средств от своего банка в соответствии с условиями вашего договора с банком. Заявление на возврат средств должно быть подано в течение 8 недель, начиная с даты списания средств с вашего счета. Ваши права разъясняются в заявлении, которое вы можете получить в своем банке. Вы соглашаетесь получать уведомления о будущих списаниях средств за 2 дня до их осуществления.";
}

function localeStrings_cardTerms(str) {
  return "Предоставляя данные своей карты, вы позволяете компании " + str + " списать средства с вашей карты для будущих платежей в соответствии с ее условиями.";
}

function localeStrings_surchargeMsgAmount(currency, str) {
  return React.createElement(React.Fragment, {}, "Дополнительная сумма в размере" + Utils$OrcaPaymentPage.nbsp, React.createElement("strong", undefined, currency + " " + str), Utils$OrcaPaymentPage.nbsp + "будет применено к этой транзакции");
}

function localeStrings_surchargeMsgAmountForCard(currency, str) {
  return React.createElement(React.Fragment, {}, "Сумма доплаты до" + Utils$OrcaPaymentPage.nbsp, React.createElement("strong", undefined, currency + " " + str), Utils$OrcaPaymentPage.nbsp + "будет применено к этой транзакции");
}

function localeStrings_nameEmptyText(str) {
  return "Пожалуйста, предоставьте свой " + str;
}

function localeStrings_completeNameEmptyText(str) {
  return "Пожалуйста, предоставьте полную " + str;
}

var localeStrings = {
  locale: "ru",
  cardNumberLabel: "Номер карты",
  localeDirection: "ltr",
  inValidCardErrorText: "Номер карты недействителен.",
  inCompleteCVCErrorText: "Неправильно указан код безопасности карты.",
  inCompleteExpiryErrorText: "Неправильно указан срок действия карты.",
  pastExpiryErrorText: "Год истечения срока действия карты в прошлом.",
  poweredBy: "Работает на Hyperswitch",
  validThruText: "Окончание действия",
  sortCodeText: "Номер отделения банка",
  cvcTextLabel: "CVC",
  emailLabel: "Электронная почта",
  emailEmptyText: "Электронная почта не может быть пустой",
  emailInvalidText: "Неверный адрес электронной почты",
  accountNumberText: "Номер счета",
  fullNameLabel: "Ф.И.О.",
  line1Label: "Адресная строка 1",
  line1Placeholder: "Улица",
  line1EmptyText: "Адресная строка 1 не может быть пустой.",
  line2Label: "Адресная строка 2",
  line2Placeholder: "Квартира, номер блока и т. д. (необязательно)",
  line2EmptyText: "Адресная строка 2 не может быть пустой.",
  cityLabel: "Город",
  cityEmptyText: "Город не может быть пустым",
  postalCodeLabel: "Почтовый индекс",
  postalCodeEmptyText: "Почтовый индекс не может быть пустым",
  postalCodeInvalidText: "Неверный почтовый индекс",
  stateLabel: "Область",
  stateEmptyText: "Штат не может быть пустым",
  fullNamePlaceholder: "Имя и фамилия",
  countryLabel: "Страна",
  currencyLabel: "Валюта",
  bankLabel: "Выберите банк",
  redirectText: "После оформления заказа вы будете перенаправлены на другую страницу для безопасного завершения покупки.",
  bankDetailsText: "После ввода этих данных вы получите банковские реквизиты для совершения платежа. Обязательно запишите их.",
  orPayUsing: "Или оплатить с помощью",
  addNewCard: "Добавить кредитную/дебетовую карту",
  useExisitingSavedCards: "Использовать сохраненные дебетовые/кредитные карты",
  saveCardDetails: "Сохранить данные карты",
  addBankAccount: "Добавить банковский счет",
  achBankDebitTerms: localeStrings_achBankDebitTerms,
  sepaDebitTerms: localeStrings_sepaDebitTerms,
  becsDebitTerms: "Предоставляя свои банковские реквизиты и подтверждая этот платеж, вы соглашаетесь с настоящим Запросом на прямое дебетование и соглашением об услуге Запроса на прямое дебетование и уполномочиваете Hyperswitch Payments Australia Pty Ltd ACN 160 180 343 Direct Debit User ID number 507156 («Hyperswitch») списать средства с вашего счета через Систему массовых электронных расчетов (BECS) от имени Платежного виджета Hyperswitch («Продавец») на любые суммы, отдельно сообщенные вам Продавцом. Вы подтверждаете, что являетесь владельцем счета или уполномоченным лицом с правом подписи по указанному выше счету.",
  cardTerms: localeStrings_cardTerms,
  payNowButton: "Оплатить сейчас",
  cardNumberEmptyText: "Необходимо указать номер карты",
  cardExpiryDateEmptyText: "Необходимо указать дату окончания срока действия карты",
  cvcNumberEmptyText: "Необходимо указать номер CVC",
  enterFieldsText: "Заполните все поля",
  enterValidDetailsText: "Введите действительные данные",
  selectPaymentMethodText: "Пожалуйста, выберите способ оплаты и повторите попытку.",
  card: "Карта",
  surchargeMsgAmount: localeStrings_surchargeMsgAmount,
  surchargeMsgAmountForCard: localeStrings_surchargeMsgAmountForCard,
  surchargeMsgAmountForOneClickWallets: "Возможна дополнительная плата",
  billingNameLabel: "Имя плательщика",
  billingNamePlaceholder: "Имя и фамилия",
  cardHolderName: "Имя держателя карты",
  on: "на",
  and: "и",
  nameEmptyText: localeStrings_nameEmptyText,
  completeNameEmptyText: localeStrings_completeNameEmptyText,
  billingDetailsText: "Платежные реквизиты",
  socialSecurityNumberLabel: "Номер социального страхования",
  saveWalletDetails: "Данные кошельков будут сохранены после выбора.",
  morePaymentMethods: "Больше способов оплаты",
  useExistingPaymentMethods: "Используйте сохраненные способы оплаты",
  cardNickname: "Прозвище карты",
  nicknamePlaceholder: "Псевдоним карты (необязательно)"
};

export {
  localeStrings ,
}
/* react Not a pure module */
