// Generated by ReScript, PLEASE EDIT WITH CARE


var isInteg = sdkUrl === "https://dev.singularitytools.net";

var isSandbox = sdkUrl === "https://checkout.sandbox.singularitytools.net";

var isProd = sdkUrl === "https://checkout.singularitytools.net";

var targetOrigin = "*";

export {
  targetOrigin ,
  isInteg ,
  isSandbox ,
  isProd ,
}
/* isInteg Not a pure module */
