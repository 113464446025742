// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Recoil from "recoil";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as RenderIf$OrcaPaymentPage from "./RenderIf.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "../Utilities/RecoilAtoms.bs.js";

function PaymentField(props) {
  var __className = props.className;
  var __placeholder = props.placeholder;
  var __type_ = props.type_;
  var __name = props.name;
  var __fieldName = props.fieldName;
  var __rightIcon = props.rightIcon;
  var onBlur = props.onBlur;
  var value = props.value;
  var setValue = props.setValue;
  var rightIcon = __rightIcon !== undefined ? Caml_option.valFromOption(__rightIcon) : null;
  var fieldName = __fieldName !== undefined ? __fieldName : "";
  var name = __name !== undefined ? __name : "";
  var type_ = __type_ !== undefined ? __type_ : "text";
  var placeholder = __placeholder !== undefined ? __placeholder : "";
  var className = __className !== undefined ? __className : "";
  var match = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.configAtom);
  var config = match.config;
  var match$1 = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.configAtom);
  var themeObj = match$1.themeObj;
  var match$2 = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.optionAtom);
  var match$3 = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.keys);
  var parentURL = match$3.parentURL;
  var match$4 = React.useState(function () {
        return false;
      });
  var setInputFocused = match$4[1];
  var inputFocused = match$4[0];
  var handleFocus = function (param) {
    setInputFocused(function (param) {
          return true;
        });
    if (setValue !== undefined) {
      setValue(function (prev) {
            return {
                    value: prev.value,
                    isValid: undefined,
                    errorString: ""
                  };
          });
    }
    Utils$OrcaPaymentPage.handleOnFocusPostMessage(parentURL, undefined);
  };
  var handleBlur = function (ev) {
    setInputFocused(function (param) {
          return false;
        });
    if (onBlur !== undefined) {
      onBlur(ev);
    }
    Utils$OrcaPaymentPage.handleOnBlurPostMessage(parentURL, undefined);
  };
  var backgroundClass;
  backgroundClass = props.paymentType === "Payment" ? themeObj.colorBackground : "transparent";
  var direction = type_ === "password" || type_ === "tel" ? "ltr" : "";
  var focusClass = inputFocused || value.value.length > 0 ? "mb-7 pb-1 pt-2 " + themeObj.fontSizeXs + " transition-all ease-in duration-75" : "transition-all ease-in duration-75";
  var floatinglabelClass = inputFocused ? "Label--floating" : "Label--resting";
  var getClassName = function (initialLabel) {
    if (value.value.length === 0) {
      return initialLabel + "--empty";
    }
    var valid = value.isValid;
    if (valid !== undefined && !valid) {
      return initialLabel + "--invalid";
    } else {
      return "";
    }
  };
  var labelClass = getClassName("Label");
  var inputClass = getClassName("Input");
  return React.createElement("div", {
              className: "flex flex-col w-full",
              style: {
                color: themeObj.colorText
              }
            }, React.createElement(RenderIf$OrcaPaymentPage.make, {
                  condition: fieldName.length > 0 && config.appearance.labels === "Above",
                  children: React.createElement("div", {
                        className: "Label " + labelClass,
                        style: {
                          fontSize: themeObj.fontSizeLg,
                          fontWeight: themeObj.fontWeightNormal,
                          marginBottom: "5px",
                          opacity: "0.6"
                        }
                      }, fieldName)
                }), React.createElement("div", {
                  className: "flex flex-row ",
                  style: {
                    direction: direction
                  }
                }, React.createElement("div", {
                      className: "relative w-full"
                    }, React.createElement("input", {
                          ref: Caml_option.some(props.inputRef),
                          className: "Input " + inputClass + " " + className + " focus:outline-none transition-shadow ease-out duration-200",
                          style: {
                            background: backgroundClass,
                            padding: themeObj.spacingUnit,
                            width: "100%"
                          },
                          autoComplete: "on",
                          disabled: match$2.readOnly,
                          maxLength: props.maxLength,
                          name: name,
                          pattern: props.pattern,
                          placeholder: config.appearance.labels === "Above" || config.appearance.labels === "Never" ? placeholder : "",
                          type: type_,
                          value: value.value,
                          onFocus: handleFocus,
                          onBlur: handleBlur,
                          onChange: props.onChange
                        }), React.createElement(RenderIf$OrcaPaymentPage.make, {
                          condition: config.appearance.labels === "Floating",
                          children: React.createElement("div", {
                                className: "Label " + floatinglabelClass + " " + labelClass + " absolute bottom-0 ml-3 " + focusClass,
                                style: {
                                  fontSize: inputFocused || value.value.length > 0 ? themeObj.fontSizeXs : "",
                                  marginBottom: inputFocused || value.value.length > 0 ? "" : themeObj.spacingUnit,
                                  opacity: "0.6"
                                }
                              }, fieldName)
                        })), React.createElement("div", {
                      className: "relative flex -ml-10  items-center"
                    }, rightIcon)), React.createElement(RenderIf$OrcaPaymentPage.make, {
                  condition: value.errorString.length > 0,
                  children: React.createElement("div", {
                        className: "Error pt-1",
                        style: {
                          color: themeObj.colorDangerText,
                          fontSize: themeObj.fontSizeSm,
                          textAlign: "left",
                          alignSelf: "start"
                        }
                      }, value.errorString)
                }));
}

var make = PaymentField;

export {
  make ,
}
/* react Not a pure module */
