// Generated by ReScript, PLEASE EDIT WITH CARE


function logApi(eventName, statusCodeOpt, dataOpt, apiLogType, urlOpt, paymentMethodOpt, resultOpt, optLogger, logTypeOpt, logCategoryOpt, isPaymentSessionOpt, param) {
  var statusCode = statusCodeOpt !== undefined ? statusCodeOpt : "";
  var data = dataOpt !== undefined ? dataOpt : ({});
  var url = urlOpt !== undefined ? urlOpt : "";
  var paymentMethod = paymentMethodOpt !== undefined ? paymentMethodOpt : "";
  var result = resultOpt !== undefined ? resultOpt : ({});
  var logType = logTypeOpt !== undefined ? logTypeOpt : "INFO";
  var logCategory = logCategoryOpt !== undefined ? logCategoryOpt : "API";
  var isPaymentSession = isPaymentSessionOpt !== undefined ? isPaymentSessionOpt : false;
  var match;
  switch (apiLogType) {
    case "Request" :
        match = [
          [[
              "url",
              url
            ]],
          []
        ];
        break;
    case "Response" :
        match = [
          [
            [
              "url",
              url
            ],
            [
              "statusCode",
              statusCode
            ]
          ],
          [[
              "response",
              data
            ]]
        ];
        break;
    case "NoResponse" :
        match = [
          [
            [
              "url",
              url
            ],
            [
              "statusCode",
              "504"
            ],
            [
              "response",
              data
            ]
          ],
          [[
              "response",
              data
            ]]
        ];
        break;
    case "Method" :
        match = [
          [[
              "method",
              paymentMethod
            ]],
          [[
              "result",
              result
            ]]
        ];
        break;
    case "Err" :
        match = [
          [
            [
              "url",
              url
            ],
            [
              "statusCode",
              statusCode
            ],
            [
              "response",
              data
            ]
          ],
          [[
              "response",
              data
            ]]
        ];
        break;
    
  }
  if (optLogger !== undefined) {
    return optLogger.setLogApi({
                TAG: "ArrayType",
                _0: match[0]
              }, {
                TAG: "ArrayType",
                _0: match[1]
              }, eventName, undefined, logType, logCategory, undefined, apiLogType, isPaymentSession, undefined);
  }
  
}

function logInputChangeInfo(text, logger) {
  logger.setLogInfo(text, undefined, "INPUT_FIELD_CHANGED", undefined, undefined, undefined, undefined, undefined, undefined);
}

function handleLogging(optLogger, value, internalMetadataOpt, eventName, paymentMethod, logTypeOpt, param) {
  var internalMetadata = internalMetadataOpt !== undefined ? internalMetadataOpt : "";
  var logType = logTypeOpt !== undefined ? logTypeOpt : "INFO";
  if (optLogger !== undefined) {
    return optLogger.setLogInfo(value, internalMetadata, eventName, undefined, undefined, logType, undefined, paymentMethod, undefined);
  }
  
}

export {
  logApi ,
  logInputChangeInfo ,
  handleLogging ,
}
/* No side effect */
