// Generated by ReScript, PLEASE EDIT WITH CARE


function mapLocalStringToTypeLocale(val) {
  switch (val) {
    case "ar" :
        return "AR";
    case "ca" :
        return "CA";
    case "de" :
        return "DE";
    case "en" :
        return "EN";
    case "en-GB" :
        return "EN_GB";
    case "es" :
        return "ES";
    case "fr" :
        return "FR";
    case "fr-BE" :
        return "FR_BE";
    case "he" :
        return "HE";
    case "it" :
        return "IT";
    case "ja" :
        return "JA";
    case "nl" :
        return "NL";
    case "pl" :
        return "PL";
    case "pt" :
        return "PT";
    case "ru" :
        return "RU";
    case "sv" :
        return "SV";
    default:
      return "EN";
  }
}

export {
  mapLocalStringToTypeLocale ,
}
/* No side effect */
