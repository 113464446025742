// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

function localeStrings_achBankDebitTerms(str) {
  return "Ved at angive dit kontonummer og bekræfte denne betaling giver du " + str + " og Hyperswitch, vores betalingstjenesteudbyder, tilladelse til at sende instruktioner til din bank om at debitere din konto og din bank om at debitere din konto i overensstemmelse med disse instruktioner. Du har ret til en refusion fra din bank i henhold til vilkårene og betingelserne i din aftale med din bank. Der skal anmodes om en refusion inden for otte uger fra den dato, hvor din konto blev debiteret.";
}

function localeStrings_sepaDebitTerms(str) {
  return "Ved at angive dine betalingsoplysninger og bekræfte denne betaling giver du (A) " + str + " og Hyperswitch, vores betalingstjenesteudbyder og/eller PPRO, dens lokale tjenesteudbyder, tilladelse til at sende instruktioner til din bank om at debitere din konto og (B) din bank om at debitere din konto i overensstemmelse med disse instruktioner. Som en del af dine rettigheder har du ret til en refusion fra din bank i henhold til vilkårene og betingelserne i din aftale med din bank. Der skal anmodes om en refusion inden for otte uger fra den dato, hvor din konto blev debiteret. Dine rettigheder er forklaret i en erklæring, som du kan få fra din bank. Du accepterer at modtage meddelelser om fremtidige debiteringer op til to dage, før de finder sted.";
}

function localeStrings_cardTerms(str) {
  return "Ved at angive dine kortoplysninger giver du tilladelse " + str + " til at debitere dit kort for fremtidige betalinger i overensstemmelse med deres vilkår.";
}

function localeStrings_surchargeMsgAmount(currency, str) {
  return React.createElement(React.Fragment, {}, "Een toeslagbedrag van" + Utils$OrcaPaymentPage.nbsp, React.createElement("strong", undefined, currency + " " + str), Utils$OrcaPaymentPage.nbsp + "zal voor deze transactie worden toegepast");
}

function localeStrings_surchargeMsgAmountForCard(currency, str) {
  return React.createElement(React.Fragment, {}, "Een toeslagbedrag van maximaal" + Utils$OrcaPaymentPage.nbsp, React.createElement("strong", undefined, currency + " " + str), Utils$OrcaPaymentPage.nbsp + "zal voor deze transactie worden toegepast");
}

function localeStrings_nameEmptyText(str) {
  return "Geef alstublieft uw " + str;
}

function localeStrings_completeNameEmptyText(str) {
  return "Geef uw volledige " + str;
}

var localeStrings = {
  locale: "nl",
  cardNumberLabel: "Kortnummer",
  localeDirection: "ltr",
  inValidCardErrorText: "Kortnummeret er ugyldigt.",
  inCompleteCVCErrorText: "Dit korts sikkerhedskode er ufuldstændig.",
  inCompleteExpiryErrorText: "Dit korts udløbsdato er ufuldstændig.",
  pastExpiryErrorText: "Dit korts udløbsår er i fortiden",
  poweredBy: "Drevet af Hyperswitch",
  validThruText: "Udløbsdato",
  sortCodeText: "Registreringsnummer",
  cvcTextLabel: "CVC",
  emailLabel: "E-mail",
  emailEmptyText: "E-mail mag niet leeg zijn",
  emailInvalidText: "Ongeldig e-mailadres",
  accountNumberText: "Kontonummer",
  fullNameLabel: "Fulde navn",
  line1Label: "Adresselinje 1",
  line1Placeholder: "Gadenavn",
  line1EmptyText: "Adresregel 1 mag niet leeg zijn",
  line2Label: "Adresselinje 2",
  line2Placeholder: "Lejl., enhedsnummer osv. (valgfrit)",
  line2EmptyText: "Adresregel 2 mag niet leeg zijn",
  cityLabel: "By",
  cityEmptyText: "De stad kan niet leeg zijn",
  postalCodeLabel: "Postnummer",
  postalCodeEmptyText: "Postcode mag niet leeg zijn",
  postalCodeInvalidText: "Ongeldige postcode",
  stateLabel: "Region",
  stateEmptyText: "De staat kan niet leeg zijn",
  fullNamePlaceholder: "For- og efternavn",
  countryLabel: "Land",
  currencyLabel: "Valuta",
  bankLabel: "Vælg bank",
  redirectText: "Når du har indsendt din bestilling, bliver du omdirigeret for at gennemføre dit køb på en sikker måde.",
  bankDetailsText: "Når du har indsendt disse oplysninger, vil du få oplysninger om den bankkonto, du skal betale til. Sørg for at notede dem.",
  orPayUsing: "Eller betal ved hjælp af",
  addNewCard: "Kredit/debetkort",
  useExisitingSavedCards: "Brug gemte debet-/kreditkort",
  saveCardDetails: "Gem kortoplysninger",
  addBankAccount: "Tilføj bankkonto",
  achBankDebitTerms: localeStrings_achBankDebitTerms,
  sepaDebitTerms: localeStrings_sepaDebitTerms,
  becsDebitTerms: "Ved at angive dine bankkontooplysninger og bekræfte denne betaling accepterer du denne anmodning om direkte debitering og serviceaftalen om anmodning om direkte debitering og giver Hyperswitch Payments Australia Pty Ltd ACN 160 180 343 Direct Debit User ID-nummer 507156 ( \"Hyperswitch\") tilladelse til at debitere din konto via Bulk Electronic Clearing System (BECS) på vegne af Hyperswitch Payment Widget (\"Forhandleren\") for eventuelle beløb, som Forhandleren har meddelt dig separat. Du bekræfter, at du enten er en kontoindehaver eller en autoriseret underskriver på den konto, der er angivet ovenfor.",
  cardTerms: localeStrings_cardTerms,
  payNowButton: "Betal nu",
  cardNumberEmptyText: "Kortnummeret kan ikke være tomt",
  cardExpiryDateEmptyText: "Kortets udløbsdato kan ikke være tom",
  cvcNumberEmptyText: "CVC-nummeret kan ikke være tomt",
  enterFieldsText: "Udfyld venligst alle dato felter",
  enterValidDetailsText: "Indtast gyldige oplysninger",
  selectPaymentMethodText: "Selecteer een betaalmethode en probeer het opnieuw",
  card: "Kort",
  surchargeMsgAmount: localeStrings_surchargeMsgAmount,
  surchargeMsgAmountForCard: localeStrings_surchargeMsgAmountForCard,
  surchargeMsgAmountForOneClickWallets: "Extra kosten van toepassing",
  billingNameLabel: "Faktureringsnavn",
  billingNamePlaceholder: "Voornaam en achternaam",
  cardHolderName: "Naam van de kaarthouder",
  on: "op",
  and: "En",
  nameEmptyText: localeStrings_nameEmptyText,
  completeNameEmptyText: localeStrings_completeNameEmptyText,
  billingDetailsText: "Factureringsgegevens",
  socialSecurityNumberLabel: "Burgerservicenummer",
  saveWalletDetails: "Portefeuillegegevens worden bij selectie opgeslagen",
  morePaymentMethods: "Meer betaalmethoden",
  useExistingPaymentMethods: "Gebruik opgeslagen betaalmethoden",
  cardNickname: "Kaartbijnaam",
  nicknamePlaceholder: "Bijnaam kaart (optioneel)"
};

export {
  localeStrings ,
}
/* react Not a pure module */
