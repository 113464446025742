// Generated by ReScript, PLEASE EDIT WITH CARE


function checkIsSafari() {
  var userAgentString = navigator.userAgent;
  var chromeAgent = userAgentString.indexOf("Chrome") > -1;
  var safariAgent = userAgentString.indexOf("Safari") > -1;
  if (chromeAgent) {
    return false;
  } else {
    return safariAgent;
  }
}

var date = new Date();

function broswerInfo() {
  var colorDepth = [
      1,
      4,
      8,
      15,
      16,
      24,
      32,
      48
    ].includes(screen.colorDepth) ? screen.colorDepth : 24;
  return [[
            "browser_info",
            Object.fromEntries([
                  [
                    "user_agent",
                    navigator.userAgent
                  ],
                  [
                    "accept_header",
                    "text\/html,application\/xhtml+xml,application\/xml;q=0.9,image\/webp,image\/apng,*\/*;q=0.8"
                  ],
                  [
                    "language",
                    navigator.language
                  ],
                  [
                    "color_depth",
                    colorDepth
                  ],
                  [
                    "screen_height",
                    screen.height
                  ],
                  [
                    "screen_width",
                    screen.width
                  ],
                  [
                    "time_zone",
                    date.getTimezoneOffset()
                  ],
                  [
                    "java_enabled",
                    true
                  ],
                  [
                    "java_script_enabled",
                    true
                  ]
                ])
          ]];
}

export {
  checkIsSafari ,
  date ,
  broswerInfo ,
}
/* date Not a pure module */
