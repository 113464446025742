// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

function localeStrings_achBankDebitTerms(str) {
  return "En fournissant votre numéro de compte et en confirmant ce paiement, vous autorisez " + str + " et Hyperswitch, notre prestataire de services de paiement, à envoyer des instructions à votre banque pour débiter votre compte et votre banque à débiter votre compte conformément à ces instructions. Vous avez droit à un remboursement de la part de votre banque selon les termes et conditions de l'accord que vous avez conclu avec elle. Le remboursement doit être demandé dans un délai de 8 semaines à compter de la date à laquelle votre compte a été débité.";
}

function localeStrings_sepaDebitTerms(str) {
  return "En fournissant vos informations de paiement et en confirmant ce paiement, vous autorisez (A) " + str + " et Hyperswitch, notre prestataire de services de paiement et/ou PPRO, son prestataire de services local, à envoyer des instructions à votre banque pour débiter votre compte et (B) votre banque à débiter votre compte conformément à ces instructions. Dans le cadre de vos droits, vous avez droit à un remboursement de votre banque selon les termes et conditions de votre accord avec votre banque. Le remboursement doit être demandé dans un délai de 8 semaines à compter de la date à laquelle votre compte a été débité. Vos droits sont expliqués dans une déclaration que vous pouvez obtenir auprès de votre banque. Vous acceptez de recevoir des notifications pour les débits futurs jusqu'à 2 jours avant qu'ils ne se produisent.";
}

function localeStrings_cardTerms(str) {
  return "En fournissant les informations relatives à votre carte, vous autorisez " + str + " à débiter votre carte pour les paiements futurs conformément à leurs conditions.";
}

function localeStrings_surchargeMsgAmount(currency, str) {
  return React.createElement(React.Fragment, {}, "Un montant supplémentaire de" + Utils$OrcaPaymentPage.nbsp, React.createElement("strong", undefined, currency + " " + str), Utils$OrcaPaymentPage.nbsp + "sera appliqué pour cette transaction");
}

function localeStrings_surchargeMsgAmountForCard(currency, str) {
  return React.createElement(React.Fragment, {}, "Un montant supplémentaire pouvant aller jusqu'à" + Utils$OrcaPaymentPage.nbsp, React.createElement("strong", undefined, currency + " " + str), Utils$OrcaPaymentPage.nbsp + "sera appliqué pour cette transaction");
}

function localeStrings_nameEmptyText(str) {
  return "Veuillez fournir votre " + str;
}

function localeStrings_completeNameEmptyText(str) {
  return "Veuillez fournir votre complet " + str;
}

var localeStrings = {
  locale: "fr-BE",
  cardNumberLabel: "Numéro de carte",
  localeDirection: "ltr",
  inValidCardErrorText: "Le numéro de carte n'est pas valide.",
  inCompleteCVCErrorText: "Le code de sécurité de votre carte est incomplet.",
  inCompleteExpiryErrorText: "La date d'expiration de votre carte est incomplète.",
  pastExpiryErrorText: "L'année d'expiration de votre carte est passée.",
  poweredBy: "Alimenté par Hyperswitch",
  validThruText: "Expiration",
  sortCodeText: "Code guichet",
  cvcTextLabel: "CVC",
  emailLabel: "E-mail",
  emailEmptyText: "L'e-mail ne peut pas être vide",
  emailInvalidText: "Adresse e-mail invalide",
  accountNumberText: "Numéro dAccount Number",
  fullNameLabel: "Nom cFull name",
  line1Label: "Adresse ligne 1",
  line1Placeholder: "Rue",
  line1EmptyText: "La ligne d'adresse 1 ne peut pas être vide",
  line2Label: "Adresse ligne 2",
  line2Placeholder: "Appartement, numéro d'unité, etc. (facultatif)",
  line2EmptyText: "La ligne d'adresse 2 ne peut pas être vide",
  cityLabel: "Ville",
  cityEmptyText: "La ville ne peut pas être vide",
  postalCodeLabel: "Code postal",
  postalCodeEmptyText: "Le code postal ne peut pas être vide",
  postalCodeInvalidText: "Code postal invalide",
  stateLabel: "État",
  stateEmptyText: "L'état ne peut pas être vide",
  fullNamePlaceholder: "Nom et prénom",
  countryLabel: "Pays",
  currencyLabel: "Devise",
  bankLabel: "Sélectionner une banque",
  redirectText: "Après avoir passé votre commande, vous serez redirigé pour effectuer votre achat en toute sécurité.",
  bankDetailsText: "Après avoir envoyé ces informations, vous obtiendrez les informations de votre compte bancaire pour effectuer le paiement. Veillez à en prendre note.",
  orPayUsing: "Ou payer en utilisant",
  addNewCard: "Ajouter une carte de crédit/débit",
  useExisitingSavedCards: "Utiliser les cartes de débit/crédit enregistrées",
  saveCardDetails: "Enregistrer les détails de la carte",
  addBankAccount: "Ajouter un compte bancaire",
  achBankDebitTerms: localeStrings_achBankDebitTerms,
  sepaDebitTerms: localeStrings_sepaDebitTerms,
  becsDebitTerms: "En fournissant vos coordonnées bancaires et en confirmant ce paiement, vous acceptez la présente demande de prélèvement automatique et l'accord de service de demande de prélèvement automatique et autorisez Hyperswitch Payments Australia Pty Ltd ACN 160 180 343 Numéro d'identification d'utilisateur de prélèvement automatique 507156 (« Hyperswitch ») à débiter votre compte via le système de compensation électronique en bloc (BECS) au nom de Hyperswitch Payment Widget (le « Marchand ») pour tout montant qui vous est communiqué séparément par le Marchand. Vous certifiez que vous êtes soit le titulaire du compte, soit un signataire autorisé du compte mentionné ci-dessus.",
  cardTerms: localeStrings_cardTerms,
  payNowButton: "Payer maintenant",
  cardNumberEmptyText: "Le numéro de carte ne peut pas être vide",
  cardExpiryDateEmptyText: "La date d'expiration de la carte ne peut pas être vide",
  cvcNumberEmptyText: "Le numéro CVC ne peut pas être vide",
  enterFieldsText: "Veuillez saisir tous les champs",
  enterValidDetailsText: "Veuillez saisir des détails valides",
  selectPaymentMethodText: "Veuillez sélectionner un mode de paiement et réessayer",
  card: "Carte",
  surchargeMsgAmount: localeStrings_surchargeMsgAmount,
  surchargeMsgAmountForCard: localeStrings_surchargeMsgAmountForCard,
  surchargeMsgAmountForOneClickWallets: "Frais supplémentaires applicables",
  billingNameLabel: "Nom de facturation",
  billingNamePlaceholder: "Nom et prénom",
  cardHolderName: "Nom du titulaire",
  on: "sur",
  and: "et",
  nameEmptyText: localeStrings_nameEmptyText,
  completeNameEmptyText: localeStrings_completeNameEmptyText,
  billingDetailsText: "Détails de la facturation",
  socialSecurityNumberLabel: "Numéro de sécurité sociale",
  saveWalletDetails: "Les détails des portefeuilles seront enregistrés lors de la sélection",
  morePaymentMethods: "Plus de méthodes de paiement",
  useExistingPaymentMethods: "Utiliser les modes de paiement enregistrés",
  cardNickname: "Pseudonyme de la carte",
  nicknamePlaceholder: "Surnom de la carte (facultatif)"
};

export {
  localeStrings ,
}
/* react Not a pure module */
