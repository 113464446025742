// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as Utils$OrcaPaymentPage from "./Utilities/Utils.bs.js";
import * as OrcaLogger$OrcaPaymentPage from "./orca-log-catcher/OrcaLogger.bs.js";
import * as LoggerUtils$OrcaPaymentPage from "./Utilities/LoggerUtils.bs.js";
import * as PaymentHelpers$OrcaPaymentPage from "./Utilities/PaymentHelpers.bs.js";

function ThreeDSMethod(props) {
  var logger = OrcaLogger$OrcaPaymentPage.make(undefined, undefined, undefined, undefined, undefined, undefined);
  var mountToInnerHTML = function (innerHTML) {
    var ele = document.querySelector("#threeDsInvisibleIframe");
    if (ele == null) {
      console.warn("INTEGRATION ERROR: Div does not seem to exist on which threeDSMethod is to be mounted");
    } else {
      ele.innerHTML = innerHTML;
    }
  };
  React.useEffect((function () {
          Utils$OrcaPaymentPage.handlePostMessage(undefined, [[
                  "iframeMountedCallback",
                  true
                ]]);
          var handle = function (ev) {
            var json = JSON.parse(ev.data);
            var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
            if (!Core__Option.isSome(dict["fullScreenIframeMounted"])) {
              return ;
            }
            var metadata = Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "metadata");
            var metaDataDict = Core__Option.getOr(Core__JSON.Decode.object(metadata), {});
            var threeDsDataDict = Core__Option.getOr(Belt_Option.flatMap(metaDataDict["threeDSData"], Core__JSON.Decode.object), {});
            var threeDsUrl = Core__Option.getOr(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(threeDsDataDict["three_ds_method_details"], Core__JSON.Decode.object), (function (x) {
                            return x["three_ds_method_url"];
                          })), Core__JSON.Decode.string), "");
            var threeDsMethodData = Core__Option.getOr(Belt_Option.flatMap(Belt_Option.flatMap(threeDsDataDict["three_ds_method_details"], Core__JSON.Decode.object), (function (x) {
                        return x["three_ds_method_data"];
                      })), {});
            var paymentIntentId = Utils$OrcaPaymentPage.getString(metaDataDict, "paymentIntentId", "");
            var publishableKey = Utils$OrcaPaymentPage.getString(metaDataDict, "publishableKey", "");
            var iframeId = Utils$OrcaPaymentPage.getString(metaDataDict, "iframeId", "");
            logger.setClientSecret(paymentIntentId);
            logger.setMerchantId(publishableKey);
            Core__Promise.$$catch(PaymentHelpers$OrcaPaymentPage.threeDsMethod(threeDsUrl, threeDsMethodData, logger).then(function (res) {
                      if (res === "") {
                        return Promise.reject(Js_exn.raiseError("Empty response from threeDsMethod"));
                      } else {
                        LoggerUtils$OrcaPaymentPage.handleLogging(logger, "Y", undefined, "THREE_DS_METHOD_RESULT", "CARD", undefined, undefined);
                        mountToInnerHTML(res);
                        Utils$OrcaPaymentPage.getDictFromJson(metadata)["3dsMethodComp"] = "Y";
                        Utils$OrcaPaymentPage.handlePostMessage(undefined, [
                              [
                                "fullscreen",
                                true
                              ],
                              [
                                "param",
                                "3dsAuth"
                              ],
                              [
                                "iframeId",
                                iframeId
                              ],
                              [
                                "metadata",
                                metadata
                              ]
                            ]);
                        return Promise.resolve(res);
                      }
                    }), (function (err) {
                    var exceptionMessage = Utils$OrcaPaymentPage.formatException(err);
                    Utils$OrcaPaymentPage.getDictFromJson(metadata)["3dsMethodComp"] = "N";
                    Utils$OrcaPaymentPage.handlePostMessage(undefined, [
                          [
                            "fullscreen",
                            true
                          ],
                          [
                            "param",
                            "3dsAuth"
                          ],
                          [
                            "iframeId",
                            iframeId
                          ],
                          [
                            "metadata",
                            metadata
                          ]
                        ]);
                    LoggerUtils$OrcaPaymentPage.handleLogging(logger, JSON.stringify(exceptionMessage), undefined, "THREE_DS_METHOD_RESULT", "CARD", "ERROR", undefined);
                    return Promise.reject(err);
                  }));
            var headersDict = Core__Option.getOr(Core__JSON.Decode.object(Utils$OrcaPaymentPage.getJsonObjectFromDict(metaDataDict, "headers")), {});
            var headers = {};
            Object.entries(headersDict).forEach(function (entries) {
                  headers[entries[0]] = Core__Option.getOr(Core__JSON.Decode.string(entries[1]), "");
                });
          };
          window.addEventListener("message", handle);
          return (function () {
                    window.removeEventListener("message", handle);
                  });
        }), []);
  return React.createElement("div", {
              className: "bg-black-100 h-96",
              id: "threeDsInvisibleIframe"
            });
}

var make = ThreeDSMethod;

export {
  make ,
}
/* react Not a pure module */
