// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Recoil from "recoil";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Utils$OrcaPaymentPage from "./Utilities/Utils.bs.js";
import * as Recoil$OrcaPaymentPage from "./libraries/Recoil.bs.js";
import * as Window$OrcaPaymentPage from "./Window.bs.js";
import * as CardTheme$OrcaPaymentPage from "./CardTheme.bs.js";
import * as CardUtils$OrcaPaymentPage from "./CardUtils.bs.js";
import * as ApiEndpoint$OrcaPaymentPage from "./Utilities/ApiEndpoint.bs.js";
import * as CommonHooks$OrcaPaymentPage from "./Hooks/CommonHooks.bs.js";
import * as ElementType$OrcaPaymentPage from "./Types/ElementType.bs.js";
import * as PaymentType$OrcaPaymentPage from "./Types/PaymentType.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "./Utilities/RecoilAtoms.bs.js";
import * as DefaultTheme$OrcaPaymentPage from "./DefaultTheme.bs.js";

function LoaderController(props) {
  var initTimestamp = props.initTimestamp;
  var logger = props.logger;
  var setIntegrateErrorError = props.setIntegrateErrorError;
  var paymentMode = props.paymentMode;
  var match = Recoil.useRecoilState(RecoilAtoms$OrcaPaymentPage.configAtom);
  var setConfig = match[1];
  var match$1 = Recoil.useRecoilState(RecoilAtoms$OrcaPaymentPage.keys);
  var setKeys = match$1[1];
  var keys = match$1[0];
  var match$2 = Recoil.useRecoilState(RecoilAtoms$OrcaPaymentPage.list);
  var setList = match$2[1];
  var paymentlist = match$2[0];
  var match$3 = Recoil.useRecoilState(RecoilAtoms$OrcaPaymentPage.sessions);
  var setSessions = match$3[1];
  var match$4 = Recoil.useRecoilState(RecoilAtoms$OrcaPaymentPage.elementOptions);
  var setOptions = match$4[1];
  var options = match$4[0];
  var match$5 = Recoil.useRecoilState(RecoilAtoms$OrcaPaymentPage.optionAtom);
  var setOptionsPayment = match$5[1];
  var optionsPayment = match$5[0];
  var setSessionId = Recoil.useSetRecoilState(RecoilAtoms$OrcaPaymentPage.sessionId);
  var setBlockConfirm = Recoil.useSetRecoilState(RecoilAtoms$OrcaPaymentPage.isConfirmBlocked);
  var setSwitchToCustomPod = Recoil.useSetRecoilState(RecoilAtoms$OrcaPaymentPage.switchToCustomPod);
  var setIsGooglePayReady = Recoil.useSetRecoilState(RecoilAtoms$OrcaPaymentPage.isGooglePayReady);
  var setIsApplePayReady = Recoil.useSetRecoilState(RecoilAtoms$OrcaPaymentPage.isApplePayReady);
  var match$6 = React.useState(function () {
        return 0.0;
      });
  var setDivH = match$6[1];
  var divH = match$6[0];
  var match$7 = React.useState(function () {
        return 0.0;
      });
  var showCardFormByDefault = optionsPayment.showCardFormByDefault;
  var paymentMethodOrder = optionsPayment.paymentMethodOrder;
  var setLaunchTime = match$7[1];
  var launchTime = match$7[0];
  var divRef = React.useRef(null);
  var iframeId = keys.iframeId;
  var config = match[0].config;
  var handlePostMessage = function (data) {
    Utils$OrcaPaymentPage.handlePostMessage(keys.parentURL, data);
  };
  var setUserFullName = Recoil$OrcaPaymentPage.useLoggedSetRecoilState(RecoilAtoms$OrcaPaymentPage.userFullName, "fullName", logger);
  var setUserEmail = Recoil$OrcaPaymentPage.useLoggedSetRecoilState(RecoilAtoms$OrcaPaymentPage.userEmailAddress, "email", logger);
  var setUserAddressline1 = Recoil$OrcaPaymentPage.useLoggedSetRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressline1, "line1", logger);
  var setUserAddressline2 = Recoil$OrcaPaymentPage.useLoggedSetRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressline2, "line2", logger);
  var setUserAddressCity = Recoil$OrcaPaymentPage.useLoggedSetRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressCity, "city", logger);
  var setUserAddressPincode = Recoil$OrcaPaymentPage.useLoggedSetRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressPincode, "pin", logger);
  var setUserAddressState = Recoil$OrcaPaymentPage.useLoggedSetRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressState, "state", logger);
  var setUserAddressCountry = Recoil$OrcaPaymentPage.useLoggedSetRecoilState(RecoilAtoms$OrcaPaymentPage.userAddressCountry, "country", logger);
  var match$8 = Recoil.useRecoilState(RecoilAtoms$OrcaPaymentPage.userCountry);
  var setCountry = match$8[1];
  var updateOptions = function (dict) {
    var optionsDict = Utils$OrcaPaymentPage.getDictFromObj(dict, "options");
    var match = CardTheme$OrcaPaymentPage.getPaymentMode(paymentMode);
    switch (match) {
      case "Payment" :
          var paymentOptions = PaymentType$OrcaPaymentPage.itemToObjMapper(optionsDict, logger);
          setOptionsPayment(function (param) {
                return paymentOptions;
              });
          [
              [
                paymentOptions.defaultValues.billingDetails.name,
                setUserFullName
              ],
              [
                paymentOptions.defaultValues.billingDetails.email,
                setUserEmail
              ],
              [
                paymentOptions.defaultValues.billingDetails.address.line1,
                setUserAddressline1
              ],
              [
                paymentOptions.defaultValues.billingDetails.address.line2,
                setUserAddressline2
              ],
              [
                paymentOptions.defaultValues.billingDetails.address.city,
                setUserAddressCity
              ],
              [
                paymentOptions.defaultValues.billingDetails.address.postal_code,
                setUserAddressPincode
              ],
              [
                paymentOptions.defaultValues.billingDetails.address.state,
                setUserAddressState
              ],
              [
                paymentOptions.defaultValues.billingDetails.address.country,
                setUserAddressCountry
              ]
            ].forEach(function (val) {
                var value = val[0];
                if (value !== "") {
                  return val[1](function (prev) {
                              return {
                                      value: value,
                                      isValid: prev.isValid,
                                      errorString: prev.errorString
                                    };
                            });
                }
                
              });
          if (paymentOptions.defaultValues.billingDetails.address.country === "") {
            var clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            var clientCountry = Utils$OrcaPaymentPage.getClientCountry(clientTimeZone);
            setUserAddressCountry(function (prev) {
                  return {
                          value: clientCountry.countryName,
                          isValid: prev.isValid,
                          errorString: prev.errorString
                        };
                });
            return setCountry(function (param) {
                        return clientCountry.countryName;
                      });
          }
          setUserAddressCountry(function (prev) {
                return {
                        value: paymentOptions.defaultValues.billingDetails.address.country,
                        isValid: prev.isValid,
                        errorString: prev.errorString
                      };
              });
          return setCountry(function (param) {
                      return paymentOptions.defaultValues.billingDetails.address.country;
                    });
      case "NONE" :
          return ;
      default:
        return setOptions(function (param) {
                    return ElementType$OrcaPaymentPage.itemToObjMapper(optionsDict, logger);
                  });
    }
  };
  var setConfigs = function (dict, themeValues) {
    var paymentOptions = Utils$OrcaPaymentPage.getDictFromObj(dict, "paymentOptions");
    var optionsDict = Utils$OrcaPaymentPage.getDictFromObj(dict, "options");
    var $$default = themeValues.default;
    var defaultRules = themeValues.defaultRules;
    var config = CardTheme$OrcaPaymentPage.itemToObjMapper(paymentOptions, $$default, defaultRules, logger);
    var optionsLocaleString = Utils$OrcaPaymentPage.getWarningString(optionsDict, "locale", "", logger);
    var optionsAppearance = CardTheme$OrcaPaymentPage.getAppearance("appearance", optionsDict, $$default, defaultRules, logger);
    var appearance = Caml_obj.equal(optionsAppearance, CardTheme$OrcaPaymentPage.defaultAppearance) ? config.appearance : optionsAppearance;
    var localeString = CardTheme$OrcaPaymentPage.getLocaleObject(optionsLocaleString === "" ? config.locale : optionsLocaleString);
    setConfig(function (param) {
          return {
                  config: {
                    appearance: appearance,
                    locale: config.locale,
                    clientSecret: config.clientSecret,
                    fonts: config.fonts,
                    loader: config.loader
                  },
                  themeObj: appearance.variables,
                  localeString: localeString,
                  showLoader: config.loader === "Auto" || config.loader === "Always"
                };
        });
  };
  React.useEffect((function () {
          handlePostMessage([[
                  "iframeMounted",
                  true
                ]]);
          handlePostMessage([[
                  "applePayMounted",
                  true
                ]]);
          logger.setLogInitiated();
          var updatedState;
          updatedState = typeof paymentlist !== "object" && paymentlist === "Loading" ? (
              showCardFormByDefault && Utils$OrcaPaymentPage.checkPriorityList(paymentMethodOrder) ? "SemiLoaded" : "Loading"
            ) : paymentlist;
          var finalLoadLatency = launchTime <= 0.0 ? -1.0 : Date.now() - launchTime;
          if (typeof updatedState !== "object") {
            if (updatedState === "Loading") {
              logger.setLogInfo("Loading", undefined, "LOADER_CHANGED", undefined, finalLoadLatency, undefined, undefined, undefined, undefined);
            } else {
              setList(function (param) {
                    return updatedState;
                  });
              logger.setLogInfo("SemiLoaded", undefined, "LOADER_CHANGED", undefined, finalLoadLatency, undefined, undefined, undefined, undefined);
            }
          } else if (updatedState.TAG === "Loaded") {
            logger.setLogInfo("Loaded", undefined, "LOADER_CHANGED", undefined, finalLoadLatency, undefined, undefined, undefined, undefined);
          } else {
            logger.setLogError("LoadError: " + JSON.stringify(updatedState._0), undefined, "LOADER_CHANGED", undefined, finalLoadLatency, undefined, undefined, undefined, undefined);
          }
          window.addEventListener("click", (function (ev) {
                  Utils$OrcaPaymentPage.handleOnClickPostMessage(keys.parentURL, ev);
                }));
          return (function () {
                    window.removeEventListener("click", (function (ev) {
                            Utils$OrcaPaymentPage.handleOnClickPostMessage(keys.parentURL, ev);
                          }));
                  });
        }), []);
  React.useEffect((function () {
          CardUtils$OrcaPaymentPage.genreateFontsLink(config.fonts);
          var dict = Utils$OrcaPaymentPage.getDictFromJson(config.appearance.rules);
          if (Object.entries(dict).length > 0) {
            Utils$OrcaPaymentPage.generateStyleSheet("", dict, "themestyle");
          }
          var match = CardTheme$OrcaPaymentPage.getPaymentMode(paymentMode);
          if (match !== "Payment") {
            var styleClass = [
              [
                "input-base",
                Utils$OrcaPaymentPage.getDictFromJson(options.style.base)
              ],
              [
                "input-complete",
                Utils$OrcaPaymentPage.getDictFromJson(options.style.complete)
              ],
              [
                "input-invalid",
                Utils$OrcaPaymentPage.getDictFromJson(options.style.invalid)
              ],
              [
                "input-empty",
                Utils$OrcaPaymentPage.getDictFromJson(options.style.empty)
              ]
            ];
            styleClass.map(function (item) {
                  var dict = item[1];
                  if (Object.entries(dict).length > 0) {
                    Utils$OrcaPaymentPage.generateStyleSheet(item[0], dict, "widgetstyle");
                    return ;
                  }
                  
                });
          }
          
        }), [config]);
  React.useEffect((function () {
          var handleFun = function (ev) {
            var json;
            try {
              json = JSON.parse(ev.data);
            }
            catch (exn){
              json = {};
            }
            try {
              var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
              if (Utils$OrcaPaymentPage.getDictIsSome(dict, "paymentElementCreate")) {
                if (Core__Option.getOr(Core__Option.flatMap(dict["paymentElementCreate"], Core__JSON.Decode.bool), false)) {
                  if (Core__Option.getOr(Core__Option.flatMap(dict["otherElements"], Core__JSON.Decode.bool), false)) {
                    updateOptions(dict);
                  } else {
                    var sdkSessionId = Utils$OrcaPaymentPage.getString(dict, "sdkSessionId", "no-element");
                    logger.setSessionId(sdkSessionId);
                    if (Window$OrcaPaymentPage.isInteg) {
                      setBlockConfirm(function (param) {
                            return Utils$OrcaPaymentPage.getBool(dict, "blockConfirm", false);
                          });
                      setSwitchToCustomPod(function (param) {
                            return Utils$OrcaPaymentPage.getBool(dict, "switchToCustomPod", false);
                          });
                    }
                    updateOptions(dict);
                    setSessionId(function (param) {
                          return sdkSessionId;
                        });
                    if (Utils$OrcaPaymentPage.getDictIsSome(dict, "publishableKey")) {
                      var publishableKey = Utils$OrcaPaymentPage.getString(dict, "publishableKey", "");
                      logger.setMerchantId(publishableKey);
                    }
                    if (Utils$OrcaPaymentPage.getDictIsSome(dict, "endpoint")) {
                      var endpoint = Utils$OrcaPaymentPage.getString(dict, "endpoint", "");
                      ApiEndpoint$OrcaPaymentPage.setApiEndPoint(endpoint);
                    }
                    if (Utils$OrcaPaymentPage.getDictIsSome(dict, "analyticsMetadata")) {
                      var metadata = Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "analyticsMetadata");
                      logger.setMetadata(metadata);
                    }
                    if (Utils$OrcaPaymentPage.getDictIsSome(dict, "paymentOptions")) {
                      var paymentOptions = Utils$OrcaPaymentPage.getDictFromObj(dict, "paymentOptions");
                      var clientSecret = Utils$OrcaPaymentPage.getWarningString(paymentOptions, "clientSecret", "", logger);
                      setKeys(function (prev) {
                            return {
                                    clientSecret: clientSecret,
                                    publishableKey: prev.publishableKey,
                                    iframeId: prev.iframeId,
                                    parentURL: prev.parentURL,
                                    sdkHandleOneClickConfirmPayment: prev.sdkHandleOneClickConfirmPayment
                                  };
                          });
                      logger.setClientSecret(clientSecret);
                      var promise = Utils$OrcaPaymentPage.getThemePromise(paymentOptions);
                      if (promise !== undefined) {
                        Caml_option.valFromOption(promise).then(function (res) {
                              setConfigs(dict, res);
                              return Promise.resolve();
                            });
                      } else {
                        setConfigs(dict, {
                              default: DefaultTheme$OrcaPaymentPage.default,
                              defaultRules: DefaultTheme$OrcaPaymentPage.defaultRules
                            });
                      }
                    }
                    var newLaunchTime = Utils$OrcaPaymentPage.getFloat(dict, "launchTime", 0.0);
                    setLaunchTime(function (param) {
                          return newLaunchTime;
                        });
                    var initLoadlatency = Date.now() - newLaunchTime;
                    logger.setLogInfo(window.location.href, undefined, "APP_RENDERED", undefined, initLoadlatency, undefined, undefined, undefined, undefined);
                    [
                        [
                          "iframeId",
                          "no-element"
                        ],
                        [
                          "publishableKey",
                          ""
                        ],
                        [
                          "parentURL",
                          "*"
                        ],
                        [
                          "sdkHandleOneClickConfirmPayment",
                          true
                        ]
                      ].forEach(function (keyPair) {
                          CommonHooks$OrcaPaymentPage.updateKeys(dict, keyPair, setKeys);
                        });
                    var renderLatency = Date.now() - initTimestamp;
                    logger.setLogInfo("", undefined, "PAYMENT_OPTIONS_PROVIDED", undefined, renderLatency, undefined, undefined, undefined, undefined);
                  }
                } else if (Utils$OrcaPaymentPage.getDictIsSome(dict, "paymentOptions")) {
                  var paymentOptions$1 = Utils$OrcaPaymentPage.getDictFromObj(dict, "paymentOptions");
                  var clientSecret$1 = Utils$OrcaPaymentPage.getWarningString(paymentOptions$1, "clientSecret", "", logger);
                  setKeys(function (prev) {
                        return {
                                clientSecret: clientSecret$1,
                                publishableKey: prev.publishableKey,
                                iframeId: prev.iframeId,
                                parentURL: prev.parentURL,
                                sdkHandleOneClickConfirmPayment: prev.sdkHandleOneClickConfirmPayment
                              };
                      });
                  logger.setClientSecret(clientSecret$1);
                  var promise$1 = Utils$OrcaPaymentPage.getThemePromise(paymentOptions$1);
                  if (promise$1 !== undefined) {
                    Caml_option.valFromOption(promise$1).then(function (res) {
                          setConfigs(dict, res);
                          return Promise.resolve();
                        });
                  } else {
                    setConfigs(dict, {
                          default: DefaultTheme$OrcaPaymentPage.default,
                          defaultRules: DefaultTheme$OrcaPaymentPage.defaultRules
                        });
                  }
                }
                
              } else if (Utils$OrcaPaymentPage.getDictIsSome(dict, "paymentElementsUpdate")) {
                updateOptions(dict);
              } else if (Utils$OrcaPaymentPage.getDictIsSome(dict, "ElementsUpdate")) {
                var optionsDict = Utils$OrcaPaymentPage.getDictFromObj(dict, "options");
                var clientSecret$2 = dict["clientSecret"];
                if (clientSecret$2 !== undefined) {
                  setKeys(function (prev) {
                        return {
                                clientSecret: Utils$OrcaPaymentPage.getStringFromJson(clientSecret$2, ""),
                                publishableKey: prev.publishableKey,
                                iframeId: prev.iframeId,
                                parentURL: prev.parentURL,
                                sdkHandleOneClickConfirmPayment: prev.sdkHandleOneClickConfirmPayment
                              };
                      });
                  setConfig(function (prev) {
                        var init = prev.config;
                        return {
                                config: {
                                  appearance: init.appearance,
                                  locale: init.locale,
                                  clientSecret: Utils$OrcaPaymentPage.getStringFromJson(clientSecret$2, ""),
                                  fonts: init.fonts,
                                  loader: init.loader
                                },
                                themeObj: prev.themeObj,
                                localeString: prev.localeString,
                                showLoader: prev.showLoader
                              };
                      });
                }
                var promise$2 = Utils$OrcaPaymentPage.getThemePromise(optionsDict);
                if (promise$2 !== undefined) {
                  Caml_option.valFromOption(promise$2).then(function (res) {
                        setConfigs(dict, res);
                        return Promise.resolve();
                      });
                } else {
                  setConfigs(dict, {
                        default: DefaultTheme$OrcaPaymentPage.default,
                        defaultRules: DefaultTheme$OrcaPaymentPage.defaultRules
                      });
                }
              }
              if (Utils$OrcaPaymentPage.getDictIsSome(dict, "sessions")) {
                setSessions(function (param) {
                      return {
                              TAG: "Loaded",
                              _0: Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "sessions")
                            };
                    });
              }
              if (Utils$OrcaPaymentPage.getDictIsSome(dict, "isReadyToPay")) {
                setIsGooglePayReady(function (param) {
                      return Core__Option.getOr(Core__JSON.Decode.bool(Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "isReadyToPay")), false);
                    });
              }
              if (Utils$OrcaPaymentPage.getDictIsSome(dict, "paymentMethodList")) {
                var list = Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "paymentMethodList");
                var finalLoadLatency = launchTime <= 0.0 ? -1.0 : Date.now() - launchTime;
                var updatedState;
                if (Caml_obj.equal(list, {})) {
                  updatedState = {
                    TAG: "LoadError",
                    _0: list
                  };
                } else {
                  var match = Utils$OrcaPaymentPage.getDictFromJson(list)["error"];
                  if (match !== undefined) {
                    updatedState = {
                      TAG: "LoadError",
                      _0: list
                    };
                  } else {
                    var isNonEmptyPaymentMethodList = Utils$OrcaPaymentPage.getArray(Utils$OrcaPaymentPage.getDictFromJson(list), "payment_methods").length > 0;
                    updatedState = isNonEmptyPaymentMethodList ? ({
                          TAG: "Loaded",
                          _0: list
                        }) : ({
                          TAG: "LoadError",
                          _0: list
                        });
                  }
                }
                var evalMethodsList = function () {
                  if (typeof updatedState !== "object") {
                    return ;
                  } else if (updatedState.TAG === "Loaded") {
                    return logger.setLogInfo("Loaded", undefined, "LOADER_CHANGED", undefined, finalLoadLatency, undefined, undefined, undefined, undefined);
                  } else {
                    return logger.setLogError("LoadError: " + JSON.stringify(updatedState._0), undefined, "LOADER_CHANGED", undefined, finalLoadLatency, undefined, undefined, undefined, undefined);
                  }
                };
                if (optionsPayment.displaySavedPaymentMethods) {
                  var match$1 = optionsPayment.customerPaymentMethods;
                  if (typeof match$1 === "object") {
                    if (match$1.TAG === "LoadedSavedCards" && match$1._0.length > 0) {
                      logger.setLogInfo("Loaded", undefined, "LOADER_CHANGED", undefined, finalLoadLatency, undefined, undefined, undefined, undefined);
                    } else {
                      evalMethodsList();
                    }
                  }
                  
                } else {
                  evalMethodsList();
                }
                setList(function (param) {
                      return updatedState;
                    });
              }
              if (Utils$OrcaPaymentPage.getDictIsSome(dict, "customerPaymentMethods")) {
                var customerPaymentMethods = PaymentType$OrcaPaymentPage.createCustomerObjArr(dict);
                setOptionsPayment(function (prev) {
                      var newrecord = Caml_obj.obj_dup(prev);
                      newrecord.customerPaymentMethods = customerPaymentMethods;
                      return newrecord;
                    });
                var finalLoadLatency$1 = launchTime <= 0.0 ? -1.0 : Date.now() - launchTime;
                var evalMethodsList$1 = function () {
                  if (typeof paymentlist !== "object") {
                    return ;
                  } else if (paymentlist.TAG === "Loaded") {
                    return logger.setLogInfo("Loaded", undefined, "LOADER_CHANGED", undefined, finalLoadLatency$1, undefined, undefined, undefined, undefined);
                  } else {
                    return logger.setLogError("LoadError: " + JSON.stringify(paymentlist._0), undefined, "LOADER_CHANGED", undefined, finalLoadLatency$1, undefined, undefined, undefined, undefined);
                  }
                };
                var match$2 = optionsPayment.customerPaymentMethods;
                if (typeof match$2 === "object") {
                  if (match$2.TAG === "LoadedSavedCards" && match$2._0.length > 0) {
                    logger.setLogInfo("Loaded", undefined, "LOADER_CHANGED", undefined, finalLoadLatency$1, undefined, undefined, undefined, undefined);
                  } else {
                    evalMethodsList$1();
                  }
                }
                
              }
              if (Core__Option.isSome(dict["applePayCanMakePayments"])) {
                setIsApplePayReady(function (param) {
                      return true;
                    });
              }
              if (Core__Option.isSome(dict["applePaySessionObjNotPresent"])) {
                return setIsApplePayReady(function (prev) {
                            return false;
                          });
              } else {
                return ;
              }
            }
            catch (exn$1){
              return setIntegrateErrorError(function (param) {
                          return true;
                        });
            }
          };
          return Utils$OrcaPaymentPage.handleMessage(handleFun, "Error in parsing sent Data");
        }), [
        showCardFormByDefault,
        paymentMethodOrder
      ]);
  var observer = new ResizeObserver((function (entries) {
          entries.map(function (item) {
                setDivH(function (param) {
                      return item.contentRect.height;
                    });
              });
        }));
  var r = divRef.current;
  if (!(r == null)) {
    observer.observe(r);
  }
  React.useEffect((function () {
          handlePostMessage([
                [
                  "iframeHeight",
                  divH + 1.0
                ],
                [
                  "iframeId",
                  iframeId
                ]
              ]);
        }), [
        divH,
        iframeId
      ]);
  return React.createElement("div", {
              ref: Caml_option.some(divRef)
            }, props.children);
}

var make = LoaderController;

export {
  make ,
}
/* react Not a pure module */
