// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

function localeStrings_achBankDebitTerms(str) {
  return "En facilitar el número de compte i confirmar el pagament, autoritza " + str + " i Hyperswitch, el nostre proveïdor de serveis de pagament, a enviar ordres al seu banc perquè apliqui els càrrecs corresponents al compte. Tindrà dret a rebre un reembossament del banc d'acord amb els termes i condicions del contracte que hi hagi subscrit. El reembossament s'ha de sol·licitar en un termini de 8 setmanes des de la data en què es va aplicar el càrrec al compte.";
}

function localeStrings_sepaDebitTerms(str) {
  return "En facilitar la informació de pagament i confirmar el pagament, autoritza " + str + " i Hyperswitch, el nostre proveïdor de serveis de pagament, o a PPRO, el seu proveïdor de serveis local, a enviar ordres al seu banc i a (B) perquè apliqui els càrrecs corresponents al compte. Com a part dels seus drets, podrà rebre un reembossament del banc d'acord amb els termes i condicions del contracte que hi hagi subscrit. El reembossament s'ha de sol·licitar en un termini de 8 setmanes des de la data en què es va aplicar el càrrec al compte. Els seus drets s'expliquen en un extracte que podrà sol·licitar al banc. Accepta rebre notificacions dels càrrecs futurs fins 2 dies abans que es produeixin.";
}

function localeStrings_cardTerms(str) {
  return "En facilitar la informació de la targeta, permet a " + str + " que faci càrrecs a la targeta per a pagaments futurs d'acord amb les seves condicions.";
}

function localeStrings_surchargeMsgAmount(currency, str) {
  return React.createElement(React.Fragment, {}, "Un import de recàrrec de" + Utils$OrcaPaymentPage.nbsp, React.createElement("strong", undefined, currency + " " + str), Utils$OrcaPaymentPage.nbsp + "s'aplicarà per a aquesta transacció");
}

function localeStrings_surchargeMsgAmountForCard(currency, str) {
  return React.createElement(React.Fragment, {}, "Un recàrrec de fins a" + Utils$OrcaPaymentPage.nbsp, React.createElement("strong", undefined, currency + " " + str), Utils$OrcaPaymentPage.nbsp + "s'aplicarà per a aquesta transacció");
}

function localeStrings_nameEmptyText(str) {
  return "Si us plau, proporcioneu el vostre" + str;
}

function localeStrings_completeNameEmptyText(str) {
  return "Si us plau, proporcioneu el vostre complet " + str;
}

var localeStrings = {
  locale: "ca",
  cardNumberLabel: "Número de targeta",
  localeDirection: "ltr",
  inValidCardErrorText: "El número de targeta no és vàlid.",
  inCompleteCVCErrorText: "El codi de seguretat de la targeta està incomplet.",
  inCompleteExpiryErrorText: "La data de venciment de la targeta està incompleta.",
  pastExpiryErrorText: "La data de venciment de la targeta ja ha passat.",
  poweredBy: "Amb tecnologia de Hyperswitch",
  validThruText: "Venciment",
  sortCodeText: "Codi de sucursal",
  cvcTextLabel: "CVC",
  emailLabel: "Adreça electrònica",
  emailEmptyText: "El correu electrònic no pot estar buit",
  emailInvalidText: "adressa de correu invàlida",
  accountNumberText: "Número de compte",
  fullNameLabel: "Nom complet",
  line1Label: "Línia d'adreça 1",
  line1Placeholder: "Adreça postal",
  line1EmptyText: "La línia d'adreça 1 no pot estar buida",
  line2Label: "Línia d'adreça 2",
  line2Placeholder: "Pis, número d'apartament, etc. (opcional)",
  line2EmptyText: "La línia d'adreça 2 no pot estar buida",
  cityLabel: "Ciutat",
  cityEmptyText: "La ciutat no pot estar buida",
  postalCodeLabel: "Codi postal",
  postalCodeEmptyText: "El codi postal no pot estar buit",
  postalCodeInvalidText: "Codi postal no vàlid",
  stateLabel: "Estat",
  stateEmptyText: "L'estat no pot estar buit",
  fullNamePlaceholder: "Nom i cognoms",
  countryLabel: "País",
  currencyLabel: "Moneda",
  bankLabel: "Seleccioni un banc",
  redirectText: "En fer la comanda, se li redirigirà perquè completi la compra de manera segura.",
  bankDetailsText: "Després d'enviar aquestes dades, rebrà la informació del compte bancari per fer el pagament. Recordi prendre'n nota.",
  orPayUsing: "O faci el pagament mitjançant",
  addNewCard: "Afegeixi una targeta de crèdit o dèbit",
  useExisitingSavedCards: "Faci servir les targetes de dèbit o crèdit desades",
  saveCardDetails: "Desi les dades de la targeta",
  addBankAccount: "Afegeixi un compte bancari",
  achBankDebitTerms: localeStrings_achBankDebitTerms,
  sepaDebitTerms: localeStrings_sepaDebitTerms,
  becsDebitTerms: "En facilitar les dades del compte bancari i confirmar el pagament, accepta aquesta sol·licitud de domiciliació bancària i l'acord de servei corresponent. A més, autoritza Hyperswitch Payments Australia Pty Ltd ACN 160 180 343, amb número d'identificació d'usuari de domiciliació bancària 507156, («Hyperswitch») a aplicar càrrecs al compte a través del sistema de compensació electrònica massiva (BECS) en nom de Hyperswitch Payment Widget (el «comerç») per a qualsevol import que el comerç li comuniqui individualment. Certifica que és titular d'un compte o signatari autoritzat del compte que s'indica anteriorment.",
  cardTerms: localeStrings_cardTerms,
  payNowButton: "Pagui ara",
  cardNumberEmptyText: "Cal indicar el número de la targeta",
  cardExpiryDateEmptyText: "Cal indicar la data de venciment de la targeta",
  cvcNumberEmptyText: "Cal indicar el número CVC",
  enterFieldsText: "Empleni tots els camps",
  enterValidDetailsText: "Introdueixi dades vàlides",
  selectPaymentMethodText: "Seleccioneu una forma de pagament i torneu-ho a provar",
  card: "Targeta",
  surchargeMsgAmount: localeStrings_surchargeMsgAmount,
  surchargeMsgAmountForCard: localeStrings_surchargeMsgAmountForCard,
  surchargeMsgAmountForOneClickWallets: "Taxa addicional aplicable",
  billingNameLabel: "Nom de facturació",
  billingNamePlaceholder: "Nom i cognom",
  cardHolderName: "Nom del titular de la targeta",
  on: "activat",
  and: "i",
  nameEmptyText: localeStrings_nameEmptyText,
  completeNameEmptyText: localeStrings_completeNameEmptyText,
  billingDetailsText: "Detalls de facturació",
  socialSecurityNumberLabel: "Número de la Seguretat Social",
  saveWalletDetails: "Els detalls de les carteres es desaran en seleccionar-los",
  morePaymentMethods: "més mètodes de pagament",
  useExistingPaymentMethods: "Utilitzeu formes de pagament desades",
  cardNickname: "Sobrenom de la targeta",
  nicknamePlaceholder: "Àlies de la targeta (opcional)"
};

export {
  localeStrings ,
}
/* react Not a pure module */
