// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";

var make = Curry._1(React.lazy, (function () {
        return import("./SingleLineCardPayment.bs.js");
      }));

export {
  make ,
}
/* make Not a pure module */
