// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Recoil from "recoil";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as Modal$OrcaPaymentPage from "../Components/Modal.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "../Utilities/RecoilAtoms.bs.js";
import * as PaymentHelpers$OrcaPaymentPage from "../Utilities/PaymentHelpers.bs.js";

function getKeyValue(json, str) {
  return Core__Option.getOr(Core__JSON.Decode.string(Core__Option.getOr(json[str], {})), "");
}

function QRCodeDisplay(props) {
  var match = React.useState(function () {
        return "";
      });
  var setQrCode = match[1];
  var match$1 = React.useState(function () {
        return 900000.0;
      });
  var setExpiryTime = match$1[1];
  var expiryTime = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setOpenModal = match$2[1];
  var match$3 = React.useState(function () {
        return "";
      });
  var setReturnUrl = match$3[1];
  var return_url = match$3[0];
  var match$4 = React.useState(function () {
        return "";
      });
  var setClientSecret = match$4[1];
  var clientSecret = match$4[0];
  var match$5 = React.useState(function () {
        return [];
      });
  var setHeaders = match$5[1];
  var headers = match$5[0];
  var logger = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.loggerAtom);
  var switchToCustomPod = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.switchToCustomPod);
  React.useEffect((function () {
          Utils$OrcaPaymentPage.handlePostMessage(undefined, [[
                  "iframeMountedCallback",
                  true
                ]]);
          var handle = function (ev) {
            var json = JSON.parse(ev.data);
            var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
            if (!Core__Option.isSome(dict["fullScreenIframeMounted"])) {
              return ;
            }
            var metadata = Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "metadata");
            var metaDataDict = Core__Option.getOr(Core__JSON.Decode.object(metadata), {});
            var qrData = Utils$OrcaPaymentPage.getString(metaDataDict, "qrData", "");
            setQrCode(function (param) {
                  return qrData;
                });
            var paymentIntentId = Utils$OrcaPaymentPage.getString(metaDataDict, "paymentIntentId", "");
            setClientSecret(function (param) {
                  return paymentIntentId;
                });
            var headersDict = Core__Option.getOr(Core__JSON.Decode.object(Utils$OrcaPaymentPage.getJsonObjectFromDict(metaDataDict, "headers")), {});
            var headers = {};
            setReturnUrl(function (param) {
                  return Utils$OrcaPaymentPage.getString(Utils$OrcaPaymentPage.getDictFromJson(metadata), "url", "");
                });
            Object.entries(headersDict).forEach(function (entries) {
                  headers[entries[0]] = Utils$OrcaPaymentPage.getStringFromJson(entries[1], "");
                });
            var expiryTime = Core__Option.getOr(Belt_Float.fromString(Utils$OrcaPaymentPage.getString(metaDataDict, "expiryTime", "")), 0.0);
            var timeExpiry = expiryTime - Date.now();
            if (timeExpiry > 0.0 && timeExpiry < 900000.0) {
              setExpiryTime(function (param) {
                    return timeExpiry;
                  });
            }
            setHeaders(function (param) {
                  return Object.entries(headers);
                });
            PaymentHelpers$OrcaPaymentPage.pollRetrievePaymentIntent(paymentIntentId, Object.entries(headers), logger, switchToCustomPod, undefined).then(function (res) {
                  Modal$OrcaPaymentPage.close(setOpenModal);
                  Utils$OrcaPaymentPage.postSubmitResponse(res, return_url);
                  return Promise.resolve(res);
                });
          };
          window.addEventListener("message", handle);
          return (function () {
                    window.removeEventListener("message", handle);
                  });
        }), []);
  React.useEffect((function () {
          if (expiryTime < 1000.0) {
            Modal$OrcaPaymentPage.close(setOpenModal);
          }
          var intervalID = setInterval((function () {
                  setExpiryTime(function (prev) {
                        return prev - 1000.0;
                      });
                }), 1000);
          return (function () {
                    clearInterval(intervalID);
                  });
        }), [expiryTime]);
  var closeModal = function () {
    Core__Promise.$$catch(PaymentHelpers$OrcaPaymentPage.retrievePaymentIntent(clientSecret, headers, logger, switchToCustomPod, undefined).then(function (json) {
                var dict = Core__Option.getOr(Core__JSON.Decode.object(json), {});
                var status = Utils$OrcaPaymentPage.getString(dict, "status", "");
                if (status === "succeeded") {
                  Utils$OrcaPaymentPage.postSubmitResponse(json, return_url);
                } else if (status === "failed") {
                  Utils$OrcaPaymentPage.postFailedSubmitResponse("confirm_payment_failed", "Payment failed. Try again!");
                } else {
                  Utils$OrcaPaymentPage.postFailedSubmitResponse("sync_payment_failed", "Payment is processing. Try again later!");
                }
                return Promise.resolve(json);
              }).then(function (_json) {
              Modal$OrcaPaymentPage.close(setOpenModal);
              return Promise.resolve(null);
            }), (function (e) {
            console.log("Retrieve Failed", e);
            return Promise.resolve(null);
          }));
  };
  var expiryString = React.useMemo((function () {
          var minutes = String(expiryTime / 60000.0 | 0);
          var seconds = String((expiryTime | 0) % 60000).slice(0, 2);
          var seconds$1 = seconds.length === 1 ? seconds + "0" : seconds;
          return minutes + ":" + seconds$1;
        }), [expiryTime]);
  return React.createElement(Modal$OrcaPaymentPage.make, {
              children: React.createElement("div", {
                    className: "flex flex-col h-full justify-between items-center"
                  }, React.createElement("div", {
                        className: " flex flex-row w-full justify-center items-start mb-8 font-medium text-2xl font-semibold text-[#151A1F] opacity-50"
                      }, expiryString), React.createElement("img", {
                        style: {
                          height: "13rem"
                        },
                        src: match[0]
                      }), React.createElement("div", {
                        className: " flex flex-col max-w-md justify-between items-center"
                      }, React.createElement("div", {
                            className: "Disclaimer w-full mt-16 font-medium text-xs text-[#151A1F] opacity-50"
                          }, " The QR Code is valid for the next 15 minutes, please do not close until you have successfully completed the payment, after which you will be automatically redirected. "), React.createElement("div", {
                            className: "button w-full"
                          }, React.createElement("div", undefined, React.createElement("button", {
                                    className: "w-full mt-6 p-2 h-[40px]",
                                    style: {
                                      background: "#006DF9",
                                      color: "#ffffff",
                                      borderRadius: "4px"
                                    },
                                    onClick: (function (param) {
                                        closeModal();
                                      })
                                  }, "Done"))))),
              showClose: false,
              setOpenModal: setOpenModal,
              openModal: match$2[0]
            });
}

var make = QRCodeDisplay;

export {
  getKeyValue ,
  make ,
}
/* react Not a pure module */
