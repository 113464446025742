// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Recoil from "recoil";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as Utils$OrcaPaymentPage from "./Utilities/Utils.bs.js";
import * as Country$OrcaPaymentPage from "./Country.bs.js";
import * as CardTheme$OrcaPaymentPage from "./CardTheme.bs.js";
import * as CardUtils$OrcaPaymentPage from "./CardUtils.bs.js";
import * as ConfirmType$OrcaPaymentPage from "./Types/ConfirmType.bs.js";
import * as LoggerUtils$OrcaPaymentPage from "./Utilities/LoggerUtils.bs.js";
import * as PaymentBody$OrcaPaymentPage from "./Utilities/PaymentBody.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "./Utilities/RecoilAtoms.bs.js";
import * as ErrorOccured$OrcaPaymentPage from "./Components/ErrorOccured.bs.js";
import * as PaymentHelpers$OrcaPaymentPage from "./Utilities/PaymentHelpers.bs.js";
import * as PostalCodeType$OrcaPaymentPage from "./Types/PostalCodeType.bs.js";
import * as RenderPaymentMethods$OrcaPaymentPage from "./RenderPaymentMethods.bs.js";

function setUserError(message) {
  Utils$OrcaPaymentPage.postFailedSubmitResponse("validation_error", message);
}

function Payment(props) {
  var logger = props.logger;
  var paymentMode = props.paymentMode;
  var match = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.configAtom);
  var localeString = match.localeString;
  var keys = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.keys);
  var cardScheme = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.cardBrand);
  var showFields = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.showCardFieldsAtom);
  var selectedOption = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.selectedOptionAtom);
  var paymentToken = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.paymentTokenAtom);
  var iframeId = keys.iframeId;
  var match$1 = React.useState(function () {
        return "";
      });
  var setCardNumber = match$1[1];
  var cardNumber = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setCardExpiry = match$2[1];
  var cardExpiry = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var setCvcNumber = match$3[1];
  var cvcNumber = match$3[0];
  var match$4 = React.useState(function () {
        return "";
      });
  var setZipCode = match$4[1];
  var zipCode = match$4[0];
  var match$5 = React.useState(function () {
        return "";
      });
  var setCardError = match$5[1];
  var match$6 = React.useState(function () {
        return "";
      });
  var setCvcError = match$6[1];
  var match$7 = React.useState(function () {
        return "";
      });
  var setExpiryError = match$7[1];
  var match$8 = React.useState(function () {
        return false;
      });
  var setDisplayPincode = match$8[1];
  var match$9 = React.useState(function () {
        return false;
      });
  var setIsFocus = match$9[1];
  var match$10 = React.useState(function () {
        return false;
      });
  var setBlurState = match$10[1];
  var intent = PaymentHelpers$OrcaPaymentPage.usePaymentIntent(logger, "Card");
  var cardRef = React.useRef(null);
  var expiryRef = React.useRef(null);
  var cvcRef = React.useRef(null);
  var zipRef = React.useRef(null);
  var match$11 = React.useState(function () {
        
      });
  var setIsCardValid = match$11[1];
  var isCardValid = match$11[0];
  var match$12 = React.useState(function () {
        
      });
  var setIsExpiryValid = match$12[1];
  var isExpiryValid = match$12[0];
  var match$13 = React.useState(function () {
        
      });
  var setIsCVCValid = match$13[1];
  var isCVCValid = match$13[0];
  var match$14 = React.useState(function () {
        
      });
  var setIsZipValid = match$14[1];
  var isZipValid = match$14[0];
  var match$15 = React.useMemo((function () {
          var brand = CardUtils$OrcaPaymentPage.getCardBrand(cardNumber);
          var maxLength = CardUtils$OrcaPaymentPage.getMaxLength(cardNumber);
          var isNotBancontact = selectedOption !== "bancontact_card" && brand === "";
          if (!showFields && isNotBancontact) {
            return [
                    cardScheme,
                    maxLength
                  ];
          } else {
            return [
                    brand,
                    maxLength
                  ];
          }
        }), [
        cardNumber,
        cardScheme,
        showFields
      ]);
  var cardBrand = match$15[0];
  var cardType = React.useMemo((function () {
          return CardUtils$OrcaPaymentPage.getCardType(cardBrand);
        }), [cardBrand]);
  var clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  var clientCountry = Utils$OrcaPaymentPage.getClientCountry(clientTimeZone);
  var countryNames = Utils$OrcaPaymentPage.getCountryNames(Country$OrcaPaymentPage.country);
  var countryProps_0 = clientCountry.countryName;
  var countryProps = [
    countryProps_0,
    countryNames
  ];
  var match$16 = React.useState(function () {
        return [PostalCodeType$OrcaPaymentPage.defaultPostalCode];
      });
  var setPostalCodes = match$16[1];
  var postalCodes = match$16[0];
  React.useEffect((function () {
          var obj = CardUtils$OrcaPaymentPage.getobjFromCardPattern(cardBrand);
          var cvcLength = obj.maxCVCLenth;
          if (CardUtils$OrcaPaymentPage.cvcNumberInRange(cvcNumber, cardBrand).includes(true) && cvcNumber.length === cvcLength) {
            CardUtils$OrcaPaymentPage.blurRef(cvcRef);
          }
          
        }), [
        cvcNumber,
        cardNumber
      ]);
  React.useEffect((function () {
          if (CardTheme$OrcaPaymentPage.getPaymentMode(paymentMode) === "Card") {
            Core__Promise.$$catch(import("./PostalCodes.bs.js").then(function (res) {
                      setPostalCodes(function (param) {
                            return res.default;
                          });
                      return Promise.resolve();
                    }), (function (param) {
                    setPostalCodes(function (param) {
                          return [PostalCodeType$OrcaPaymentPage.defaultPostalCode];
                        });
                    return Promise.resolve();
                  }));
          }
          
        }), []);
  var changeCardNumber = function (ev) {
    var val = ev.target.value;
    LoggerUtils$OrcaPaymentPage.logInputChangeInfo("cardNumber", logger);
    var card = CardUtils$OrcaPaymentPage.formatCardNumber(val, cardType);
    var clearValue = CardUtils$OrcaPaymentPage.clearSpaces(card);
    CardUtils$OrcaPaymentPage.setCardValid(clearValue, setIsCardValid);
    if (CardUtils$OrcaPaymentPage.cardValid(clearValue, cardBrand)) {
      CardUtils$OrcaPaymentPage.handleInputFocus(cardRef, expiryRef);
    }
    if (card.length > 6 && CardUtils$OrcaPaymentPage.pincodeVisibility(cardNumber)) {
      setDisplayPincode(function (param) {
            return true;
          });
    } else if (card.length < 8) {
      setDisplayPincode(function (param) {
            return false;
          });
    }
    setCardNumber(function (param) {
          return card;
        });
  };
  var changeCardExpiry = function (ev) {
    var val = ev.target.value;
    LoggerUtils$OrcaPaymentPage.logInputChangeInfo("cardExpiry", logger);
    var formattedExpiry = CardUtils$OrcaPaymentPage.formatCardExpiryNumber(val);
    if (CardUtils$OrcaPaymentPage.isExipryValid(formattedExpiry)) {
      CardUtils$OrcaPaymentPage.handleInputFocus(expiryRef, cvcRef);
    }
    CardUtils$OrcaPaymentPage.setExpiryValid(formattedExpiry, setIsExpiryValid);
    setCardExpiry(function (param) {
          return formattedExpiry;
        });
  };
  var changeCVCNumber = function (ev) {
    var val = ev.target.value;
    LoggerUtils$OrcaPaymentPage.logInputChangeInfo("cardCVC", logger);
    var cvc = CardUtils$OrcaPaymentPage.formatCVCNumber(val, cardBrand);
    setCvcNumber(function (param) {
          return cvc;
        });
    if (cvc.length > 0 && CardUtils$OrcaPaymentPage.cvcNumberInRange(cvc, cardBrand).includes(true)) {
      Core__Option.forEach(Caml_option.nullable_to_opt(zipRef.current), (function (input) {
              input.focus();
            }));
    }
    if (cvc.length > 0 && CardUtils$OrcaPaymentPage.cvcNumberInRange(cvc, cardBrand).includes(true)) {
      return setIsCVCValid(function (param) {
                  return true;
                });
    } else {
      return setIsCVCValid(function (param) {
                  
                });
    }
  };
  var changeZipCode = function (ev) {
    var val = ev.target.value;
    LoggerUtils$OrcaPaymentPage.logInputChangeInfo("zipCode", logger);
    var regex = CardUtils$OrcaPaymentPage.postalRegex(postalCodes, undefined, undefined);
    if (regex !== "" && new RegExp(regex).test(val)) {
      CardUtils$OrcaPaymentPage.blurRef(zipRef);
    }
    setZipCode(function (param) {
          return val;
        });
  };
  var onZipCodeKeyDown = function (ev) {
    CardUtils$OrcaPaymentPage.commonKeyDownEvent(ev, zipRef, cvcRef, zipCode, cvcNumber, setCvcNumber);
  };
  var onCvcKeyDown = function (ev) {
    CardUtils$OrcaPaymentPage.commonKeyDownEvent(ev, cvcRef, expiryRef, cvcNumber, cardExpiry, setCardExpiry);
  };
  var onExpiryKeyDown = function (ev) {
    CardUtils$OrcaPaymentPage.commonKeyDownEvent(ev, expiryRef, cardRef, cardExpiry, cardNumber, setCardNumber);
  };
  var handleCardBlur = function (ev) {
    var cardNumber = ev.target.value;
    if (CardUtils$OrcaPaymentPage.cardNumberInRange(cardNumber).includes(true) && CardUtils$OrcaPaymentPage.calculateLuhn(cardNumber)) {
      return setIsCardValid(function (param) {
                  return true;
                });
    } else if (cardNumber.length === 0) {
      return setIsCardValid(function (param) {
                  
                });
    } else {
      return setIsCardValid(function (param) {
                  return false;
                });
    }
  };
  var handleElementFocus = React.useMemo((function () {
          return function (isFocus) {
            setIsFocus(function (param) {
                  return isFocus;
                });
          };
        }), [
        isCardValid,
        isCVCValid,
        isExpiryValid,
        isZipValid
      ]);
  var handleExpiryBlur = function (ev) {
    var cardExpiry = ev.target.value;
    if (cardExpiry.length > 0 && CardUtils$OrcaPaymentPage.getExpiryValidity(cardExpiry)) {
      return setIsExpiryValid(function (param) {
                  return true;
                });
    } else if (cardExpiry.length === 0) {
      return setIsExpiryValid(function (param) {
                  
                });
    } else {
      return setIsExpiryValid(function (param) {
                  return false;
                });
    }
  };
  var handleCVCBlur = function (ev) {
    var cvcNumber = ev.target.value;
    if (cvcNumber.length > 0 && CardUtils$OrcaPaymentPage.cvcNumberInRange(cvcNumber, cardBrand).includes(true)) {
      return setIsCVCValid(function (param) {
                  return true;
                });
    } else if (cvcNumber.length === 0) {
      return setIsCVCValid(function (param) {
                  
                });
    } else {
      return setIsCVCValid(function (param) {
                  return false;
                });
    }
  };
  var handleZipBlur = function (ev) {
    var zipCode = ev.target.value;
    var regex = CardUtils$OrcaPaymentPage.postalRegex(postalCodes, undefined, undefined);
    if (new RegExp(regex).test(zipCode) || regex === "") {
      return setIsZipValid(function (param) {
                  return true;
                });
    } else if (zipCode.length === 0) {
      return setIsZipValid(function (param) {
                  
                });
    } else {
      return setIsZipValid(function (param) {
                  return false;
                });
    }
  };
  React.useEffect((function () {
          setCvcNumber(function (param) {
                return "";
              });
          setIsCVCValid(function (param) {
                
              });
          setCvcError(function (param) {
                return "";
              });
          setCardError(function (param) {
                return "";
              });
          setExpiryError(function (param) {
                return "";
              });
        }), [
        paymentToken[0],
        showFields
      ]);
  var paymentType = React.useMemo((function () {
          return CardTheme$OrcaPaymentPage.getPaymentMode(paymentMode);
        }), [paymentMode]);
  React.useEffect((function () {
          var handleFun = function (ev) {
            var json;
            try {
              json = JSON.parse(ev.data);
            }
            catch (exn){
              json = {};
            }
            var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
            if (Core__Option.isSome(dict["doBlur"])) {
              logger.setLogInfo("doBlur Triggered", undefined, "BLUR", undefined, undefined, undefined, undefined, undefined, undefined);
              return setBlurState(function (param) {
                          return true;
                        });
            } else if (Core__Option.isSome(dict["doFocus"])) {
              logger.setLogInfo("doFocus Triggered", undefined, "FOCUS", undefined, undefined, undefined, undefined, undefined, undefined);
              Core__Option.forEach(Caml_option.nullable_to_opt(cardRef.current), (function (input) {
                      input.focus();
                    }));
              return ;
            } else if (Core__Option.isSome(dict["doClearValues"])) {
              logger.setLogInfo("doClearValues Triggered", undefined, "CLEAR", undefined, undefined, undefined, undefined, undefined, undefined);
              setCardNumber(function (param) {
                    return "";
                  });
              setCardExpiry(function (param) {
                    return "";
                  });
              setCvcNumber(function (param) {
                    return "";
                  });
              setIsCardValid(function (param) {
                    
                  });
              setCardError(function (param) {
                    return "";
                  });
              setCvcError(function (param) {
                    return "";
                  });
              setExpiryError(function (param) {
                    return "";
                  });
              setIsExpiryValid(function (param) {
                    
                  });
              return setIsCVCValid(function (param) {
                          
                        });
            } else {
              return ;
            }
          };
          return Utils$OrcaPaymentPage.handleMessage(handleFun, "Error in parsing sent Data");
        }), []);
  React.useEffect((function () {
          var handleDoSubmit = function (ev) {
            var json = JSON.parse(ev.data);
            var jsonDict = Utils$OrcaPaymentPage.getDictFromJson(json);
            var confirm = ConfirmType$OrcaPaymentPage.itemToObjMapper(jsonDict);
            if (confirm.doSubmit) {
              var confirmParam = confirm.confirmParams;
              var match = CardTheme$OrcaPaymentPage.getPaymentMode(paymentMode);
              var validFormat;
              switch (match) {
                case "Card" :
                    validFormat = Core__Option.getOr(isCardValid, false) && Core__Option.getOr(isExpiryValid, false) && Core__Option.getOr(isCVCValid, false);
                    break;
                case "CardNumberElement" :
                    validFormat = Core__Option.getOr(isCardValid, false) && CardUtils$OrcaPaymentPage.checkCardCVC(CardUtils$OrcaPaymentPage.getCardElementValue(iframeId, "card-cvc"), cardBrand) && CardUtils$OrcaPaymentPage.checkCardExpiry(CardUtils$OrcaPaymentPage.getCardElementValue(iframeId, "card-expiry"));
                    break;
                default:
                  validFormat = true;
              }
              var cardNetwork = cardBrand !== "" ? [[
                    "card_network",
                    CardUtils$OrcaPaymentPage.getCardBrand(cardNumber)
                  ]] : [];
              if (validFormat) {
                var match$1 = CardTheme$OrcaPaymentPage.getPaymentMode(paymentMode);
                var body;
                switch (match$1) {
                  case "Card" :
                      var match$2 = CardUtils$OrcaPaymentPage.getExpiryDates(cardExpiry);
                      body = PaymentBody$OrcaPaymentPage.cardPaymentBody(cardNumber, match$2[0], match$2[1], "", cvcNumber, cardNetwork, undefined, undefined);
                      break;
                  case "CardNumberElement" :
                      var match$3 = CardUtils$OrcaPaymentPage.getExpiryDates(CardUtils$OrcaPaymentPage.getCardElementValue(iframeId, "card-expiry"));
                      var localCvcNumber = CardUtils$OrcaPaymentPage.getCardElementValue(iframeId, "card-cvc");
                      body = PaymentBody$OrcaPaymentPage.cardPaymentBody(cardNumber, match$3[0], match$3[1], "", localCvcNumber, cardNetwork, undefined, undefined);
                      break;
                  default:
                    body = [];
                }
                var match$4 = CardTheme$OrcaPaymentPage.getPaymentMode(paymentMode);
                switch (match$4) {
                  case "Card" :
                  case "CardNumberElement" :
                      return intent(false, body, confirmParam, undefined, undefined);
                  default:
                    return ;
                }
              } else {
                if (cardNumber === "") {
                  setCardError(function (param) {
                        return localeString.cardNumberEmptyText;
                      });
                  Utils$OrcaPaymentPage.postFailedSubmitResponse("validation_error", localeString.enterFieldsText);
                }
                if (cardExpiry === "") {
                  setExpiryError(function (param) {
                        return localeString.cardExpiryDateEmptyText;
                      });
                  Utils$OrcaPaymentPage.postFailedSubmitResponse("validation_error", localeString.enterFieldsText);
                }
                if (cvcNumber === "") {
                  setCvcError(function (param) {
                        return localeString.cvcNumberEmptyText;
                      });
                  Utils$OrcaPaymentPage.postFailedSubmitResponse("validation_error", localeString.enterFieldsText);
                }
                if (!validFormat) {
                  return Utils$OrcaPaymentPage.postFailedSubmitResponse("validation_error", localeString.enterValidDetailsText);
                } else {
                  return ;
                }
              }
            }
            
          };
          return Utils$OrcaPaymentPage.handleMessage(handleDoSubmit, "");
        }), [
        cardNumber,
        cvcNumber,
        cardExpiry,
        isCVCValid,
        isExpiryValid,
        isCardValid
      ]);
  React.useEffect((function () {
          setCardError(function (param) {
                if (Core__Option.getOr(isCardValid, true)) {
                  return "";
                } else {
                  return localeString.inValidCardErrorText;
                }
              });
        }), [isCardValid]);
  React.useEffect((function () {
          setCvcError(function (param) {
                if (Core__Option.getOr(isCVCValid, true)) {
                  return "";
                } else {
                  return localeString.inCompleteCVCErrorText;
                }
              });
        }), [isCVCValid]);
  React.useEffect((function () {
          setExpiryError(function (param) {
                var match = CardUtils$OrcaPaymentPage.isExpiryComplete(cardExpiry);
                if (isExpiryValid !== undefined) {
                  if (isExpiryValid) {
                    if (match) {
                      return "";
                    } else {
                      return localeString.inCompleteExpiryErrorText;
                    }
                  } else if (match) {
                    return localeString.pastExpiryErrorText;
                  } else {
                    return localeString.inCompleteExpiryErrorText;
                  }
                } else {
                  return "";
                }
              });
        }), [
        isExpiryValid,
        CardUtils$OrcaPaymentPage.isExpiryComplete(cardExpiry)
      ]);
  var icon = React.useMemo((function () {
          var animate = cardType === "NOTFOUND" ? "animate-slideLeft" : "animate-slideRight";
          var cardBrandIcon = CardUtils$OrcaPaymentPage.getCardBrandIcon(cardType, paymentType);
          return React.createElement("div", {
                      className: animate
                    }, cardBrandIcon);
        }), [
        cardType,
        paymentType
      ]);
  var cardProps_7 = match$5[0];
  var cardProps_9 = match$15[1];
  var cardProps = [
    isCardValid,
    setIsCardValid,
    cardNumber,
    changeCardNumber,
    handleCardBlur,
    cardRef,
    icon,
    cardProps_7,
    setCardError,
    cardProps_9
  ];
  var expiryProps_7 = match$7[0];
  var expiryProps = [
    isExpiryValid,
    setIsExpiryValid,
    cardExpiry,
    changeCardExpiry,
    handleExpiryBlur,
    expiryRef,
    onExpiryKeyDown,
    expiryProps_7,
    setExpiryError
  ];
  var cvcProps_8 = match$6[0];
  var cvcProps = [
    isCVCValid,
    setIsCVCValid,
    cvcNumber,
    setCvcNumber,
    changeCVCNumber,
    handleCVCBlur,
    cvcRef,
    onCvcKeyDown,
    cvcProps_8,
    setCvcError
  ];
  var zipProps_7 = match$8[0];
  var zipProps = [
    isZipValid,
    setIsZipValid,
    zipCode,
    changeZipCode,
    handleZipBlur,
    zipRef,
    onZipCodeKeyDown,
    zipProps_7
  ];
  if (props.integrateError) {
    return React.createElement(ErrorOccured$OrcaPaymentPage.make, {});
  } else {
    return React.createElement(RenderPaymentMethods$OrcaPaymentPage.make, {
                paymentType: paymentType,
                cardProps: cardProps,
                expiryProps: expiryProps,
                cvcProps: cvcProps,
                zipProps: zipProps,
                handleElementFocus: handleElementFocus,
                blurState: match$10[0],
                countryProps: countryProps,
                isFocus: match$9[0]
              });
  }
}

var make = Payment;

export {
  setUserError ,
  make ,
}
/* react Not a pure module */
