// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Recoil from "recoil";
import * as Recoil$OrcaPaymentPage from "../libraries/Recoil.bs.js";
import * as RenderIf$OrcaPaymentPage from "./RenderIf.bs.js";
import * as PaymentType$OrcaPaymentPage from "../Types/PaymentType.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "../Utilities/RecoilAtoms.bs.js";
import * as PaymentField$OrcaPaymentPage from "./PaymentField.bs.js";

function PhoneNumberPaymentInput(props) {
  var match = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.optionAtom);
  var loggerState = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.loggerAtom);
  var match$1 = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userPhoneNumber, "phone", loggerState);
  var setPhone = match$1[1];
  var showDetails = PaymentType$OrcaPaymentPage.getShowDetails(match.fields.billingDetails, loggerState);
  var formatBSB = function (bsb) {
    var secondPart = bsb.slice(4).trim();
    if (bsb.length <= 4) {
      return "+351 ";
    } else if (bsb.length > 4) {
      return "+351 " + secondPart;
    } else {
      return bsb;
    }
  };
  var changePhone = function (ev) {
    var val = ev.target.value.replace(/\+D+/g, "");
    setPhone(function (prev) {
          return {
                  value: formatBSB(val),
                  isValid: prev.isValid,
                  errorString: prev.errorString
                };
        });
  };
  var phoneRef = React.useRef(null);
  return React.createElement(RenderIf$OrcaPaymentPage.make, {
              condition: showDetails.phone === "Auto",
              children: React.createElement(PaymentField$OrcaPaymentPage.make, {
                    value: match$1[0],
                    onChange: changePhone,
                    fieldName: "Phone Number",
                    name: "phone",
                    type_: "tel",
                    paymentType: "Payment",
                    maxLength: 14,
                    placeholder: "+351 200 000 000",
                    inputRef: phoneRef
                  })
            });
}

var make = PhoneNumberPaymentInput;

export {
  make ,
}
/* react Not a pure module */
