// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

function localeStrings_achBankDebitTerms(str) {
  return "על ידי הזנת מספר החשבון שלך ואישור תשלום זה, אתה מאשר את " + str + " ואת Hyperswitch, ספק השירותים התשלום שלנו, לשלוח הוראות לבנק שלך לחייב את החשבון שלך ולהפחית את החשבון שלך על פי ההוראות האלה. אתה זכאי לקבל החזר מהבנק שלך על פי תנאי ההסכם שלך עם הבנק. ההחזר יכול להידרש תוך 8 שבועות מהתאריך שבו הוא הופחת מחשבונך.";
}

function localeStrings_sepaDebitTerms(str) {
  return "על ידי הזנת מידע התשלום שלך ואישור תשלום זה, אתה מאשר (א) את " + str + " ואת Hyperswitch, ספק השירותים התשלום שלנו ו/או PPRO, ספק השירותים המקומי שלו, לשלוח הוראות לבנק שלך לחייב את החשבון שלך ו(ב) את הבנק שלך לחייב את החשבון שלך על פי ההוראות האלה. כחלק מזכויותיך, אתה זכאי לקבל החזר מהבנק שלך על פי תנאי ההסכם שלך עם הבנק. ההחזר יכול להידרש תוך 8 שבועות מהתאריך שבו הוא הופחת מחשבונך. הזכויות שלך מוסברות בהצהרה שאתה יכול לקבל מהבנק שלך. אתה מסכים לקבל הודעות להוראות חיוב עתידיות עד 2 ימים לפני החיוב.";
}

function localeStrings_cardTerms(str) {
  return "על ידי הזנת פרטי הכרטיס שלך, אתה מאפשר ל" + str + " לחייב את הכרטיס שלך עבור תשלומים עתידיים על פי תנאיהם.";
}

function localeStrings_surchargeMsgAmount(currency, str) {
  return React.createElement(React.Fragment, {}, "סכום היטל של" + Utils$OrcaPaymentPage.nbsp, React.createElement("strong", undefined, currency + " " + str), Utils$OrcaPaymentPage.nbsp + "יוחל עבור עסקה זו");
}

function localeStrings_surchargeMsgAmountForCard(currency, str) {
  return React.createElement(React.Fragment, {}, "סכום היטל של עד" + Utils$OrcaPaymentPage.nbsp, React.createElement("strong", undefined, currency + " " + str), Utils$OrcaPaymentPage.nbsp + "יחול עבור עסקה זו");
}

function localeStrings_nameEmptyText(str) {
  return "אנא ספק את שלך " + str;
}

function localeStrings_completeNameEmptyText(str) {
  return "אנא ספק את המלא שלך " + str;
}

var localeStrings = {
  locale: "he",
  cardNumberLabel: "מספר כרטיס",
  localeDirection: "rtl",
  inValidCardErrorText: "אינו תקין",
  inCompleteCVCErrorText: "קוד האבטחה של הכרטיס שלך אינו שלם",
  inCompleteExpiryErrorText: "פרטי תוקף של הכרטיס שלך אינם מלאים",
  pastExpiryErrorText: "שנת תוקף של הכרטיס שלך עברה.",
  poweredBy: "מופעל על ידי Hyperswitch",
  validThruText: "תוקף",
  sortCodeText: "קוד מיון",
  cvcTextLabel: "קוד בגב הכרטיס",
  emailLabel: "אימייל",
  emailEmptyText: "אימייל לא יכול להיות ריק",
  emailInvalidText: "כתובת אימייל לא חוקית",
  accountNumberText: "מספר חשבון",
  fullNameLabel: "שם מלא",
  line1Label: "כתובת - שורה 1",
  line1Placeholder: "כתובת רחוב",
  line1EmptyText: "שורת כתובת 1 לא יכולה להיות ריקה",
  line2Label: "כתובת - שורה 2",
  line2Placeholder: "דירה, יחידה, וכדומה (אופציונלי)",
  line2EmptyText: "שורת כתובת 2 לא יכולה להיות ריקה",
  cityLabel: "עיר",
  cityEmptyText: "עיר לא יכולה להיות ריקה",
  postalCodeLabel: "מיקוד",
  postalCodeEmptyText: "מיקוד לא יכול להיות ריק",
  postalCodeInvalidText: "מיקוד לא חוקי",
  stateLabel: "מדינה",
  stateEmptyText: "המדינה לא יכולה להיות ריקה",
  fullNamePlaceholder: "שם פרטי ושם משפחה",
  countryLabel: "מדינה",
  currencyLabel: "מטבע",
  bankLabel: "בחר בנק",
  redirectText: "לאחר שליחת ההזמנה שלך, תועבר להשלמת הרכישה באופן מאובטח.",
  bankDetailsText: "לאחר הגשת פרטים אלה, תקבל את פרטי החשבון הבנקאי לביצוע התשלום. נא לוודא לשמור את הפרטים.",
  orPayUsing: "או לשלם באמצעות",
  addNewCard: "הוספת כרטיס אשראי/כרטיס חיוב (דביט)",
  useExisitingSavedCards: "שימוש בכרטיסי חיוב/ אשראי שמורים",
  saveCardDetails: "שמירת פרטי הכרטיס",
  addBankAccount: "הוסף חשבון בנק",
  achBankDebitTerms: localeStrings_achBankDebitTerms,
  sepaDebitTerms: localeStrings_sepaDebitTerms,
  becsDebitTerms: "על ידי הזנת פרטי החשבון הבנקאי שלך ואישור תשלום זה, אתה מסכים לבקשת החיוב הישיר ולהסכם תשלום בקשת החיוב הישיר ומאשר את Hyperswitch Payments Australia Pty Ltd ACN 160 180 343 מספר זיהוי משתמש בקשת החיוב הישיר 507156 (Hyperswitch) לחייב את החשבון שלך דרך מערכת BECS (Bulk Electronic Clearing System) בשם Hyperswitch Payment Widget (הסוחר) עבור סכומים הודעו לך בנפרד על ידי הסוחר. אתה מאשר שאתה בעל החשבון או חותם מורשה בחשבון שצוין למעלה.",
  cardTerms: localeStrings_cardTerms,
  payNowButton: "שלם עכשיו",
  cardNumberEmptyText: "מספר הכרטיס אינו יכול להיות ריק",
  cardExpiryDateEmptyText: "תוקף של הכרטיס אינו יכול להיות ריק",
  cvcNumberEmptyText: "פרטי קוד ה-CVC אינם יכולים להיות ריקים",
  enterFieldsText: "יש להזין את כל השדות",
  enterValidDetailsText: "יש להזין פרטים תקינים",
  selectPaymentMethodText: "אנא בחר אמצעי תשלום ונסה שוב",
  card: "כרטיס",
  surchargeMsgAmount: localeStrings_surchargeMsgAmount,
  surchargeMsgAmountForCard: localeStrings_surchargeMsgAmountForCard,
  surchargeMsgAmountForOneClickWallets: "תשלום נוסף חל",
  billingNameLabel: "שם החיוב",
  billingNamePlaceholder: "שם פרטי ושם משפחה",
  cardHolderName: "שם בעל הכרטיס",
  on: "עַל",
  and: "ו",
  nameEmptyText: localeStrings_nameEmptyText,
  completeNameEmptyText: localeStrings_completeNameEmptyText,
  billingDetailsText: "פרטי תשלום",
  socialSecurityNumberLabel: "מספר ביטוח לאומי",
  saveWalletDetails: "פרטי הארנק יישמרו בעת בחירה",
  morePaymentMethods: "אמצעי תשלום נוספים",
  useExistingPaymentMethods: "השתמש באמצעי תשלום שמורים",
  cardNickname: "כינוי לכרטיס",
  nicknamePlaceholder: "כינוי לכרטיס (אופציונלי)"
};

export {
  localeStrings ,
}
/* react Not a pure module */
