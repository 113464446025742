// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

function localeStrings_achBankDebitTerms(str) {
  return "Al proporcionar su número de cuenta y confirmar este pago, autoriza a " + str + " y Hyperswitch, nuestro proveedor de servicios de pago, a enviar órdenes a su banco para que aplique cargos en su cuenta y para que realice los correspondientes adeudos en la misma de acuerdo con dichas órdenes. Tiene derecho a recibir un reembolso de su banco de acuerdo con las condiciones del contrato suscrito. El reembolso debe reclamarse en un plazo de 8 semanas desde la fecha en la que se aplicó el cargo en su cuenta.";
}

function localeStrings_sepaDebitTerms(str) {
  return "Al proporcionar su información de pago y confirmar este pago, autoriza a (A) " + str + " y Hyperswitch, nuestro proveedor de servicios de pago o a PPRO, su proveedor de servicios local, a enviar órdenes a su banco para que aplique cargos en su cuenta y (B) para que realice los correspondientes adeudos en la misma de acuerdo con dichas órdenes. Como parte de sus derechos, tiene la potestad de que su banco le devuelva el dinero según las condiciones de su contrato. El reembolso debe reclamarse en un plazo de 8 semanas desde la fecha en la que se aplicó el cargo en su cuenta. Sus derechos aparecen explicados en una declaración que puede solicitar en su banco. Acepta recibir notificaciones de futuros cargos hasta 2 días antes de que se produzcan.";
}

function localeStrings_cardTerms(str) {
  return "Al proporcionar la información de su tarjeta, permite a " + str + " que realice cargos en su tarjeta para futuros pagos de acuerdo con sus términos.";
}

function localeStrings_surchargeMsgAmount(currency, str) {
  return React.createElement(React.Fragment, {}, "un monto de recargo de" + Utils$OrcaPaymentPage.nbsp, React.createElement("strong", undefined, currency + " " + str), Utils$OrcaPaymentPage.nbsp + "se aplicará para esta transacción");
}

function localeStrings_surchargeMsgAmountForCard(currency, str) {
  return React.createElement(React.Fragment, {}, "Un monto de recargo de hasta" + Utils$OrcaPaymentPage.nbsp, React.createElement("strong", undefined, currency + " " + str), Utils$OrcaPaymentPage.nbsp + "se aplicará para esta transacción");
}

function localeStrings_nameEmptyText(str) {
  return "Por favor proporcione su " + str;
}

function localeStrings_completeNameEmptyText(str) {
  return "Por favor proporcione su completo " + str;
}

var localeStrings = {
  locale: "es",
  cardNumberLabel: "Número de tarjeta",
  localeDirection: "ltr",
  inValidCardErrorText: "El número de la tarjeta no es válido.",
  inCompleteCVCErrorText: "El código de seguridad de su tarjeta está incompleto.",
  inCompleteExpiryErrorText: "La fecha de vencimiento de su tarjeta está incompleta.",
  pastExpiryErrorText: "El año de vencimiento de su tarjeta ya ha pasado.",
  poweredBy: "Desarrollado por Hyperswitch",
  validThruText: "Vencimiento",
  sortCodeText: "Código Sort",
  cvcTextLabel: "CVV",
  emailLabel: "Correo electrónico",
  emailEmptyText: "El correo electrónico no puede estar vacío.",
  emailInvalidText: "Dirección de correo electrónico no válida",
  accountNumberText: "Número de cuenta",
  fullNameLabel: "Nombre completo",
  line1Label: "Línea de dirección 1",
  line1Placeholder: "Dirección postal",
  line1EmptyText: "La línea de dirección 1 no puede estar vacía",
  line2Label: "Línea de dirección 2",
  line2Placeholder: "Piso, número de departamento, etc. (opcional)",
  line2EmptyText: "La línea de dirección 2 no puede estar vacía",
  cityLabel: "Ciudad",
  cityEmptyText: "La ciudad no puede estar vacía.",
  postalCodeLabel: "Código postal",
  postalCodeEmptyText: "El código postal no puede estar vacío.",
  postalCodeInvalidText: "código postal no válido",
  stateLabel: "Provincia/estado",
  stateEmptyText: "El estado no puede estar vacío.",
  fullNamePlaceholder: "Nombre y apellidos",
  countryLabel: "País",
  currencyLabel: "Moneda",
  bankLabel: "Seleccionar banco",
  redirectText: "Después de enviar su pedido, se le redirigirá para que complete su compra de forma segura.",
  bankDetailsText: "Después de enviar estos datos, obtendrá la información de la cuenta bancaria para realizar el pago. Asegúrese de anotarla.",
  orPayUsing: "O pague con",
  addNewCard: "Añadir tarjeta de crédito/débito",
  useExisitingSavedCards: "Utilizar las tarjetas de débito/crédito guardadas",
  saveCardDetails: "Guardar la información de la tarjeta",
  addBankAccount: "Añadir cuenta bancaria",
  achBankDebitTerms: localeStrings_achBankDebitTerms,
  sepaDebitTerms: localeStrings_sepaDebitTerms,
  becsDebitTerms: "Al proporcionar los datos de su cuenta bancaria y confirmar este pago, acepta esta solicitud de domiciliación bancaria y el acuerdo de servicio para la misma. Además, autoriza a Hyperswitch Payments Australia Pty Ltd ACN 160 180 343, número de identificación de usuario de domiciliación bancaria 507156 («Hyperswitch»), a realizar adeudos en su cuenta a través del sistema de compensación electrónica masiva (BECS) en nombre de Hyperswitch Payment Widget (el «comercio») por cualquier importe que el comercio le comunique individualmente. Certifica que es titular de una cuenta o un firmante autorizado en la cuenta indicada anteriormente.",
  cardTerms: localeStrings_cardTerms,
  payNowButton: "Pagar ahora",
  cardNumberEmptyText: "El número de la tarjeta no puede estar vacío",
  cardExpiryDateEmptyText: "La fecha de vencimiento de la tarjeta no puede estar vacía",
  cvcNumberEmptyText: "El número CVV no puede estar vacío",
  enterFieldsText: "Rellene todos los campos",
  enterValidDetailsText: "Introduzca datos válidos",
  selectPaymentMethodText: "Por favor seleccione un método de pago y vuelva a intentarlo",
  card: "Tarjeta",
  surchargeMsgAmount: localeStrings_surchargeMsgAmount,
  surchargeMsgAmountForCard: localeStrings_surchargeMsgAmountForCard,
  surchargeMsgAmountForOneClickWallets: "Tarifa adicional aplicable",
  billingNameLabel: "Nombre de facturación",
  billingNamePlaceholder: "Nombre y apellido",
  cardHolderName: "Nombre del titular de la tarjeta",
  on: "en",
  and: "y",
  nameEmptyText: localeStrings_nameEmptyText,
  completeNameEmptyText: localeStrings_completeNameEmptyText,
  billingDetailsText: "Detalles de facturación",
  socialSecurityNumberLabel: "Número de seguro social",
  saveWalletDetails: "Los detalles de las billeteras se guardarán al realizar la selección.",
  morePaymentMethods: "Más métodos de pago",
  useExistingPaymentMethods: "Utilice métodos de pago guardados",
  cardNickname: "Apodo de la tarjeta",
  nicknamePlaceholder: "Apodo de la tarjeta (opcional)"
};

export {
  localeStrings ,
}
/* react Not a pure module */
