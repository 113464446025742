// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as Modal$OrcaPaymentPage from "./Components/Modal.bs.js";
import * as Utils$OrcaPaymentPage from "./Utilities/Utils.bs.js";
import * as OrcaLogger$OrcaPaymentPage from "./orca-log-catcher/OrcaLogger.bs.js";
import * as LoggerUtils$OrcaPaymentPage from "./Utilities/LoggerUtils.bs.js";
import * as PaymentHelpers$OrcaPaymentPage from "./Utilities/PaymentHelpers.bs.js";

function ThreeDSAuth(props) {
  var match = React.useState(function () {
        return false;
      });
  var match$1 = React.useState(function () {
        return true;
      });
  var setloader = match$1[1];
  var logger = OrcaLogger$OrcaPaymentPage.make(undefined, undefined, undefined, undefined, undefined, undefined);
  React.useEffect((function () {
          Utils$OrcaPaymentPage.handlePostMessage(undefined, [[
                  "iframeMountedCallback",
                  true
                ]]);
          var handle = function (ev) {
            var json = JSON.parse(ev.data);
            var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
            if (!Core__Option.isSome(dict["fullScreenIframeMounted"])) {
              return ;
            }
            var metadata = Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "metadata");
            var metaDataDict = Core__Option.getOr(Core__JSON.Decode.object(metadata), {});
            var paymentIntentId = Utils$OrcaPaymentPage.getString(metaDataDict, "paymentIntentId", "");
            var publishableKey = Utils$OrcaPaymentPage.getString(metaDataDict, "publishableKey", "");
            logger.setClientSecret(paymentIntentId);
            logger.setMerchantId(publishableKey);
            var headersDict = Core__Option.getOr(Core__JSON.Decode.object(Utils$OrcaPaymentPage.getJsonObjectFromDict(metaDataDict, "headers")), {});
            var threeDsAuthoriseUrl = Utils$OrcaPaymentPage.getString(Core__Option.getOr(Core__JSON.Decode.object(Utils$OrcaPaymentPage.getJsonObjectFromDict(metaDataDict, "threeDSData")), {}), "three_ds_authorize_url", "");
            var headers = Object.entries(headersDict).map(function (entries) {
                  return [
                          entries[0],
                          Core__Option.getOr(Core__JSON.Decode.string(entries[1]), "")
                        ];
                });
            var threeDsMethodComp = Utils$OrcaPaymentPage.getString(metaDataDict, "3dsMethodComp", "U");
            Core__Promise.$$catch(PaymentHelpers$OrcaPaymentPage.threeDsAuth(paymentIntentId, logger, threeDsMethodComp, headers).then(function (json) {
                      var dict = Core__Option.getOr(Core__JSON.Decode.object(json), {});
                      var creq = Utils$OrcaPaymentPage.getString(dict, "challenge_request", "");
                      var transStatus = Utils$OrcaPaymentPage.getString(dict, "trans_status", "Y");
                      var acsUrl = Utils$OrcaPaymentPage.getString(dict, "acs_url", "");
                      var ele = document.querySelector("#threeDsAuthDiv");
                      LoggerUtils$OrcaPaymentPage.handleLogging(logger, transStatus, undefined, "DISPLAY_THREE_DS_SDK", "CARD", undefined, undefined);
                      if (!(ele == null)) {
                        if (transStatus === "C") {
                          setloader(function (param) {
                                return false;
                              });
                          var form = Utils$OrcaPaymentPage.makeForm(ele, acsUrl, "3dsChallenge");
                          var input = document.createElement("input");
                          input.name = "creq";
                          input.value = creq;
                          form.target = "threeDsAuthFrame";
                          form.appendChild(input);
                          form.submit();
                        } else {
                          var form1 = Utils$OrcaPaymentPage.makeForm(ele, threeDsAuthoriseUrl, "3dsFrictionLess");
                          form1.submit();
                        }
                      }
                      return Promise.resolve(json);
                    }), (function (err) {
                    var exceptionMessage = Utils$OrcaPaymentPage.formatException(err);
                    LoggerUtils$OrcaPaymentPage.handleLogging(logger, JSON.stringify(exceptionMessage), undefined, "DISPLAY_THREE_DS_SDK", "CARD", "ERROR", undefined);
                    return Promise.resolve(null);
                  }));
          };
          window.addEventListener("message", handle);
          return (function () {
                    window.removeEventListener("message", handle);
                  });
        }), []);
  return React.createElement(Modal$OrcaPaymentPage.make, {
              children: React.createElement("div", {
                    className: "backdrop-blur-xl"
                  }, React.createElement("div", {
                        className: "hidden",
                        id: "threeDsAuthDiv"
                      }), React.createElement("iframe", {
                        id: "threeDsAuthFrame",
                        style: {
                          minHeight: "500px"
                        },
                        name: "threeDsAuthFrame",
                        width: "100%"
                      })),
              loader: match$1[0],
              showClose: false,
              setOpenModal: match[1],
              openModal: match[0]
            });
}

var make = ThreeDSAuth;

export {
  make ,
}
/* react Not a pure module */
