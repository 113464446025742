// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

function localeStrings_achBankDebitTerms(str) {
  return "Fornendo il tuo numero di conto e confermando questo pagamento, autorizzi " + str + " e Hyperswitch, il nostro fornitore di servizi di pagamento, a inviare istruzioni alla tua banca per effettuare l'addebito sul tuo conto e la tua banca a effettuare tale addebito sul conto in conformità alle presenti istruzioni. Hai diritto a un rimborso dalla tua banca secondo i termini e le condizioni del tuo contratto con la stessa. Il rimborso va richiesto entro 8 settimane a partire dalla data di addebito sul conto.";
}

function localeStrings_sepaDebitTerms(str) {
  return "Fornendo le tue informazioni di pagamento e confermando questo pagamento, autorizzi (A) " + str + " e Hyperswitch, il nostro fornitore di servizi di pagamento e/o PPRO, il suo fornitore locale di servizi, a inviare istruzioni alla tua banca per effettuare l'addebito sul tuo conto e (B) la tua banca a effettuare tale addebito sul conto in conformità alle presenti istruzioni. Come parte dei tuoi diritti, puoi richiedere un rimborso alla tua banca in conformità con i termini e le condizioni del tuo contratto con tale istituto. Devi richiedere il rimborso entro 8 settimane a partire dalla data di addebito sul conto. Puoi conoscere i tuoi diritti in merito richiedendo la relativa informativa alla tua banca. Accetti di ricevere avvisi relativi ai prossimi addebiti fino a 2 giorni prima della loro data effettiva.";
}

function localeStrings_cardTerms(str) {
  return "Fornendo i dati della tua carta, autorizzi " + str + " ad addebitare sulla tua carta pagamenti futuri in conformità con i suoi termini.";
}

function localeStrings_surchargeMsgAmount(currency, str) {
  return React.createElement(React.Fragment, {}, "Un importo aggiuntivo di" + Utils$OrcaPaymentPage.nbsp, React.createElement("strong", undefined, currency + " " + str), Utils$OrcaPaymentPage.nbsp + "verrà applicato per questa transazione");
}

function localeStrings_surchargeMsgAmountForCard(currency, str) {
  return React.createElement(React.Fragment, {}, "Un importo di supplemento fino a" + Utils$OrcaPaymentPage.nbsp, React.createElement("strong", undefined, currency + " " + str), Utils$OrcaPaymentPage.nbsp + "verrà applicato per questa transazione");
}

function localeStrings_nameEmptyText(str) {
  return "Per favore fornisci il tuo " + str;
}

function localeStrings_completeNameEmptyText(str) {
  return "Si prega di fornire il file completo " + str;
}

var localeStrings = {
  locale: "it",
  cardNumberLabel: "Numero della carta",
  localeDirection: "lrt",
  inValidCardErrorText: "Il numero della carta non è valido.",
  inCompleteCVCErrorText: "Il codice di sicurezza della carta è incompleto.",
  inCompleteExpiryErrorText: "La data di scadenza della carta è incompleta.",
  pastExpiryErrorText: "L'anno di scadenza della tua carta è passato.",
  poweredBy: "Offerto da Hyperswitch",
  validThruText: "Scadenza",
  sortCodeText: "Sort Code",
  cvcTextLabel: "CVC",
  emailLabel: "E-mail",
  emailEmptyText: "L'e-mail non può essere vuota",
  emailInvalidText: "indirizzo email non valido",
  accountNumberText: "Numero del conto",
  fullNameLabel: "Nome e cognome",
  line1Label: "Indirizzo (riga 1)",
  line1Placeholder: "Indirizzo",
  line1EmptyText: "La riga dell'indirizzo 1 non può essere vuota",
  line2Label: "Indirizzo (riga 2)",
  line2Placeholder: "Appartamento, interno, ecc. (facoltativo)",
  line2EmptyText: "La riga dell'indirizzo 2 non può essere vuota",
  cityLabel: "Città",
  cityEmptyText: "La città non può essere vuota",
  postalCodeLabel: "Codice di avviamento postale",
  postalCodeEmptyText: "Il codice postale non può essere vuoto",
  postalCodeInvalidText: "Codice postale non valido",
  stateLabel: "Stato",
  stateEmptyText: "Lo stato non può essere vuoto",
  fullNamePlaceholder: "Nome e cognome",
  countryLabel: "Paese",
  currencyLabel: "Valuta",
  bankLabel: "Seleziona una banca",
  redirectText: "Dopo aver inviato l'ordine, ti reindirizzeremo per completare l'acquisto in totale sicurezza.",
  bankDetailsText: "Dopo aver inviato questi dettagli, riceverai le informazioni sul conto bancario per effettuare il pagamento. Assicurati di annotarli.",
  orPayUsing: "Oppure paga utilizzando",
  addNewCard: "Aggiungi una carta di credito/debito",
  useExisitingSavedCards: "Utilizza carte di debito/credito salvate",
  saveCardDetails: "Salva i dati della carta",
  addBankAccount: "Aggiungi un conto bancario",
  achBankDebitTerms: localeStrings_achBankDebitTerms,
  sepaDebitTerms: localeStrings_sepaDebitTerms,
  becsDebitTerms: "Fornendo i dettagli del tuo conto bancario e confermando questo pagamento, accetti la presente Richiesta di addebito diretto e il contratto di servizio per la Richiesta di addebito diretto e autorizzi Hyperswitch Payments Australia Pty Ltd ACN 160 180 343, numero identificativo utente per addebito diretto 507156 ( \"Hyperswitch\") a effettuare l'addebito sul tuo conto tramite il Bulk Electronic Clearing System (BECS) per conto di Hyperswitch Payment Widget (l' Esercente) per qualsiasi importo ti venga comunicato separatamente dall'Esercente. Dichiari di essere titolare o firmatario autorizzato del conto sopra indicato.",
  cardTerms: localeStrings_cardTerms,
  payNowButton: "Paga ora",
  cardNumberEmptyText: "Il campo Numero della carta non può essere vuoto",
  cardExpiryDateEmptyText: "Il campo Data di scadenza della carta non può essere vuoto",
  cvcNumberEmptyText: "Il campo CVC non può essere vuoto",
  enterFieldsText: "Compila tutti i campi",
  enterValidDetailsText: "Inserisci dati validi",
  selectPaymentMethodText: "Seleziona un metodo di pagamento e riprova",
  card: "Carta",
  surchargeMsgAmount: localeStrings_surchargeMsgAmount,
  surchargeMsgAmountForCard: localeStrings_surchargeMsgAmountForCard,
  surchargeMsgAmountForOneClickWallets: "Supplemento applicabile",
  billingNameLabel: "Intestatario della fattura",
  billingNamePlaceholder: "Nome e cognome",
  cardHolderName: "Nome del titolare della carta",
  on: "SU",
  and: "E",
  nameEmptyText: localeStrings_nameEmptyText,
  completeNameEmptyText: localeStrings_completeNameEmptyText,
  billingDetailsText: "Dettagli di fatturazione",
  socialSecurityNumberLabel: "Numero di Social Security",
  saveWalletDetails: "I dettagli dei portafogli verranno salvati al momento della selezione",
  morePaymentMethods: "Più metodi di pagamento",
  useExistingPaymentMethods: "Utilizza i metodi di pagamento salvati",
  cardNickname: "Soprannome della carta",
  nicknamePlaceholder: "Soprannome della carta (facoltativo)"
};

export {
  localeStrings ,
}
/* react Not a pure module */
